import {
    CheckOutlined,
    CloseOutlined,
    EllipsisOutlined,
    MenuOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Dropdown,
    Form,
    Menu,
    Select,
    Space,
    Switch,
    message,
    Input,
    Collapse,
} from "antd";
import { cloneDeep, pullAt, set } from "lodash";
import { useEffect, useRef, useState } from "react";
import { KEYMAP, CLUSTER_KEYS } from "./mapping";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { getActionMenuItems } from "src/modules/worksheet/components/WorksheetEditor/helpers";
import { AudioPoolModal } from "../../../../Figma/renderEditor/AudioPoolModal";
import { LogicModal } from "../common/LogicModal";
import TagTable from "./TagTable";
import ItemsTable from "./ItemsTable";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import ComponentDetails from "../common/ComponentDetails";
import { FeedbackPool } from "../common/FeedbackPool";
import { COMPONENT_RENDER_TYPES } from "../../../defaultValue";
import CollapseBtn from "src/modules/worksheet/components/WorksheetEditor/helpers/CollapseBtn";
import { checkDnDClusterValid } from "../../../isValid";

export const DragHandle = SortableHandle(() => <MenuOutlined />);

const SortableItem = SortableElement(
    ({
        child,
        disableWrite,
        value,
        deleteBlock,
        addBlock,
        onClick,
        currentCluster,
        idx,
    }) => {
        const getBlockActionsMenu = () => {
            return (
                <Menu
                    items={[
                        ...getActionMenuItems({
                            idx,
                            type: child.type,
                            disableDuplicateAction: disableWrite,
                            deleteBlock,
                            addBlockCustom: addBlock,
                            block: child,
                            // for progression stage
                            updateId: true,
                            ignoreId: false,
                            updateGenricId: true,
                        }),
                        {
                            key: 3,
                            label: <span>Copy JSON</span>,
                            onClick: async () => {
                                navigator.clipboard.writeText(
                                    JSON.stringify(child),
                                );
                                message.info(`Copied Cluster to clipboard!`);
                            },
                        },
                    ]}
                />
            );
        };

        const isValid = checkDnDClusterValid(child);

        return (
            <Card
                hoverable
                style={{
                    marginBottom: "20px",
                    boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                    background: !isValid
                        ? "#FFD6D7"
                        : currentCluster === value
                        ? "#E6F7FF"
                        : "#ffffff",
                    borderRight:
                        currentCluster === value ? "4px solid #1890FF" : 0,
                }}
                onClick={onClick}
                bodyStyle={{
                    background:
                        currentCluster === value
                            ? !isValid
                                ? "4px solid red"
                                : "#E6F7FF"
                            : "#ffffff",
                    padding: 0,
                }}
                extra={
                    <Dropdown
                        overlay={() => getBlockActionsMenu()}
                        trigger={["click"]}
                        overlayStyle={{ width: "100px" }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Button
                                style={{
                                    flexShrink: 0,
                                }}
                                type="default"
                                icon={
                                    <EllipsisOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                                disabled={disableWrite}
                            />
                        </a>
                    </Dropdown>
                }
                title={
                    <>
                        <DragHandle />{" "}
                        {child?.type
                            ? CLUSTER_KEYS[child.type]
                            : child?.dropZones === 1
                            ? CLUSTER_KEYS.item_cluster
                            : CLUSTER_KEYS.tag_cluster}
                    </>
                }
            />
        );
    },
);

const SortableList = SortableContainer(({ children }) => {
    return <div style={{ overflow: "auto", gap: "20px" }}>{children}</div>;
});

const DragNDropEditor = (props: any) => {
    const {
        block,
        disableWrite,
        setBlock,
        currentEditor,
        setCurrentEditor,
        hasMentions,
        mentionsList = [],
        renderLinkSelect,
    } = props;
    const {
        figma: { variables, compute_functions },
        component_type,
    } = block;
    const blockVarsMap: any = useRef({});

    const [currentCluster, setCurCluster] = useState(null);
    const [openAudioModal, setOpenAudioModal] = useState("");
    const [tempChildren, setTempChildren] = useState("");
    const [openFeedbackModal, setOpenFeedbackModal] = useState("");
    const [load, setLoad] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        setLoad(true);
        variables?.forEach((element: { name: string | number }, i: any) => {
            blockVarsMap.current[element.name] = i;
        });
        let v = setTimeout(() => {
            setLoad(false);
        }, 50);
        return () => {
            clearTimeout(v);
        };
    }, []);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const tmpChildren = arrayMoveImmutable(
            variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        let tmpBlock = cloneDeep(block);
        tmpBlock = set(
            tmpBlock,
            [
                "figma",
                "variables",
                blockVarsMap.current[KEYMAP.generic_cluster],
                "value",
            ],
            tmpChildren,
        );
        setBlock(tmpBlock);
        setCurCluster(newIndex);
    };

    return load ? (
        <></>
    ) : (
        <div style={{ paddingBottom: "10px", display: "flex" }}>
            {openAudioModal && openAudioModal !== "" && (
                <AudioPoolModal
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenAudioModal(value === true ? openAudioModal : "");
                    }}
                    name={openAudioModal}
                    audioPool={
                        blockVarsMap.current[openAudioModal] &&
                        variables[blockVarsMap.current[openAudioModal]].value
                    }
                    disableWrite={disableWrite}
                    schema={{
                        x: "",
                        audio: "",
                    }}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[openAudioModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            )}
            {openFeedbackModal && openFeedbackModal !== "" && (
                <FeedbackPool
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenFeedbackModal(
                            value === true ? openFeedbackModal : "",
                        );
                    }}
                    name={openFeedbackModal}
                    feedbackPool={
                        blockVarsMap.current[openFeedbackModal] >= 0 &&
                        variables[blockVarsMap.current[openFeedbackModal]].value
                    }
                    disableWrite={disableWrite}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[openFeedbackModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                />
            )}
            <Card
                style={{
                    minWidth: "50px",
                    maxWidth: "300px",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                }}
                bodyStyle={{
                    padding: "0px",
                    paddingTop: collapsed ? "10px" : "15px",
                }}
            >
                <div
                    style={{
                        height: "25px",
                    }}
                >
                    <CollapseBtn
                        collapsed={collapsed}
                        toggleCollapsed={toggleCollapsed}
                        style={{
                            marginBottom: 10,
                            marginLeft: collapsed ? 10 : 20,
                            marginRight: collapsed ? 10 : 0,
                            position: "fixed",
                        }}
                    />
                </div>
                <div
                    style={{
                        display: collapsed ? "none" : "block",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        height: "80vh",
                        padding: 20,
                        paddingTop: 30,
                    }}
                >
                    <SortableList onSortEnd={onSortEnd} useDragHandle>
                        {variables[
                            blockVarsMap.current[KEYMAP.generic_cluster]
                        ]?.value?.map((child, idx) => (
                            <SortableItem
                                disabled={disableWrite}
                                key={`item-${idx}`}
                                index={idx}
                                idx={idx}
                                value={idx}
                                child={child}
                                disableWrite={disableWrite}
                                onClick={() => setCurCluster(idx)}
                                currentCluster={currentCluster}
                                addBlock={(
                                    type: string,
                                    index: number,
                                    newBlock: any,
                                ) => {
                                    let tmpBlock = cloneDeep(block);
                                    const tmpChildren = [
                                        ...variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value,
                                    ];
                                    tmpChildren.splice(index, 0, newBlock);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                        ],
                                        tmpChildren,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                deleteBlock={(index: number) => {
                                    let tmpBlock = cloneDeep(block);
                                    const tmpChildren = [
                                        ...variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value,
                                    ];
                                    pullAt(tmpChildren, idx);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                        ],
                                        tmpChildren,
                                    );
                                    setBlock(tmpBlock);
                                    setCurCluster(idx - 1);
                                }}
                                setBlock={(v) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                            idx,
                                        ],
                                        v,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        ))}
                    </SortableList>
                    {!variables[blockVarsMap.current[KEYMAP.generic_cluster]]
                        ?.value?.length && (
                        <div
                            style={{
                                fontSize: "16px",
                                color: "red",
                            }}
                        >
                            No clusters added
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "10px",
                            flexWrap: "wrap",
                        }}
                    >
                        {!disableWrite &&
                            variables?.[
                                blockVarsMap.current?.[KEYMAP.generic_cluster]
                            ]?.schema?.map((item: any, key: number) => (
                                <Button
                                    key={key}
                                    ghost
                                    type="primary"
                                    onClick={() => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ],
                                                "value",
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ].value?.length,
                                            ],
                                            item,
                                        );
                                        setBlock(tmpBlock);
                                        setCurCluster(
                                            variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ].value?.length,
                                        );
                                    }}
                                >
                                    Add {CLUSTER_KEYS[item.type]}
                                </Button>
                            ))}
                    </div>
                    {!disableWrite && (
                        <Form.Item label={"Add Cluster"}>
                            <Input.TextArea
                                disabled={disableWrite}
                                value={tempChildren}
                                placeholder="Paste Cluster here"
                                onChange={(e) =>
                                    setTempChildren(e.target.value)
                                }
                                rows={2}
                                style={{
                                    width: "100%",
                                }}
                            />
                            <Button
                                disabled={disableWrite}
                                style={{ marginTop: "10px" }}
                                type="primary"
                                size="small"
                                onClick={() => {
                                    try {
                                        const parsedJson =
                                            JSON.parse(tempChildren);
                                        if (
                                            !Object.keys(CLUSTER_KEYS).includes(
                                                parsedJson?.type,
                                            )
                                        ) {
                                            message.warn("Invalid JSON");
                                            return;
                                        }
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ],
                                                "value",
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ].value?.length,
                                            ],
                                            updateKeysAndCopy(
                                                parsedJson,
                                                false,
                                                true,
                                            ),
                                        );
                                        setBlock(tmpBlock);
                                        setCurCluster(
                                            variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ].value?.length,
                                        );
                                        setTempChildren("");
                                        message.info("Updated!");
                                    } catch (e) {
                                        // captureException(e)
                                        console.log(e);
                                        message.error("Error in JSON!");
                                    }
                                }}
                            >
                                Add New Cluster
                            </Button>
                        </Form.Item>
                    )}
                    <Collapse>
                        <Collapse.Panel header="Settings" key="1">
                            <ComponentDetails
                                disableWrite={disableWrite}
                                block={block}
                                setBlock={setBlock}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    gap: "4px",
                                }}
                            >
                                <LogicModal
                                    disableWrite={disableWrite}
                                    computeFUnctions={compute_functions}
                                    onSave={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["figma", "compute_functions"],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    filterList={[]}
                                />
                                <Select
                                    value={
                                        blockVarsMap.current[
                                            KEYMAP.global_config
                                        ] >= 0 &&
                                        variables[
                                            blockVarsMap.current[
                                                KEYMAP.global_config
                                            ]
                                        ].value?.mid?.evaluationType
                                    }
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "One by one",
                                            value: "ONE_BY_ONE",
                                        },
                                        {
                                            label: "One by one till first incorrect",
                                            value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                        },
                                        {
                                            label: "All in one go",
                                            value: "ALL_IN_ONE_GO",
                                        },
                                    ]}
                                    style={{
                                        width: "150px",
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.global_config
                                                ],
                                                "value",
                                                "mid",
                                                "evaluationType",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                                {/* <Button
                        style={{
                            flexShrink: 0,
                        }}
                        type="default"
                        icon={<EllipsisOutlined style={{ fontSize: "20px" }} />}
                        disabled={disableWrite}
                    /> */}
                            </div>
                            <Form.Item
                                label="Feedback Type"
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <Select
                                    value={
                                        blockVarsMap.current[
                                            KEYMAP.feedback_type
                                        ] &&
                                        variables[
                                            blockVarsMap.current[
                                                KEYMAP.feedback_type
                                            ]
                                        ].value
                                    }
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "No Feedback",
                                            value: "NO_FEEDBACK",
                                        },
                                        {
                                            label: "Manual Audio",
                                            value: "MANUAL_AUDIO",
                                        },
                                        {
                                            label: "Manual Story",
                                            value: "MANUAL_STORY",
                                        },
                                        {
                                            label: "Generated Audio",
                                            value: "GENERATED_AUDIO",
                                        },
                                        {
                                            label: "Generated Story",
                                            value: "GENERATED_STORY",
                                        },
                                    ]}
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.feedback_type
                                                ],
                                                "value",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            </Form.Item>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "4px",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Form.Item label="Has Highlight?">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={
                                            blockVarsMap.current[
                                                KEYMAP.global_config
                                            ] >= 0 &&
                                            ["true", "1", true, 1].includes(
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.global_config
                                                    ]
                                                ].value?.start?.highlight
                                                    ?.enable,
                                            )
                                        }
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                        KEYMAP.global_config
                                                    ],
                                                    "value",
                                                    "start",
                                                    "highlight",
                                                    "enable",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Has Feedback Stroke?">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={
                                            blockVarsMap.current[
                                                KEYMAP.global_config
                                            ] >= 0 &&
                                            ["true", "1", true, 1].includes(
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.global_config
                                                    ]
                                                ].value?.end?.stroke?.enable,
                                            )
                                        }
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                        KEYMAP.global_config
                                                    ],
                                                    "value",
                                                    "end",
                                                    "stroke",
                                                    "enable",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Has Feedback Fill?">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={
                                            blockVarsMap.current[
                                                KEYMAP.global_config
                                            ] >= 0 &&
                                            ["true", "1", true, 1].includes(
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.global_config
                                                    ]
                                                ].value?.end?.fills?.enable,
                                            )
                                        }
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                        KEYMAP.global_config
                                                    ],
                                                    "value",
                                                    "end",
                                                    "fills",
                                                    "enable",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item label="Audio Pool">
                                <Space>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setOpenAudioModal(
                                                KEYMAP.start_audio,
                                            );
                                        }}
                                        size="small"
                                    >
                                        Add Audio
                                    </Button>
                                </Space>
                            </Form.Item>
                            {blockVarsMap.current[KEYMAP.start_feedback] >=
                                0 && (
                                <Form.Item label="Feedback Pool">
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setOpenFeedbackModal(
                                                KEYMAP.start_feedback,
                                            );
                                        }}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Form.Item>
                            )}
                            <Form.Item label="Component Type">
                                <Select
                                    value={component_type}
                                    placeholder="Select type"
                                    options={Object.values(
                                        COMPONENT_RENDER_TYPES,
                                    ).map((v) => ({
                                        value: v,
                                        label: v,
                                    }))}
                                    style={{
                                        width: "150px",
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["component_type"],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            </Form.Item>
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </Card>
            {currentCluster !== null &&
                variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value[
                    currentCluster
                ] && (
                    <div
                        style={{
                            minWidth: "320px",
                            flexShrink: 1,
                            marginLeft: "8px",
                            // maxWidth: "600px",
                            height: "85vh",
                            overflow: "auto",
                            scrollbarWidth: "none",
                        }}
                    >
                        {variables[blockVarsMap.current[KEYMAP.generic_cluster]]
                            ?.value[currentCluster]?.dragSrcs === 1 ? (
                            <Card>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "15px",
                                        width: "100%",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {renderLinkSelect &&
                                        renderLinkSelect({
                                            value: variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[currentCluster]?.srcs
                                                ?.linked_global_context_variable
                                                ?.name,
                                            onChange: (value) => {
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ],
                                                        "value",
                                                        currentCluster,
                                                        "srcs",
                                                        "linked_global_context_variable",
                                                        "name",
                                                    ],
                                                    value,
                                                );
                                                // tmpBlock = set(
                                                //     tmpBlock,
                                                //     [
                                                //         "figma",
                                                //         "variables",
                                                //         blockVarsMap.current[
                                                //             KEYMAP.generic_cluster
                                                //         ],
                                                //         "value",
                                                //         currentCluster,
                                                //         "srcs",
                                                //         "value",
                                                //     ],
                                                //     value !== null
                                                //         ? `@@${value}@@`
                                                //         : null,
                                                // );
                                                setBlock(tmpBlock);
                                            },
                                        })}
                                    <Form.Item label="Value from variable">
                                        <Select
                                            value={
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ]?.value[currentCluster]?.srcs
                                                    ?.value
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ],
                                                        "value",
                                                        currentCluster,
                                                        "srcs",
                                                        "value",
                                                    ],
                                                    val,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item label="Align Items">
                                    <Select
                                        value={
                                            variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[currentCluster]?.alignItems
                                        }
                                        placeholder="Select type"
                                        options={[
                                            { label: "Left", value: "MIN" },
                                            {
                                                label: "Center",
                                                value: "CENTER",
                                            },
                                            { label: "Right", value: "MAX" },
                                        ]}
                                        style={{
                                            width: "150px",
                                            flexGrow: 0,
                                        }}
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ],
                                                    "value",
                                                    currentCluster,
                                                    "alignItems",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        disabled={disableWrite}
                                    />
                                </Form.Item>
                                {!variables[
                                    blockVarsMap.current[KEYMAP.generic_cluster]
                                ]?.value[currentCluster]?.srcs?.value && (
                                    <TagTable
                                        hasMentions={hasMentions}
                                        mentionsList={mentionsList}
                                        disableWrite={disableWrite}
                                        currentEditor={currentEditor}
                                        setCurrentEditor={setCurrentEditor}
                                        items={
                                            variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[currentCluster]?.srcs
                                                ?.items || []
                                        }
                                        setBlock={(v) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ],
                                                    "value",
                                                    currentCluster,
                                                    "srcs",
                                                    "items",
                                                ],
                                                v,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                        schema={
                                            variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[currentCluster]?.srcs
                                                ?.schema || {}
                                        }
                                        categories={variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value
                                            ?.map((i) => {
                                                if (i.items?.length) {
                                                    return i?.items?.map(
                                                        (j) => j.category,
                                                    );
                                                }
                                                return i?.srcs?.items?.map(
                                                    (j) => j?.category,
                                                );
                                            })
                                            .flat(2)}
                                    />
                                )}
                            </Card>
                        ) : (
                            <Card>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "15px",
                                        width: "100%",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {renderLinkSelect &&
                                        renderLinkSelect({
                                            value: variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[currentCluster]
                                                ?.linked_global_context_variable
                                                ?.name,
                                            onChange: (value) => {
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ],
                                                        "value",
                                                        currentCluster,
                                                        "linked_global_context_variable",
                                                        "name",
                                                    ],
                                                    value,
                                                );
                                                // tmpBlock = set(
                                                //     tmpBlock,
                                                //     [
                                                //         "figma",
                                                //         "variables",
                                                //         blockVarsMap.current[
                                                //             KEYMAP.generic_cluster
                                                //         ],
                                                //         "value",
                                                //         currentCluster,
                                                //         "srcs",
                                                //         "value",
                                                //     ],
                                                //     value !== null
                                                //         ? `@@${value}@@`
                                                //         : null,
                                                // );
                                                setBlock(tmpBlock);
                                            },
                                        })}
                                    <Form.Item label="Value from variable">
                                        <Select
                                            value={
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ]?.value[currentCluster]?.value
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ],
                                                        "value",
                                                        currentCluster,
                                                        "value",
                                                    ],
                                                    val,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </div>
                                <Collapse>
                                    <Collapse.Panel
                                        header="Settings"
                                        key="1"
                                        style={{
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <Select
                                            value={
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ]?.value[currentCluster]
                                                    ?.evaluationConfig?.mid
                                                    ?.evaluationType
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "One by one",
                                                    value: "ONE_BY_ONE",
                                                },
                                                {
                                                    label: "One by one till first incorrect",
                                                    value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                                },
                                                {
                                                    label: "All in one go",
                                                    value: "ALL_IN_ONE_GO",
                                                },
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                let tmpBlock = cloneDeep(block);
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ],
                                                        "value",
                                                        currentCluster,
                                                        "evaluationConfig",
                                                        "mid",
                                                        "evaluationType",
                                                    ],
                                                    val,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            disabled={disableWrite}
                                        />
                                        <Form.Item
                                            label="Feedback Type"
                                            style={{
                                                marginTop: "20px",
                                                maxWidth: "400px",
                                            }}
                                        >
                                            <Select
                                                value={
                                                    variables[
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ]
                                                    ]?.value[currentCluster]
                                                        ?.feedback_type
                                                }
                                                placeholder="Select type"
                                                options={[
                                                    {
                                                        label: "No Feedback",
                                                        value: "NO_FEEDBACK",
                                                    },
                                                    {
                                                        label: "Manual Audio",
                                                        value: "MANUAL_AUDIO",
                                                    },
                                                    {
                                                        label: "Manual Story",
                                                        value: "MANUAL_STORY",
                                                    },
                                                    {
                                                        label: "Generated Audio",
                                                        value: "GENERATED_AUDIO",
                                                    },
                                                    {
                                                        label: "Generated Story",
                                                        value: "GENERATED_STORY",
                                                    },
                                                ]}
                                                style={{
                                                    flexGrow: 0,
                                                }}
                                                onChange={(val) => {
                                                    let tmpBlock =
                                                        cloneDeep(block);
                                                    tmpBlock = set(
                                                        tmpBlock,
                                                        [
                                                            "figma",
                                                            "variables",
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ],
                                                            "value",
                                                            currentCluster,
                                                            "feedback_type",
                                                        ],
                                                        val,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                                disabled={disableWrite}
                                            />
                                        </Form.Item>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "4px",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            <Form.Item label="Has Highlight?">
                                                <Switch
                                                    disabled={disableWrite}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={[
                                                        "true",
                                                        "1",
                                                        true,
                                                        1,
                                                    ].includes(
                                                        variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[currentCluster]
                                                            ?.evaluationConfig
                                                            ?.start?.highlight
                                                            ?.enable,
                                                    )}
                                                    onChange={(val) => {
                                                        let tmpBlock =
                                                            cloneDeep(block);
                                                        tmpBlock = set(
                                                            tmpBlock,
                                                            [
                                                                "figma",
                                                                "variables",
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ],
                                                                "value",
                                                                currentCluster,
                                                                "evaluationConfig",
                                                                "start",
                                                                "highlight",
                                                                "enable",
                                                            ],
                                                            val,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item label="Has Feedback Stroke?">
                                                <Switch
                                                    disabled={disableWrite}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={[
                                                        "true",
                                                        "1",
                                                        true,
                                                        1,
                                                    ].includes(
                                                        variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[currentCluster]
                                                            ?.evaluationConfig
                                                            ?.end?.stroke
                                                            ?.enable,
                                                    )}
                                                    onChange={(val) => {
                                                        let tmpBlock =
                                                            cloneDeep(block);
                                                        tmpBlock = set(
                                                            tmpBlock,
                                                            [
                                                                "figma",
                                                                "variables",
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ],
                                                                "value",
                                                                currentCluster,
                                                                "evaluationConfig",
                                                                "end",
                                                                "stroke",
                                                                "enable",
                                                            ],
                                                            val,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item label="Has Feedback Fill?">
                                                <Switch
                                                    disabled={disableWrite}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={[
                                                        "true",
                                                        "1",
                                                        true,
                                                        1,
                                                    ].includes(
                                                        variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[currentCluster]
                                                            ?.evaluationConfig
                                                            ?.end?.fills
                                                            ?.enable,
                                                    )}
                                                    onChange={(val) => {
                                                        let tmpBlock =
                                                            cloneDeep(block);
                                                        tmpBlock = set(
                                                            tmpBlock,
                                                            [
                                                                "figma",
                                                                "variables",
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ],
                                                                "value",
                                                                currentCluster,
                                                                "evaluationConfig",
                                                                "end",
                                                                "fills",
                                                                "enable",
                                                            ],
                                                            val,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                        <Form.Item label="Align Items">
                                            <Select
                                                value={
                                                    variables[
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ]
                                                    ]?.value[currentCluster]
                                                        ?.alignItems
                                                }
                                                placeholder="Select type"
                                                options={[
                                                    {
                                                        label: "Left",
                                                        value: "MIN",
                                                    },
                                                    {
                                                        label: "Center",
                                                        value: "CENTER",
                                                    },
                                                    {
                                                        label: "Right",
                                                        value: "MAX",
                                                    },
                                                ]}
                                                style={{
                                                    width: "150px",
                                                    flexGrow: 0,
                                                }}
                                                onChange={(val) => {
                                                    let tmpBlock =
                                                        cloneDeep(block);
                                                    tmpBlock = set(
                                                        tmpBlock,
                                                        [
                                                            "figma",
                                                            "variables",
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ],
                                                            "value",
                                                            currentCluster,
                                                            "alignItems",
                                                        ],
                                                        val,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                                disabled={disableWrite}
                                            />
                                        </Form.Item>
                                    </Collapse.Panel>
                                </Collapse>
                                <ItemsTable
                                    hasMentions={hasMentions}
                                    mentionsList={mentionsList}
                                    disableWrite={disableWrite}
                                    currentEditor={currentEditor}
                                    setCurrentEditor={setCurrentEditor}
                                    renderLinkSelect={renderLinkSelect}
                                    items={
                                        variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value[currentCluster]?.items || []
                                    }
                                    setBlock={(v) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ],
                                                "value",
                                                currentCluster,
                                                "items",
                                            ],
                                            v,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    tagList={
                                        variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value?.flatMap(
                                            (v) => v?.srcs?.items || [],
                                        ) || []
                                    }
                                    schema={
                                        variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value[currentCluster]?.schema || {}
                                    }
                                    showColumns={
                                        variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value[currentCluster]
                                            ?.showTableColumns || {}
                                    }
                                />
                            </Card>
                        )}
                    </div>
                )}
        </div>
    );
};

export default DragNDropEditor;
