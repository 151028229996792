import { DEFAULT_CELL } from "../../TableEditor";
import convertPathToClipPath from "./clipPathScript";

function parseSvg(svgString, isPuzzle = false) {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
    const svgElement = svgDoc.documentElement;

    let shapes = [];
    const childElements = Array.from(svgElement.children);

    // Filter to include 'rect', 'path', 'circle', and 'ellipse' elements
    const shapeElements = childElements.filter(
        (element) =>
            element.tagName === "path" ||
            element.tagName === "circle" ||
            element.tagName === "ellipse" ||
            element.tagName === "rect",
    );

    shapeElements.forEach((element, index) => {
        const shape = {};
        const position = {};
        const dimension = {};

        if (element.tagName === "path") {
            const d = element.getAttribute("d");

            // Parse the path data to extract coordinates
            const coords = [];
            const commands = d.match(/[A-Za-z][^A-Za-z]*/g);

            commands.forEach((command) => {
                const cmdType = command.charAt(0).toUpperCase();
                const args = command
                    .slice(1)
                    .trim()
                    .split(/[\s,]+/)
                    .map(Number);
                if (cmdType === "M" || cmdType === "L") {
                    for (let i = 0; i < args.length; i += 2) {
                        coords.push({ x: args[i], y: args[i + 1] });
                    }
                } else if (cmdType === "H") {
                    // Horizontal line: x coordinate changes, y remains the same
                    const x = args[0];
                    const lastY = coords[coords.length - 1].y;
                    coords.push({ x: x, y: lastY });
                } else if (cmdType === "V") {
                    // Vertical line: y coordinate changes, x remains the same
                    const y = args[0];
                    const lastX = coords[coords.length - 1].x;
                    coords.push({ x: lastX, y: y });
                }
                // Additional command types can be handled here
            });

            // Remove duplicate coordinates (e.g., due to closing the path)
            const uniqueCoords = [];
            coords.forEach((coord) => {
                if (
                    !uniqueCoords.some(
                        (c) => c.x === coord.x && c.y === coord.y,
                    )
                ) {
                    uniqueCoords.push(coord);
                }
            });

            const sides = uniqueCoords.length;

            // Assign shape type based on the number of unique coordinates (vertices)
            if (sides === 3) {
                shape.type = "Triangle";
            } else if (sides === 4) {
                // Check if it's a rectangle by comparing angles
                const vectors = [];
                for (let i = 0; i < uniqueCoords.length; i++) {
                    const next = uniqueCoords[(i + 1) % uniqueCoords.length];
                    const curr = uniqueCoords[i];
                    vectors.push({
                        x: next.x - curr.x,
                        y: next.y - curr.y,
                    });
                }

                // Calculate angles between vectors
                const angles = [];
                for (let i = 0; i < vectors.length; i++) {
                    const v1 = vectors[i];
                    const v2 = vectors[(i + 1) % vectors.length];
                    const dot = v1.x * v2.x + v1.y * v2.y;
                    const mag1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
                    const mag2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y);
                    const angle =
                        Math.acos(dot / (mag1 * mag2)) * (180 / Math.PI);
                    angles.push(Math.round(angle));
                }

                // Check if all angles are approximately 90 degrees
                const isRectangle = angles.every(
                    (angle) => Math.abs(angle - 90) < 1,
                );

                shape.type = isRectangle ? "Rectangle" : "Polygon";
            } else if (sides === 5) {
                shape.type = "Pentagon";
            } else if (sides === 6) {
                shape.type = "Hexagon";
            } else {
                shape.type = "Polygon"; // General polygon
            }

            shape.path = convertPathToClipPath(d);

            // Create an SVG element to calculate bounding box
            const tempSvg = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "svg",
            );
            const pathElement = element.cloneNode();
            tempSvg.appendChild(pathElement);
            document.body.appendChild(tempSvg);
            const bbox = pathElement.getBBox();
            document.body.removeChild(tempSvg);

            position.x = bbox.x;
            position.y = bbox.y;
            dimension.width = bbox.width;
            dimension.height = bbox.height;
        } else if (element.tagName === "circle") {
            const cx = parseFloat(element.getAttribute("cx"));
            const cy = parseFloat(element.getAttribute("cy"));
            const r = parseFloat(element.getAttribute("r"));

            position.x = cx - r;
            position.y = cy - r;
            dimension.width = r * 2;
            dimension.height = r * 2;

            shape.type = "Circle";
        } else if (element.tagName === "ellipse") {
            const cx = parseFloat(element.getAttribute("cx"));
            const cy = parseFloat(element.getAttribute("cy"));
            const rx = parseFloat(element.getAttribute("rx"));
            const ry = parseFloat(element.getAttribute("ry"));

            position.x = cx - rx;
            position.y = cy - ry;
            dimension.width = rx * 2;
            dimension.height = ry * 2;

            shape.type = "Ellipse";
        } else if (element.tagName === "rect") {
            const x = parseFloat(element.getAttribute("x")) || 0;
            const y = parseFloat(element.getAttribute("y")) || 0;
            const width = parseFloat(element.getAttribute("width"));
            const height = parseFloat(element.getAttribute("height"));

            position.x = x;
            position.y = y;
            dimension.width = width;
            dimension.height = height;

            shape.type = "Rectangle";
        }

        shapes.push({
            index: index, // Keep track of original index
            drawing: {
                shape: shape,
                position: position,
                dimension: dimension,
            },
        });
    });

    // If isPuzzle is true, compute nearest neighbors
    if (isPuzzle) {
        shapes = computeNearestNeighbors(shapes);
    }

    // Remove the index property before returning
    shapes = shapes.map(({ drawing, neighbors }) => ({
        drawing,
        neighbors: neighbors || [],
    }));

    return shapes;
}

// Updated computeNearestNeighbors function
function computeNearestNeighbors(shapes) {
    shapes.forEach((shape, i) => {
        const neighbors = [];

        shapes.forEach((otherShape, j) => {
            if (i !== j) {
                const dx =
                    otherShape.drawing.position.x - shape.drawing.position.x;
                const dy =
                    otherShape.drawing.position.y - shape.drawing.position.y;
                const distance = Math.hypot(dx, dy);
                neighbors.push({ dx, dy, distance });
            }
        });

        // Store all neighbors sorted by distance
        neighbors.sort((a, b) => a.distance - b.distance);
        shape.neighbors = neighbors.map(({ dx, dy }) => ({ dx, dy }));
    });

    return shapes;
}

// paths is an array of objects which contains a cells array which contains the 2d coordinates of the cells
// create a function which removes all the cells from paths based on coordinate passed

function removeFromPaths(paths, x, y) {
    const newPaths = paths.map((path) => {
        const newCells = path.cells.filter(
            (cell) => cell[0] !== x || cell[1] !== y,
        );
        return { ...path, cells: newCells };
    });

    return newPaths;
}

function getParsedString(svgString, cells, paths, isPuzzle = false) {
    console.log("isPuzzle", isPuzzle);
    const parsedDrawingInfo = parseSvg(svgString, isPuzzle);
    let newCells = cells;
    if (parsedDrawingInfo?.length < cells?.length) {
        for (let i = parsedDrawingInfo.length; i < cells.length; i++) {
            let cellExists = newCells[i];
            if (cellExists) {
                cellExists["hidden"] = true;
                newCells[i] = cellExists;
            }
            if (paths) {
                paths = removeFromPaths(paths, 0, i);
            }
        }
    }
    if (parsedDrawingInfo?.length > cells?.length) {
        for (let i = cells.length; i < parsedDrawingInfo.length; i++) {
            let newCellItem = JSON.parse(JSON.stringify(DEFAULT_CELL));
            newCellItem["fillColor"].enabled = true;
            newCells.push(newCellItem);
        }
    }
    parsedDrawingInfo.forEach((item, key) => {
        let cellExists = newCells[key];
        if (cellExists) {
            cellExists["drawing"] = JSON.parse(JSON.stringify(item.drawing));
            cellExists.drawing.neighbors = item.neighbors;
            cellExists["hidden"] = false;
            newCells[key] = cellExists;
        }
    });
    return { newCells, paths };
}

export default getParsedString;
