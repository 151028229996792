import {
    CheckOutlined,
    CloseOutlined,
    EllipsisOutlined,
    MenuOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Dropdown,
    Form,
    Menu,
    Select,
    Space,
    Switch,
    message,
    Input,
    Collapse,
} from "antd";
import { cloneDeep, pullAt, set } from "lodash";
import { useEffect, useRef, useState } from "react";
import {
    KEYMAP,
    CLUSTER_KEYS,
    COMPUTE_FUNC_INPUT,
    COMPUTE_FUNCTION_MULTIINPUT,
} from "./mapping";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { getActionMenuItems } from "src/modules/worksheet/components/WorksheetEditor/helpers";
import GenericItemsForm from "../common/GenericItemsForm";
import { AudioPoolModal } from "../../../../Figma/renderEditor/AudioPoolModal";
import { LogicModal } from "../common/LogicModal";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import ComponentDetails from "../common/ComponentDetails";
import { FeedbackPool } from "../common/FeedbackPool";
import CollapseBtn from "src/modules/worksheet/components/WorksheetEditor/helpers/CollapseBtn";
import TagsTable from "../common/TagsTable";
import { checkGenericClusterValid } from "../../../isValid";

export const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            marginRight: "5px",
        }}
    />
));

const SortableItem = SortableElement(
    ({
        child,
        disableWrite,
        value,
        deleteBlock,
        addBlock,
        onClick,
        currentCluster,
        setBlock,
        schema,
        idx,
    }) => {
        const getBlockActionsMenu = () => {
            return (
                <Menu
                    items={[
                        ...getActionMenuItems({
                            idx,
                            type: child.type,
                            disableDuplicateAction: disableWrite,
                            deleteBlock,
                            addBlockCustom: addBlock,
                            block: child,
                            // for progression stage
                            updateId: true,
                            ignoreId: false,
                            updateGenricId: true,
                        }),
                        {
                            key: 3,
                            label: <span>Copy JSON</span>,
                            onClick: async () => {
                                navigator.clipboard.writeText(
                                    JSON.stringify(child),
                                );
                                message.info(`Copied Cluster to clipboard!`);
                            },
                        },
                        {
                            key: 4,
                            label: <span>Refetch</span>,
                            onClick: async () => {
                                let listName =
                                    child?.type === "generic_collection"
                                        ? "inputs_list"
                                        : "tags_list";
                                let childVars = cloneDeep(
                                    child?.props[listName]?.value?.variables
                                        ?.value?.variables,
                                );

                                let mapArray1: any = {};
                                let genericMap: any = {};

                                childVars.forEach((item, i) => {
                                    if (
                                        item?.objectType ===
                                        "DYNAMIC_COMPONENTS_DATA"
                                    ) {
                                        genericMap[item?.name] = i + 1;
                                    }
                                    mapArray1[item.name] = true;
                                });

                                let objectsToAdd: any[] = [];

                                schema?.props[
                                    listName
                                ]?.value?.variables?.value?.variables.forEach(
                                    (item) => {
                                        if (
                                            genericMap[item.name] > 0 &&
                                            item?.schema
                                        ) {
                                            const itemSchema =
                                                typeof item.schema === "string"
                                                    ? JSON.parse(item.schema)
                                                    : item.schema;
                                            const itemSchemaMap: any = {};
                                            itemSchema?.forEach(
                                                (element: {
                                                    type: string | number;
                                                    props: any;
                                                }) => {
                                                    itemSchemaMap[
                                                        element?.type
                                                    ] = element.props;
                                                },
                                            );
                                            childVars[
                                                genericMap[item.name] - 1
                                            ].schema = itemSchema;
                                            childVars[
                                                genericMap[item.name] - 1
                                            ].value = childVars[
                                                genericMap[item.name] - 1
                                            ]?.value?.map((v) => {
                                                v.props = {
                                                    ...itemSchemaMap[v.type],
                                                    ...v.props,
                                                };
                                                return v;
                                            });
                                        }
                                        if (!mapArray1[item.name]) {
                                            objectsToAdd?.push(item);
                                        }
                                    },
                                );
                                let newArray = childVars.concat(objectsToAdd);
                                let tmpBlock = cloneDeep(child);
                                tmpBlock = set(
                                    tmpBlock,
                                    [
                                        "props",
                                        listName,
                                        "value",
                                        "variables",
                                        "value",
                                        "variables",
                                    ],
                                    newArray,
                                );

                                if (child?.type === "generic_collection") {
                                    mapArray1 = {};
                                    child?.props[
                                        listName
                                    ]?.value?.compute_functions?.value?.functions.forEach(
                                        (item) => {
                                            mapArray1[item.name] = true;
                                        },
                                    );
                                    objectsToAdd = schema?.props[
                                        listName
                                    ]?.value?.compute_functions?.value?.functions.filter(
                                        (item) => !mapArray1[item.name],
                                    );
                                    newArray =
                                        child?.props[
                                            listName
                                        ]?.value?.compute_functions?.value?.functions.concat(
                                            objectsToAdd,
                                        );

                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "props",
                                            listName,
                                            "value",
                                            "compute_functions",
                                            "value",
                                            "functions",
                                        ],
                                        newArray,
                                    );
                                }
                                setBlock(tmpBlock);
                                message.success("Updated!");
                            },
                        },
                    ]}
                />
            );
        };

        const isValid = checkGenericClusterValid(child);

        return (
            <Card
                hoverable
                style={{
                    marginBottom: "20px",
                    boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                    background: !isValid
                        ? "#FFD6D7"
                        : currentCluster === value
                        ? "#E6F7FF"
                        : "#ffffff",
                    borderRight:
                        currentCluster === value
                            ? !isValid
                                ? "4px solid red"
                                : "4px solid #1890FF"
                            : 0,
                }}
                onClick={onClick}
                bodyStyle={{
                    background:
                        currentCluster === value ? "#E6F7FF" : "#ffffff",
                    padding: 0,
                }}
                extra={
                    <Dropdown
                        overlay={() => getBlockActionsMenu()}
                        trigger={["click"]}
                        overlayStyle={{ width: "100px" }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Button
                                style={{
                                    flexShrink: 0,
                                }}
                                type="default"
                                icon={
                                    <EllipsisOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                }
                                disabled={disableWrite}
                            />
                        </a>
                    </Dropdown>
                }
                title={
                    <>
                        <DragHandle /> {CLUSTER_KEYS[child.type]}
                    </>
                }
            />
        );
    },
);

const SortableList = SortableContainer(({ children }) => {
    return <div style={{ overflow: "auto", gap: "20px" }}>{children}</div>;
});

const MultiInputEditor = (props: any) => {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
    } = props;
    const {
        figma: { variables, compute_functions },
    } = block;
    const blockVarsMap: any = useRef({});

    const [currentCluster, setCurCluster] = useState(null);
    const [openAudioModal, setOpenAudioModal] = useState("");
    const [openInputAudioModal, setOpenInputAudioModal] = useState("");
    const [openFeedbackModal, setOpenFeedbackModal] = useState("");
    const [openInputFeedbackModal, setOpenInputFeedbackModal] = useState("");
    const [tempChildren, setTempChildren] = useState("");
    const [load, setLoad] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        setLoad(true);
        variables?.forEach((element: { name: string | number }, i: any) => {
            blockVarsMap.current[element.name] = i;
        });
        let v = setTimeout(() => {
            setLoad(false);
        }, 50);
        return () => {
            clearTimeout(v);
        };
    }, []);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const tmpChildren = arrayMoveImmutable(
            variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);

        let tmpBlock = cloneDeep(block);
        tmpBlock = set(
            tmpBlock,
            [
                "figma",
                "variables",
                blockVarsMap.current[KEYMAP.generic_cluster],
                "value",
            ],
            tmpChildren,
        );
        setBlock(tmpBlock);
        setCurCluster(newIndex);
    };

    return load ? (
        <></>
    ) : (
        <div
            style={{
                paddingBottom: "10px",
                display: "flex",
                flexGrow: 0,
            }}
        >
            {openAudioModal && openAudioModal !== "" && (
                <AudioPoolModal
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenAudioModal(value === true ? openAudioModal : "");
                        setOpenInputAudioModal("");
                    }}
                    name={openAudioModal}
                    audioPool={
                        blockVarsMap.current[openAudioModal] >= 0 &&
                        variables[blockVarsMap.current[openAudioModal]].value
                    }
                    disableWrite={disableWrite}
                    schema={{
                        x: "",
                        audio: "",
                    }}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[openAudioModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            )}
            {currentCluster !== null &&
                openInputAudioModal &&
                openInputAudioModal !== "" && (
                    <AudioPoolModal
                        isModalOpen={true}
                        setIsModalOpen={(value: boolean) => {
                            setOpenInputAudioModal(
                                value === true ? openInputAudioModal : "",
                            );
                            setOpenAudioModal("");
                        }}
                        name={openInputAudioModal}
                        audioPool={
                            variables[
                                blockVarsMap.current[KEYMAP.generic_cluster]
                            ]?.value[
                                currentCluster
                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                (v: { name: string }) =>
                                    v.name === openInputAudioModal,
                            ).value
                        }
                        disableWrite={disableWrite}
                        schema={{
                            x: "",
                            audio: "",
                        }}
                        onSave={(val: any) => {
                            let tmpBlock = cloneDeep(block);
                            const idx = variables[
                                blockVarsMap.current[KEYMAP.generic_cluster]
                            ]?.value[
                                currentCluster
                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                (v: { name: string }) =>
                                    v.name === openInputAudioModal,
                            );
                            if (idx >= 0) {
                                tmpBlock = set(
                                    tmpBlock,
                                    [
                                        "figma",
                                        "variables",
                                        blockVarsMap.current[
                                            KEYMAP.generic_cluster
                                        ],
                                        "value",
                                        currentCluster,
                                        "props",
                                        "inputs_list",
                                        "value",
                                        "variables",
                                        "value",
                                        "variables",
                                        idx,
                                        "value",
                                    ],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }
                        }}
                    />
                )}
            {openFeedbackModal && openFeedbackModal !== "" && (
                <FeedbackPool
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenFeedbackModal(
                            value === true ? openFeedbackModal : "",
                        );
                        setOpenInputFeedbackModal("");
                    }}
                    name={openFeedbackModal}
                    feedbackPool={
                        blockVarsMap.current[openFeedbackModal] >= 0 &&
                        variables[blockVarsMap.current[openFeedbackModal]].value
                    }
                    disableWrite={disableWrite}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[openFeedbackModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                />
            )}
            {currentCluster !== null &&
                openInputFeedbackModal &&
                openInputFeedbackModal !== "" && (
                    <FeedbackPool
                        isModalOpen={true}
                        setIsModalOpen={(value: boolean) => {
                            setOpenInputFeedbackModal(
                                value === true ? openInputFeedbackModal : "",
                            );
                            setOpenFeedbackModal("");
                        }}
                        name={openInputFeedbackModal}
                        feedbackPool={
                            variables[
                                blockVarsMap.current[KEYMAP.generic_cluster]
                            ]?.value[
                                currentCluster
                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                (v: { name: string }) =>
                                    v.name === openInputFeedbackModal,
                            ).value
                        }
                        disableWrite={disableWrite}
                        onSave={(val: any) => {
                            let tmpBlock = cloneDeep(block);
                            const idx = variables[
                                blockVarsMap.current[KEYMAP.generic_cluster]
                            ]?.value[
                                currentCluster
                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                (v: { name: string }) =>
                                    v.name === openInputFeedbackModal,
                            );
                            if (idx >= 0) {
                                tmpBlock = set(
                                    tmpBlock,
                                    [
                                        "figma",
                                        "variables",
                                        blockVarsMap.current[
                                            KEYMAP.generic_cluster
                                        ],
                                        "value",
                                        currentCluster,
                                        "props",
                                        "inputs_list",
                                        "value",
                                        "variables",
                                        "value",
                                        "variables",
                                        idx,
                                        "value",
                                    ],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }
                        }}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        currentEditor={currentEditor}
                        setCurrentEditor={setCurrentEditor}
                    />
                )}
            <Card
                style={{
                    minWidth: "50px",
                    maxWidth: "300px",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                }}
                bodyStyle={{
                    padding: "0px",
                    paddingTop: collapsed ? "10px" : "15px",
                }}
            >
                <div
                    style={{
                        height: "25px",
                    }}
                >
                    <CollapseBtn
                        collapsed={collapsed}
                        toggleCollapsed={toggleCollapsed}
                        style={{
                            marginBottom: 10,
                            marginLeft: collapsed ? 10 : 20,
                            marginRight: collapsed ? 10 : 0,
                            position: "fixed",
                        }}
                    />
                </div>
                <div
                    style={{
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        height: "80vh",
                        display: collapsed ? "none" : "block",
                        padding: 20,
                        paddingTop: 30,
                    }}
                >
                    <SortableList onSortEnd={onSortEnd} useDragHandle>
                        {(
                            variables[
                                blockVarsMap.current[KEYMAP.generic_cluster]
                            ]?.value || []
                        ).map((child, idx) => (
                            <SortableItem
                                disabled={disableWrite}
                                key={`item-${idx}`}
                                index={idx}
                                idx={idx}
                                value={idx}
                                child={child}
                                disableWrite={disableWrite}
                                onClick={() => setCurCluster(idx)}
                                currentCluster={currentCluster}
                                addBlock={(
                                    type: string,
                                    index: number,
                                    newBlock: any,
                                ) => {
                                    let tmpBlock = cloneDeep(block);
                                    const tmpChildren = [
                                        ...variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value,
                                    ];
                                    tmpChildren.splice(index, 0, newBlock);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                        ],
                                        tmpChildren,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                deleteBlock={(index: number) => {
                                    let tmpBlock = cloneDeep(block);
                                    const tmpChildren = [
                                        ...variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value,
                                    ];
                                    pullAt(tmpChildren, idx);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                        ],
                                        tmpChildren,
                                    );
                                    setBlock(tmpBlock);
                                    setCurCluster(idx - 1);
                                }}
                                computeFUnctions={
                                    variables[
                                        blockVarsMap.current[
                                            KEYMAP.generic_cluster
                                        ]
                                    ]?.value[idx]?.props?.inputs_list?.value
                                        ?.compute_functions?.value?.functions
                                }
                                setBlock={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                            idx,
                                        ],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                onSave={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                            idx,
                                            "props",
                                            "inputs_list",
                                            "value",
                                            "compute_functions",
                                            "value",
                                            "functions",
                                        ],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                schema={variables?.[
                                    blockVarsMap.current?.[
                                        KEYMAP.generic_cluster
                                    ]
                                ]?.schema?.find((v) => v?.type === child?.type)}
                            />
                        ))}
                    </SortableList>
                    {!variables[blockVarsMap.current[KEYMAP.generic_cluster]]
                        ?.value?.length && (
                        <div
                            style={{
                                fontSize: "16px",
                                color: "red",
                            }}
                        >
                            No clusters added
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "10px",
                            flexWrap: "wrap",
                        }}
                    >
                        {!disableWrite &&
                            variables?.[
                                blockVarsMap.current?.[KEYMAP.generic_cluster]
                            ]?.schema?.map((item: any, key: number) => (
                                <Button
                                    key={key}
                                    ghost
                                    type="primary"
                                    onClick={() => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ],
                                                "value",
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ].value?.length,
                                            ],
                                            item,
                                        );
                                        setBlock(tmpBlock);
                                        setCurCluster(
                                            variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ].value?.length,
                                        );
                                    }}
                                >
                                    Add {CLUSTER_KEYS[item.type]}
                                </Button>
                            ))}
                    </div>
                    {!disableWrite && (
                        <Form.Item>
                            <Input.TextArea
                                disabled={disableWrite}
                                value={tempChildren}
                                placeholder="Paste Cluster here"
                                onChange={(e) =>
                                    setTempChildren(e.target.value)
                                }
                                rows={2}
                                style={{
                                    marginTop: "10px",
                                    width: "100%",
                                }}
                            />
                            <Button
                                disabled={disableWrite || !tempChildren}
                                style={{ marginTop: "10px" }}
                                type="primary"
                                size="small"
                                onClick={() => {
                                    try {
                                        const parsedJson =
                                            JSON.parse(tempChildren);
                                        if (
                                            !Object.keys(CLUSTER_KEYS).includes(
                                                parsedJson?.type,
                                            )
                                        ) {
                                            message.warn("Invalid JSON");
                                            return;
                                        }
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ],
                                                "value",
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ].value?.length,
                                            ],
                                            updateKeysAndCopy(
                                                parsedJson,
                                                false,
                                                true,
                                            ),
                                        );
                                        setBlock(tmpBlock);
                                        setCurCluster(
                                            variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ].value?.length,
                                        );
                                        setTempChildren("");
                                        message.info("Updated!");
                                    } catch (e) {
                                        //  captureException(e)
                                        message.error("Error in JSON!");
                                    }
                                }}
                            >
                                Add New Cluster
                            </Button>
                        </Form.Item>
                    )}
                    <Collapse>
                        <Collapse.Panel header="Settings" key="1">
                            <ComponentDetails
                                block={block}
                                setBlock={setBlock}
                                disableWrite={disableWrite}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    gap: "4px",
                                }}
                            >
                                <LogicModal
                                    disableWrite={disableWrite}
                                    computeFUnctions={compute_functions}
                                    onSave={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["figma", "compute_functions"],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    filterList={COMPUTE_FUNCTION_MULTIINPUT}
                                />
                                <Select
                                    value={
                                        blockVarsMap.current[
                                            KEYMAP.check_type
                                        ] >= 0 &&
                                        variables[
                                            blockVarsMap.current[
                                                KEYMAP.check_type
                                            ]
                                        ].value
                                    }
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "One by one",
                                            value: "ONE_BY_ONE",
                                        },
                                        {
                                            label: "One by one till first incorrect",
                                            value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                        },
                                        {
                                            label: "All in one go",
                                            value: "ALL_IN_ONE_GO",
                                        },
                                    ]}
                                    style={{
                                        width: "150px",
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.check_type
                                                ],
                                                "value",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            </div>
                            <Form.Item
                                label="Feedback Type"
                                style={{
                                    marginTop: "20px",
                                }}
                            >
                                <Select
                                    value={
                                        blockVarsMap.current[
                                            KEYMAP.feedback_type
                                        ] >= 0 &&
                                        variables[
                                            blockVarsMap.current[
                                                KEYMAP.feedback_type
                                            ]
                                        ].value
                                    }
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "No Feedback",
                                            value: "NO_FEEDBACK",
                                        },
                                        {
                                            label: "Manual Audio",
                                            value: "MANUAL_AUDIO",
                                        },
                                        {
                                            label: "Manual Story",
                                            value: "MANUAL_STORY",
                                        },
                                        {
                                            label: "Generated Audio",
                                            value: "GENERATED_AUDIO",
                                        },
                                        {
                                            label: "Generated Story",
                                            value: "GENERATED_STORY",
                                        },
                                    ]}
                                    style={{
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.feedback_type
                                                ],
                                                "value",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            </Form.Item>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "4px",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Form.Item label="Has Highlight?">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={
                                            blockVarsMap.current[
                                                KEYMAP.has_highlight
                                            ] >= 0 &&
                                            ["true", "1", true, 1].includes(
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.has_highlight
                                                    ]
                                                ].value,
                                            )
                                        }
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                        KEYMAP.has_highlight
                                                    ],
                                                    "value",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Has Feedback Stroke?">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={
                                            blockVarsMap.current[
                                                KEYMAP.has_correctness_stroke
                                            ] >= 0 &&
                                            ["true", "1", true, 1].includes(
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP
                                                            .has_correctness_stroke
                                                    ]
                                                ].value,
                                            )
                                        }
                                        onChange={(val) => {
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                        KEYMAP
                                                            .has_correctness_stroke
                                                    ],
                                                    "value",
                                                ],
                                                val,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item label="Audio Pool">
                                <Space>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setOpenAudioModal(
                                                KEYMAP.start_audio,
                                            );
                                            setOpenInputAudioModal("");
                                        }}
                                        size="small"
                                    >
                                        Start
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setOpenAudioModal(KEYMAP.end_audio);
                                            setOpenInputAudioModal("");
                                        }}
                                        size="small"
                                    >
                                        End
                                    </Button>
                                </Space>
                            </Form.Item>
                            {blockVarsMap.current[KEYMAP.start_feedback] >=
                                0 && (
                                <Form.Item label="Feedback Pool">
                                    <Space>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                setOpenFeedbackModal(
                                                    KEYMAP.start_feedback,
                                                );
                                                setOpenInputFeedbackModal("");
                                            }}
                                            size="small"
                                        >
                                            Start
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                setOpenFeedbackModal(
                                                    KEYMAP.end_feedback,
                                                );
                                                setOpenInputFeedbackModal("");
                                            }}
                                            size="small"
                                        >
                                            End
                                        </Button>
                                    </Space>
                                </Form.Item>
                            )}
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </Card>
            {currentCluster !== null &&
                variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value[
                    currentCluster
                ] && (
                    <div
                        style={{
                            minWidth: "320px",
                            flexShrink: 1,
                            marginLeft: "8px",
                            // maxWidth: "600px",
                            height: "85vh",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                        }}
                    >
                        {variables[blockVarsMap.current[KEYMAP.generic_cluster]]
                            ?.value[currentCluster]?.type ===
                        "generic_collection" ? (
                            <Card>
                                <Collapse>
                                    <Collapse.Panel
                                        header="Settings"
                                        key="1"
                                        style={{
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                // justifyContent: "space-between",
                                                width: "100%",
                                                gap: "16px",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            <LogicModal
                                                disableWrite={disableWrite}
                                                computeFUnctions={
                                                    variables[
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ]
                                                    ]?.value[currentCluster]
                                                        ?.props?.inputs_list
                                                        ?.value
                                                        ?.compute_functions
                                                        ?.value?.functions
                                                }
                                                onSave={(val) => {
                                                    let tmpBlock =
                                                        cloneDeep(block);
                                                    tmpBlock = set(
                                                        tmpBlock,
                                                        [
                                                            "figma",
                                                            "variables",
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ],
                                                            "value",
                                                            currentCluster,
                                                            "props",
                                                            "inputs_list",
                                                            "value",
                                                            "compute_functions",
                                                            "value",
                                                            "functions",
                                                        ],
                                                        val,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                                filterList={COMPUTE_FUNC_INPUT}
                                            />
                                            <Select
                                                value={
                                                    variables[
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ]
                                                    ]?.value[
                                                        currentCluster
                                                    ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                        (v: { name: string }) =>
                                                            v.name ===
                                                            "check_type",
                                                    ).value ||
                                                    variables[
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ]
                                                    ]?.value[
                                                        currentCluster
                                                    ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                        (v: { name: string }) =>
                                                            v.name ===
                                                            "check_type",
                                                    ).default
                                                }
                                                placeholder="Select type"
                                                options={
                                                    // JSON.parse(
                                                    //     variables[
                                                    //         blockVarsMap.current[
                                                    //             KEYMAP.generic_cluster
                                                    //         ]
                                                    //     ]?.value[
                                                    //         currentCluster
                                                    //     ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                    //         (v: { name: string }) =>
                                                    //             v.name === "check_type",
                                                    //     ).options,
                                                    // )
                                                    [
                                                        {
                                                            label: "One by one",
                                                            value: "ONE_BY_ONE",
                                                        },
                                                        {
                                                            label: "All in one go",
                                                            value: "ALL_IN_ONE_GO",
                                                        },
                                                    ]
                                                }
                                                style={{
                                                    width: "150px",
                                                    flexGrow: 0,
                                                }}
                                                onChange={(val) => {
                                                    let tmpBlock =
                                                        cloneDeep(block);
                                                    const idx = variables[
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ]
                                                    ]?.value[
                                                        currentCluster
                                                    ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                        (v: { name: string }) =>
                                                            v.name ===
                                                            "check_type",
                                                    );
                                                    if (idx >= 0) {
                                                        tmpBlock = set(
                                                            tmpBlock,
                                                            [
                                                                "figma",
                                                                "variables",
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ],
                                                                "value",
                                                                currentCluster,
                                                                "props",
                                                                "inputs_list",
                                                                "value",
                                                                "variables",
                                                                "value",
                                                                "variables",
                                                                idx,
                                                                "value",
                                                            ],
                                                            val,
                                                        );
                                                        setBlock(tmpBlock);
                                                    }
                                                }}
                                                disabled={disableWrite}
                                            />
                                            <Form.Item label="Feedback Type">
                                                <Select
                                                    value={
                                                        variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "feedback_type",
                                                        ).value ||
                                                        variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "feedback_type",
                                                        ).default
                                                    }
                                                    placeholder="Select type"
                                                    options={
                                                        typeof variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "feedback_type",
                                                        ).options === "string"
                                                            ? JSON.parse(
                                                                  variables[
                                                                      blockVarsMap
                                                                          .current[
                                                                          KEYMAP
                                                                              .generic_cluster
                                                                      ]
                                                                  ]?.value[
                                                                      currentCluster
                                                                  ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                      (v: {
                                                                          name: string;
                                                                      }) =>
                                                                          v.name ===
                                                                          "feedback_type",
                                                                  ).options,
                                                              )
                                                            : variables[
                                                                  blockVarsMap
                                                                      .current[
                                                                      KEYMAP
                                                                          .generic_cluster
                                                                  ]
                                                              ]?.value[
                                                                  currentCluster
                                                              ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                  (v: {
                                                                      name: string;
                                                                  }) =>
                                                                      v.name ===
                                                                      "feedback_type",
                                                              ).options
                                                    }
                                                    style={{
                                                        width: "150px",
                                                        flexGrow: 0,
                                                    }}
                                                    onChange={(val) => {
                                                        let tmpBlock =
                                                            cloneDeep(block);
                                                        const idx = variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "feedback_type",
                                                        );
                                                        if (idx >= 0) {
                                                            tmpBlock = set(
                                                                tmpBlock,
                                                                [
                                                                    "figma",
                                                                    "variables",
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ],
                                                                    "value",
                                                                    currentCluster,
                                                                    "props",
                                                                    "inputs_list",
                                                                    "value",
                                                                    "variables",
                                                                    "value",
                                                                    "variables",
                                                                    idx,
                                                                    "value",
                                                                ],
                                                                val,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }
                                                    }}
                                                    disabled={disableWrite}
                                                />
                                            </Form.Item>
                                            {variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[
                                                currentCluster
                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                (v: { name: string }) =>
                                                    v.name ===
                                                    "evaluation_type",
                                            ) && (
                                                <Form.Item label="Evaluation Type">
                                                    <Select
                                                        value={
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                (v: {
                                                                    name: string;
                                                                }) =>
                                                                    v.name ===
                                                                    "evaluation_type",
                                                            )?.value ||
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                (v: {
                                                                    name: string;
                                                                }) =>
                                                                    v.name ===
                                                                    "evaluation_type",
                                                            )?.default
                                                        }
                                                        placeholder="Select type"
                                                        options={
                                                            typeof variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                (v: {
                                                                    name: string;
                                                                }) =>
                                                                    v.name ===
                                                                    "evaluation_type",
                                                            )?.options ===
                                                            "string"
                                                                ? JSON.parse(
                                                                      variables[
                                                                          blockVarsMap
                                                                              .current[
                                                                              KEYMAP
                                                                                  .generic_cluster
                                                                          ]
                                                                      ]?.value[
                                                                          currentCluster
                                                                      ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                          (v: {
                                                                              name: string;
                                                                          }) =>
                                                                              v.name ===
                                                                              "evaluation_type",
                                                                      ).options,
                                                                  )
                                                                : variables[
                                                                      blockVarsMap
                                                                          .current[
                                                                          KEYMAP
                                                                              .generic_cluster
                                                                      ]
                                                                  ]?.value[
                                                                      currentCluster
                                                                  ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                      (v: {
                                                                          name: string;
                                                                      }) =>
                                                                          v.name ===
                                                                          "evaluation_type",
                                                                  ).options
                                                        }
                                                        style={{
                                                            width: "150px",
                                                            flexGrow: 0,
                                                        }}
                                                        onChange={(val) => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            const idx =
                                                                variables[
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ]
                                                                ]?.value[
                                                                    currentCluster
                                                                ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "evaluation_type",
                                                                );
                                                            if (idx >= 0) {
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        currentCluster,
                                                                        "props",
                                                                        "inputs_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        idx,
                                                                        "value",
                                                                    ],
                                                                    val,
                                                                );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>
                                            )}
                                        </div>
                                        {variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value[
                                            currentCluster
                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                            (v: { name: string }) =>
                                                v.name === "feedback_type",
                                        ).value === "GENERATED_FEEDBACK" && (
                                            <div
                                                style={{
                                                    display: "grid",
                                                    width: "100%",
                                                    gap: "20px",
                                                }}
                                            >
                                                <Form.Item label="Feedback Prompt">
                                                    <Input.TextArea
                                                        value={
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "feedback_prompt",
                                                                )
                                                                ?.value?.toString() ||
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "feedback_prompt",
                                                                )
                                                                ?.default?.toString()
                                                        }
                                                        placeholder="Select type"
                                                        onChange={(val) => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            const idx =
                                                                variables[
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ]
                                                                ]?.value[
                                                                    currentCluster
                                                                ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "feedback_prompt",
                                                                );
                                                            if (idx >= 0) {
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        currentCluster,
                                                                        "props",
                                                                        "inputs_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        idx,
                                                                        "value",
                                                                    ],
                                                                    val?.target
                                                                        ?.value,
                                                                );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>
                                                <Form.Item label="Get_feedback_prompt">
                                                    <Input.TextArea
                                                        value={
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "get_feedback_prompt",
                                                                )
                                                                ?.value?.toString() ||
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "get_feedback_prompt",
                                                                )
                                                                ?.default?.toString()
                                                        }
                                                        placeholder="Select type"
                                                        onChange={(val) => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            const idx =
                                                                variables[
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ]
                                                                ]?.value[
                                                                    currentCluster
                                                                ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "get_feedback_prompt",
                                                                );
                                                            if (idx >= 0) {
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        currentCluster,
                                                                        "props",
                                                                        "inputs_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        idx,
                                                                        "value",
                                                                    ],
                                                                    val?.target
                                                                        ?.value,
                                                                );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>
                                                <Form.Item label="Update feedback">
                                                    <Input.TextArea
                                                        value={
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "update_feedback",
                                                                )
                                                                ?.value?.toString() ||
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "update_feedback",
                                                                )
                                                                ?.default?.toString()
                                                        }
                                                        placeholder="Select type"
                                                        onChange={(val) => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            const idx =
                                                                variables[
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ]
                                                                ]?.value[
                                                                    currentCluster
                                                                ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "update_feedback",
                                                                );
                                                            if (idx >= 0) {
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        currentCluster,
                                                                        "props",
                                                                        "inputs_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        idx,
                                                                        "value",
                                                                    ],
                                                                    val?.target
                                                                        ?.value,
                                                                );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>
                                            </div>
                                        )}
                                        {(variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value[
                                            currentCluster
                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                            (v: { name: string }) =>
                                                v.name === "feedback_type",
                                        ).value === "GENERATED_FEEDBACK" ||
                                            variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[
                                                currentCluster
                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                (v: { name: string }) =>
                                                    v.name ===
                                                    "evaluation_type",
                                            )?.value === "GROQ") && (
                                            <Form.Item label="Question Text">
                                                <Input.TextArea
                                                    value={
                                                        variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "question_text",
                                                        )?.value ||
                                                        variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "question_text",
                                                        )?.default
                                                    }
                                                    placeholder="Select type"
                                                    onChange={(val) => {
                                                        let tmpBlock =
                                                            cloneDeep(block);
                                                        const idx = variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "question_text",
                                                        );
                                                        if (idx >= 0) {
                                                            tmpBlock = set(
                                                                tmpBlock,
                                                                [
                                                                    "figma",
                                                                    "variables",
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ],
                                                                    "value",
                                                                    currentCluster,
                                                                    "props",
                                                                    "inputs_list",
                                                                    "value",
                                                                    "variables",
                                                                    "value",
                                                                    "variables",
                                                                    idx,
                                                                    "value",
                                                                ],
                                                                val?.target
                                                                    ?.value,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }
                                                    }}
                                                    disabled={disableWrite}
                                                />
                                            </Form.Item>
                                        )}

                                        {variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value[
                                            currentCluster
                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                            (v: { name: string }) =>
                                                v.name === "evaluation_type",
                                        )?.value === "GROQ" && (
                                            <div
                                                style={{
                                                    display: "grid",
                                                    width: "100%",
                                                    gap: "20px",
                                                }}
                                            >
                                                <Form.Item label="System Prompt">
                                                    <Input.TextArea
                                                        value={
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                (v: {
                                                                    name: string;
                                                                }) =>
                                                                    v.name ===
                                                                    "system_prompt",
                                                            )?.value ||
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                (v: {
                                                                    name: string;
                                                                }) =>
                                                                    v.name ===
                                                                    "system_prompt",
                                                            )?.default
                                                        }
                                                        placeholder="Select type"
                                                        onChange={(val) => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            const idx =
                                                                variables[
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ]
                                                                ]?.value[
                                                                    currentCluster
                                                                ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "system_prompt",
                                                                );
                                                            if (idx >= 0) {
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        currentCluster,
                                                                        "props",
                                                                        "inputs_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        idx,
                                                                        "value",
                                                                    ],
                                                                    val?.target
                                                                        ?.value,
                                                                );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>

                                                <Form.Item label="Get prompt">
                                                    <Input.TextArea
                                                        value={
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "get_prompt",
                                                                )
                                                                ?.value?.toString() ||
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "get_prompt",
                                                                )
                                                                ?.default?.toString()
                                                        }
                                                        placeholder="Select type"
                                                        onChange={(val) => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            const idx =
                                                                variables[
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ]
                                                                ]?.value[
                                                                    currentCluster
                                                                ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "get_prompt",
                                                                );
                                                            if (idx >= 0) {
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        currentCluster,
                                                                        "props",
                                                                        "inputs_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        idx,
                                                                        "value",
                                                                    ],
                                                                    val?.target
                                                                        ?.value,
                                                                );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>
                                                <Form.Item label="Update correct">
                                                    <Input.TextArea
                                                        value={
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "update_correct",
                                                                )
                                                                ?.value?.toString() ||
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "update_correct",
                                                                )
                                                                ?.default?.toString()
                                                        }
                                                        placeholder="Select type"
                                                        onChange={(val) => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            const idx =
                                                                variables[
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ]
                                                                ]?.value[
                                                                    currentCluster
                                                                ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        "update_correct",
                                                                );
                                                            if (idx >= 0) {
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        currentCluster,
                                                                        "props",
                                                                        "inputs_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        idx,
                                                                        "value",
                                                                    ],
                                                                    val?.target
                                                                        ?.value,
                                                                );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>
                                            </div>
                                        )}
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "20px",
                                            }}
                                        >
                                            <Form.Item label="Has Highlight?">
                                                <Switch
                                                    disabled={disableWrite}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={[
                                                        "true",
                                                        "1",
                                                        true,
                                                        1,
                                                    ].includes(
                                                        variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "has_input_list_highlight",
                                                        ).value ??
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                (v: {
                                                                    name: string;
                                                                }) =>
                                                                    v.name ===
                                                                    "has_input_list_highlight",
                                                            ).default,
                                                    )}
                                                    onChange={(val) => {
                                                        let tmpBlock =
                                                            cloneDeep(block);
                                                        const idx = variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "has_input_list_highlight",
                                                        );
                                                        if (idx >= 0) {
                                                            tmpBlock = set(
                                                                tmpBlock,
                                                                [
                                                                    "figma",
                                                                    "variables",
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ],
                                                                    "value",
                                                                    currentCluster,
                                                                    "props",
                                                                    "inputs_list",
                                                                    "value",
                                                                    "variables",
                                                                    "value",
                                                                    "variables",
                                                                    idx,
                                                                    "value",
                                                                ],
                                                                val,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item label="Has Feedback Stroke?">
                                                <Switch
                                                    disabled={disableWrite}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={[
                                                        "true",
                                                        "1",
                                                        true,
                                                        1,
                                                    ].includes(
                                                        variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "has_input_list_correctness_stroke",
                                                        ).value,
                                                    )}
                                                    onChange={(val) => {
                                                        let tmpBlock =
                                                            cloneDeep(block);
                                                        const idx = variables[
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ]
                                                        ]?.value[
                                                            currentCluster
                                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                            (v: {
                                                                name: string;
                                                            }) =>
                                                                v.name ===
                                                                "has_input_list_correctness_stroke",
                                                        );
                                                        if (idx >= 0) {
                                                            tmpBlock = set(
                                                                tmpBlock,
                                                                [
                                                                    "figma",
                                                                    "variables",
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ],
                                                                    "value",
                                                                    currentCluster,
                                                                    "props",
                                                                    "inputs_list",
                                                                    "value",
                                                                    "variables",
                                                                    "value",
                                                                    "variables",
                                                                    idx,
                                                                    "value",
                                                                ],
                                                                val,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                // justifyContent: "space-between",
                                                width: "100%",
                                                gap: "16px",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            <Form.Item label="Audio Pool">
                                                <Space>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            setOpenInputAudioModal(
                                                                KEYMAP.start_audio,
                                                            );
                                                            setOpenAudioModal(
                                                                "",
                                                            );
                                                        }}
                                                        size="small"
                                                    >
                                                        Start
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            setOpenInputAudioModal(
                                                                KEYMAP.end_audio,
                                                            );
                                                            setOpenAudioModal(
                                                                "",
                                                            );
                                                        }}
                                                        size="small"
                                                    >
                                                        End
                                                    </Button>
                                                </Space>
                                            </Form.Item>

                                            {variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[
                                                currentCluster
                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                (v: { name: string }) =>
                                                    v.name ===
                                                    KEYMAP.start_feedback,
                                            ) && (
                                                <Form.Item label="Feedback Pool">
                                                    <Space>
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                setOpenInputFeedbackModal(
                                                                    KEYMAP.start_feedback,
                                                                );
                                                                setOpenFeedbackModal(
                                                                    "",
                                                                );
                                                            }}
                                                            size="small"
                                                        >
                                                            Start
                                                        </Button>
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                setOpenInputFeedbackModal(
                                                                    KEYMAP.end_feedback,
                                                                );
                                                                setOpenFeedbackModal(
                                                                    "",
                                                                );
                                                            }}
                                                            size="small"
                                                        >
                                                            End
                                                        </Button>
                                                    </Space>
                                                </Form.Item>
                                            )}

                                            {variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[
                                                currentCluster
                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                (v: { name: string }) =>
                                                    v.name ===
                                                    KEYMAP.align_items,
                                            ) && (
                                                <Form.Item label="Align Items">
                                                    <Select
                                                        value={
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                (v: {
                                                                    name: string;
                                                                }) =>
                                                                    v.name ===
                                                                    KEYMAP.align_items,
                                                            ).value ||
                                                            variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                (v: {
                                                                    name: string;
                                                                }) =>
                                                                    v.name ===
                                                                    KEYMAP.align_items,
                                                            ).default
                                                        }
                                                        placeholder="Select type"
                                                        options={
                                                            typeof variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value[
                                                                currentCluster
                                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                (v: {
                                                                    name: string;
                                                                }) =>
                                                                    v.name ===
                                                                    KEYMAP.align_items,
                                                            ).options ===
                                                            "string"
                                                                ? JSON.parse(
                                                                      variables[
                                                                          blockVarsMap
                                                                              .current[
                                                                              KEYMAP
                                                                                  .generic_cluster
                                                                          ]
                                                                      ]?.value[
                                                                          currentCluster
                                                                      ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                          (v: {
                                                                              name: string;
                                                                          }) =>
                                                                              v.name ===
                                                                              KEYMAP.align_items,
                                                                      ).options,
                                                                  )
                                                                : variables[
                                                                      blockVarsMap
                                                                          .current[
                                                                          KEYMAP
                                                                              .generic_cluster
                                                                      ]
                                                                  ]?.value[
                                                                      currentCluster
                                                                  ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                                                      (v: {
                                                                          name: string;
                                                                      }) =>
                                                                          v.name ===
                                                                          KEYMAP.align_items,
                                                                  ).options
                                                        }
                                                        style={{
                                                            width: "150px",
                                                            flexGrow: 0,
                                                        }}
                                                        onChange={(val) => {
                                                            let tmpBlock =
                                                                cloneDeep(
                                                                    block,
                                                                );
                                                            const idx =
                                                                variables[
                                                                    blockVarsMap
                                                                        .current[
                                                                        KEYMAP
                                                                            .generic_cluster
                                                                    ]
                                                                ]?.value[
                                                                    currentCluster
                                                                ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                                    (v: {
                                                                        name: string;
                                                                    }) =>
                                                                        v.name ===
                                                                        KEYMAP.align_items,
                                                                );
                                                            if (idx >= 0) {
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        currentCluster,
                                                                        "props",
                                                                        "inputs_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        idx,
                                                                        "value",
                                                                    ],
                                                                    val,
                                                                );
                                                                setBlock(
                                                                    tmpBlock,
                                                                );
                                                            }
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>
                                            )}
                                        </div>
                                    </Collapse.Panel>
                                </Collapse>
                                <GenericItemsForm
                                    {...{
                                        ...props,
                                        setBlock: (v) => {
                                            let tmpBlock = cloneDeep(block);
                                            const idx = variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[
                                                currentCluster
                                            ]?.props?.inputs_list?.value?.variables?.value?.variables?.findIndex(
                                                (v: { name: string }) =>
                                                    v.name === "Components",
                                            );
                                            if (idx >= 0)
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ],
                                                        "value",
                                                        currentCluster,
                                                        "props",
                                                        "inputs_list",
                                                        "value",
                                                        "variables",
                                                        "value",
                                                        "variables",
                                                        idx,
                                                        "value",
                                                    ],
                                                    v,
                                                );
                                            setBlock(tmpBlock);
                                        },
                                        ...variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value[
                                            currentCluster
                                        ]?.props?.inputs_list?.value?.variables?.value?.variables?.find(
                                            (v: { name: string }) =>
                                                v.name === "Components",
                                        ),
                                    }}
                                />
                            </Card>
                        ) : (
                            <TagsTable
                                {...{
                                    disableWrite,
                                    currentEditor,
                                    setCurrentEditor,
                                    hasMentions,
                                    mentionsList,
                                    setBlock: (v) => {
                                        let tmpBlock = cloneDeep(block);
                                        const idx = variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value[
                                            currentCluster
                                        ]?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                                            (v: { name: string }) =>
                                                v.name === "options",
                                        );
                                        if (idx >= 0)
                                            tmpBlock = set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ],
                                                    "value",
                                                    currentCluster,
                                                    "props",
                                                    "tags_list",
                                                    "value",
                                                    "variables",
                                                    "value",
                                                    "variables",
                                                    idx,
                                                    "value",
                                                ],
                                                v,
                                            );
                                        setBlock(tmpBlock);
                                    },
                                    ...variables[
                                        blockVarsMap.current[
                                            KEYMAP.generic_cluster
                                        ]
                                    ]?.value[
                                        currentCluster
                                    ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
                                        (v: { name: string }) =>
                                            v.name === "options",
                                    ),
                                }}
                            />
                        )}
                    </div>
                )}
        </div>
    );
};

export default MultiInputEditor;
