import React, { Component } from "react";
import { Col, Form, Row, Switch, InputNumber, Card, Button, Input } from "antd";
import _ from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { DeleteOutlined } from "@ant-design/icons";

const TableEffects = ({ block, setBlock, disableWrite }: any) => {
    return (
        <Card
            title={<h3>Table Effects</h3>}
            bodyStyle={{
                padding: block.interactionLimit?.enabled ? "12px" : "0px",
            }}
        >
            <Row>
                <Col span={24}>
                    <Form.Item label={"Effects"}>
                        <Button
                            ghost
                            type="primary"
                            size="small"
                            style={{
                                marginBottom: "10px",
                            }}
                            disabled={disableWrite}
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(block);
                                if (tmpBlock.table_effects === undefined) {
                                    tmpBlock.table_effects = [
                                        {
                                            event: "",
                                            computeFunction: { output: "" },
                                        },
                                    ];
                                } else {
                                    tmpBlock.table_effects.push({
                                        event: "",
                                        computeFunction: { output: "" },
                                    });
                                }
                                setBlock(tmpBlock);
                            }}
                        >
                            Add New Table Effects
                        </Button>
                        {block?.table_effects?.map((v: any, key: number) => (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "1rem",
                                }}
                            >
                                <Input
                                    key={key}
                                    disabled={disableWrite}
                                    value={v.event}
                                    placeholder="event"
                                    onChange={(e) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock.table_effects[key].event =
                                            e.target.value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                                <Input.TextArea
                                    key={key}
                                    disabled={disableWrite}
                                    value={v.computeFunction.output}
                                    placeholder="computeFunction"
                                    onChange={(e) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock.table_effects[
                                            key
                                        ].computeFunction.output =
                                            e.target.value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                                <Button
                                    disabled={disableWrite}
                                    icon={<DeleteOutlined />}
                                    type="primary"
                                    shape="circle"
                                    danger
                                    size="small"
                                    onClick={() => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock.table_effects =
                                            tmpBlock.table_effects.filter(
                                                (v: any, index: number) =>
                                                    index !== key,
                                            );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </div>
                        ))}
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};

export default TableEffects;
