import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Form, Input, Switch } from "antd";
import _ from "lodash";

const tappable = {
    enabled: true, //
    variableLink: "tableTappable",
    is_multiple_select: true, //
    name: "modifier",
    max_select: 150,
    min_select: 1,
    function: `function tappable(params) {
        const { variable,frameId } = params;
        const result = []
        let currentSound = "";
        const selectedSound = "https://cdn.homeworkapp.ai/sets-gamify-assets/dev/worksheet/audio/1710667396330.mp3"
        const notSelectedSound = "https://cdn.homeworkapp.ai/sets-gamify-assets/dev/worksheet/audio/1710667360304.mp3"
        for (let i = 0; i < variable.length; i++) {
            const url = variable[i].selected ?
                "https://sets-gamify-assets.s3.ap-south-1.amazonaws.com/dev/figma/assets/66318029471c7a691265b5ed5440e17928cdb070.png" :
                "https://sets-gamify-assets.s3.ap-south-1.amazonaws.com/dev/figma/assets/52c344db70ec90f9019753fadf1586d194ef4506.png"
            const item = {
                type: "UPDATE_IMAGE",
                props: {
                    ...variable[i],
                    url
                }
            }
            if(variable[i].frame_id == frameId){
                if(variable[i].selected){
                    currentSound = selectedSound;
                }else {
                    currentSound = notSelectedSound;
                }
            }
            result.push(item);
        }
        const sound = {
            type:"PLAY_SOUND",
            props:{
                url:currentSound
            }
        };
        result.push(sound);
        return result;
    }`,
};

const Tappable = ({ block, setBlock, disableWrite }) => {
    return (
        <>
            <h3>Tappable Handling</h3>
            <Form.Item label="Enabled?">
                <Switch
                    disabled={disableWrite}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={block.tappable?.enabled}
                    onChange={(value) => {
                        let tmpBlock = _.cloneDeep(block);
                        if (tmpBlock.tappable === undefined)
                            tmpBlock.tappable = _.cloneDeep(tappable);
                        tmpBlock.tappable.enabled = value;
                        setBlock(tmpBlock);
                    }}
                />
            </Form.Item>
            {block.tappable?.enabled && (
                <>
                    <Form.Item label="Multi Select?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={block.tappable?.is_multiple_select}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                if (tmpBlock.tappable === undefined)
                                    tmpBlock.tappable = _.cloneDeep(tappable);
                                tmpBlock.tappable.is_multiple_select = value;
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    {/*Min Select*/}
                    <Form.Item label="Min Select">
                        <Input
                            disabled={disableWrite}
                            value={block.tappable?.min_select}
                            type="number"
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);
                                if (tmpBlock.tappable === undefined)
                                    tmpBlock.tappable = _.cloneDeep(tappable);
                                tmpBlock.tappable.min_select = e.target.value;
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Row Wrap">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={block.row_wrap}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock.row_wrap = value;
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    {/*Max Select*/}
                    <Form.Item label="Max Select">
                        {" "}
                        <Input
                            disabled={disableWrite}
                            value={block.tappable?.max_select}
                            type="number"
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);
                                if (tmpBlock.tappable === undefined)
                                    tmpBlock.tappable = _.cloneDeep(tappable);
                                tmpBlock.tappable.max_select = e.target.value;
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>

                    {/* Max Table width */}
                    <Form.Item label="Max Table width">
                        <Input
                            disabled={disableWrite}
                            value={block.max_table_width}
                            type="number"
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock.max_table_width = e.target.value;
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>

                    {/*tappable Function field, store in block.tappable.function */}
                    <Form.Item label="Function">
                        <Input.TextArea
                            rows={6}
                            disabled={disableWrite}
                            value={block.tappable?.function}
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);
                                if (tmpBlock.tappable === undefined)
                                    tmpBlock.tappable = _.cloneDeep(tappable);
                                tmpBlock.tappable.function = e.target.value;
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </>
            )}
        </>
    );
};

export default Tappable;
