import { Button, Card, Form, Input, Select, Tag, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { renderer } from "../../../../helpers";
import { cloneDeep } from "lodash";
import { getBackgroundColor } from "../../../AllInOne/renderEditor/ComponentsEditor/GenericTable/preview/helpers";
import DroppedItem from "../../../AllInOne/renderEditor/ComponentsEditor/GenericTable/preview/DroppedItem";
import { EditOutlined } from "@ant-design/icons";
import DropItemModal from "../DropItemModal";

const TextComponent = ({ text, align, id }: any) => {
    return (
        <Card.Grid
            style={{
                width: "200px",
                padding: 4,
                textAlign: align ? align.toLowerCase() : "left",
                background: "transparent",
            }}
            hoverable={false}
        >
            {
                renderer(text.value?.default, {
                    fontSize: text.size?.default,
                    color: text.color?.default,
                })
                // :(<p
                //     style={{
                //         margin: 0,
                //         fontSize: `${text.size?.default}px` ?? 16,
                //         color: text.color?.default ?? "#000",
                //     }}
                // >
                //     {text.value?.default}
                // </p>)
            }
        </Card.Grid>
    );
};

const InputComponent = ({ cell }: any) => {
    return (
        <>
            {cell.input?.enabled && (
                <Card.Grid
                    style={{
                        width: "50%",
                        padding: 4,
                        background: "transparent",
                    }}
                    hoverable={false}
                >
                    INPUT : {cell.input?.type}
                </Card.Grid>
            )}
            {cell.dropdown?.enabled && (
                <Card.Grid
                    style={{
                        width: "50%",
                        padding: 4,
                        background: "transparent",
                    }}
                    hoverable={false}
                >
                    DROPDOWN : {cell.dropdown?.type}
                </Card.Grid>
            )}
            {cell.tappable?.enabled && (
                <Card.Grid
                    style={{
                        width: "50%",
                        padding: 4,
                        background: "transparent",
                    }}
                    hoverable={false}
                >
                    TAPPABLE
                </Card.Grid>
            )}
        </>
    );
};

const CorrectInputValues = ({
    correctInputValues = [],
    onSubmit,
    disableWrite,
}: any) => {
    const [tagInput, setTagInput] = useState("");
    return (
        <Form.Item
            label="Correct Values"
            style={{ marginTop: 10, maxWidth: "200px" }}
        >
            {correctInputValues?.map((tag: any, i: number) => {
                return (
                    <Tag
                        closable
                        onClose={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onSubmit(
                                correctInputValues?.filter(
                                    (t: any, ti: number) => ti !== i,
                                ),
                            );
                        }}
                    >
                        {tag}
                    </Tag>
                );
            })}
            <Input
                disabled={disableWrite}
                type="text"
                style={{ width: 78, border: "1px solid black" }}
                value={tagInput}
                onChange={(e) => setTagInput(e?.target?.value)}
                onPressEnter={() => {
                    onSubmit([...correctInputValues, tagInput]);
                    setTagInput("");
                }}
            />
        </Form.Item>
    );
};

const CellImagePreview = ({ image }: any) => {
    return (
        <Card.Grid
            style={{
                width: "100%",
                padding: 4,
                background: "transparent",
            }}
            hoverable={false}
        >
            <img src={image?.src?.default} alt="" style={{ width: "50px" }} />
        </Card.Grid>
    );
};

const CellInnerContentPreview = ({
    cell,
    setCurrentCell,
    disableWrite,
    tabIndex,
    ...props
}: any) => {
    const [isDropModalOpen, setDropModalOpen] = useState(false);
    const fillColorStyle = useMemo(() => {
        if (cell?.fillColor?.enabled) {
            return {
                backgroundColor:
                    cell.fillColor.value ?? cell.fillColor.default ?? "#56ccf2",
                minHeight: "56px",
            };
        }
    }, [cell?.fillColor]);

    const backgroundImageStyles = useMemo(() => {
        if (cell?.imageBackground?.enabled) {
            return {
                backgroundImage: `url(${
                    cell.imageBackground.src?.value ??
                    cell.imageBackground.src?.default
                })`,
                backgroundSize: "auto 100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                minHeight: "56px",
            };
        }
        return {};
    }, [cell?.imageBackground]);

    const modifiedTagsList: any[] = useMemo(
        () => props.tagsList,
        [props.tagsList],
    );

    return (
        <Card
            bodyStyle={{
                padding: 10,
                ...fillColorStyle,
                ...backgroundImageStyles,
                maxWidth: "300px",
            }}
        >
            <div>
                {cell.text?.enabled && (
                    <>
                        {cell.text.type === "latex" ? (
                            <TextComponent
                                text={cell.text}
                                align={cell.align}
                                id={cell.id}
                            />
                        ) : (
                            <div
                                style={{
                                    maxWidth: "200px",
                                }}
                            >
                                <Input
                                    tabIndex={tabIndex}
                                    disabled={disableWrite}
                                    type="text"
                                    style={{
                                        width: "100%",
                                        fontSize: `${
                                            cell?.text?.size?.default || 16
                                        }px`,
                                        color:
                                            cell?.text?.color?.default ??
                                            "#000",
                                        ...fillColorStyle,
                                    }}
                                    value={cell.text.value?.default}
                                    onChange={(e) => {
                                        let tmpCell = cloneDeep(cell);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["text", "value", "default"],
                                            e.target.value,
                                        );
                                        setCurrentCell(tmpCell);
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
                <InputComponent cell={cell} />
                {cell.dragDrop?.enabled && (
                    <Card.Grid
                        style={{
                            minWidth: "100px",
                            padding: 4,
                            background: "transparent",
                        }}
                        hoverable={false}
                    >
                        DRAG & DROP{" "}
                        <div
                            className="renderer-table-drag-drop-area-wrapper"
                            style={{
                                backgroundColor: cell.dragDrop
                                    ?.takeCellBackground
                                    ? fillColorStyle?.backgroundColor
                                    : getBackgroundColor(
                                          cell.dragDrop?.fillIndex || 0,
                                      ),
                                position: "relative",
                                border:
                                    cell.dragDrop?.droppedSource &&
                                    !modifiedTagsList?.some(
                                        (tag) =>
                                            tag.genericId ===
                                            cell.dragDrop?.droppedSource
                                                ?.genericId,
                                    )
                                        ? "5px solid red"
                                        : "",
                            }}
                        >
                            {isDropModalOpen && (
                                <DropItemModal
                                    {...props}
                                    isModalOpen={isDropModalOpen}
                                    setModalOpen={setDropModalOpen}
                                    droppedSource={cell.dragDrop?.droppedSource}
                                    onSubmit={(v, data) => {
                                        let tmpCell = cloneDeep(cell);
                                        tmpCell = _.set(
                                            tmpCell,
                                            ["dragDrop", "droppedSource"],
                                            v,
                                        );
                                        setCurrentCell(tmpCell, data);
                                    }}
                                />
                            )}
                            {!disableWrite && (
                                <Button
                                    icon={<EditOutlined />}
                                    type="default"
                                    onClick={() => setDropModalOpen(true)}
                                    style={{
                                        background: "#F9F8F8",
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        zIndex: 3,
                                    }}
                                />
                            )}
                            {cell.dragDrop?.droppedSource && (
                                <DroppedItem
                                    droppedSource={cell.dragDrop?.droppedSource}
                                />
                            )}
                        </div>
                    </Card.Grid>
                )}
                {cell.image?.enabled && <CellImagePreview image={cell.image} />}
            </div>
            {(cell.dragDrop?.enabled ||
                cell.input?.enabled ||
                cell.dropdown?.enabled ||
                cell.tappable?.enabled ||
                cell.tappable?.enabled) && (
                <CorrectInputValues
                    correctInputValues={cell.correctInputValues}
                    onSubmit={(v) => {
                        let tmpCell = cloneDeep(cell);
                        tmpCell = _.set(tmpCell, ["correctInputValues"], v);
                        setCurrentCell(tmpCell);
                    }}
                    disableWrite={disableWrite}
                />
            )}
        </Card>
    );
};

export default CellInnerContentPreview;
