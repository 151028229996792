import React from "react";
import { Form, Input, Select, Divider } from "antd";
import _ from "lodash";

import PathForm from "./PathForm";

const FeedbackAndEvaluation = ({ block, setBlock, disableWrite }: any) => {
    return (
        <>
            <h4>Enable Partial Submission</h4>
            <Form.Item>
                <Select
                    disabled={disableWrite}
                    value={block.evaluation?.partialSubmission}
                    onChange={(val) => {
                        let tmpBlock = _.cloneDeep(block);
                        if (tmpBlock.evaluation === undefined)
                            tmpBlock.evaluation = {};
                        tmpBlock.evaluation.partialSubmission = val;
                        setBlock(tmpBlock);
                    }}
                    style={{
                        width: "200px",
                    }}
                >
                    <Select.Option value={true}>Enable</Select.Option>
                    <Select.Option value={false}>Disable</Select.Option>
                </Select>
            </Form.Item>

            <h4>Enable Filled Evaluation</h4>
            <Form.Item>
                <Select
                    disabled={disableWrite}
                    value={block.evaluation?.filledEvaluation}
                    onChange={(val) => {
                        let tmpBlock = _.cloneDeep(block);
                        if (tmpBlock.evaluation === undefined)
                            tmpBlock.evaluation = {};
                        tmpBlock.evaluation.filledEvaluation = val;
                        setBlock(tmpBlock);
                    }}
                    style={{
                        width: "200px",
                    }}
                >
                    <Select.Option value={true}>Enable</Select.Option>
                    <Select.Option value={false}>Disable</Select.Option>
                </Select>
            </Form.Item>
            <h3>Evaluation Handling</h3>
            {/*Evaluation Function field, store in block.evaluation.function */}
            <Form.Item label="Evaluation Function">
                <Input.TextArea
                    disabled={disableWrite}
                    rows={6}
                    value={block.evaluation?.function}
                    onChange={(e) => {
                        let tmpBlock = _.cloneDeep(block);
                        if (tmpBlock.evaluation === undefined)
                            tmpBlock.evaluation = {};
                        tmpBlock.evaluation.function = e.target.value;
                        setBlock(tmpBlock);
                    }}
                />
            </Form.Item>
            <Divider />
            <h3>Feedback Handling</h3>
            {/*Select field for feedback type, options - cell, row, column, table*/}
            <Form.Item label="Feedback Type">
                <Select
                    disabled={disableWrite}
                    value={block.feedback?.type}
                    onChange={(val) => {
                        let tmpBlock = _.cloneDeep(block);
                        if (tmpBlock.feedback === undefined)
                            tmpBlock.feedback = {};
                        tmpBlock.feedback.type = val;
                        setBlock(tmpBlock);
                    }}
                    style={{
                        width: "200px",
                    }}
                >
                    <Select.Option value="CELL">Cell</Select.Option>
                    <Select.Option value="ROW">Row</Select.Option>
                    <Select.Option value="COLUMN">Column</Select.Option>
                    <Select.Option value="TABLE">Table</Select.Option>
                    <Select.Option value="PATH">Path</Select.Option>
                </Select>
            </Form.Item>
            {block.feedback?.type === "TABLE" && (
                <>
                    {/*Select field for highlight type border or fill */}
                    <Form.Item label="Highlight Type">
                        <Select
                            disabled={disableWrite}
                            value={block.feedback?.highlightType}
                            onChange={(val) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock.feedback.highlightType = val;
                                setBlock(tmpBlock);
                            }}
                            style={{
                                width: "200px",
                            }}
                        >
                            <Select.Option value="BORDER">Border</Select.Option>
                            <Select.Option value="FILL">Fill</Select.Option>
                        </Select>
                    </Form.Item>
                </>
            )}
            {/*Another select field for feedback.subType all-at-once, one-by-one, one-by-one-untill-incorrect, show field for every other type except table*/}
            {block.feedback?.type && block.feedback.type !== "TABLE" && (
                <>
                    <Form.Item label="Feedback Sub Type">
                        <Select
                            disabled={disableWrite}
                            value={block.feedback?.subType}
                            onChange={(val) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock.feedback.subType = val;
                                setBlock(tmpBlock);
                            }}
                            style={{
                                width: "200px",
                            }}
                        >
                            <Select.Option value="ALL_IN_ONE_GO">
                                All in one go{" "}
                            </Select.Option>
                            <Select.Option value="ONE_BY_ONE">
                                One By One
                            </Select.Option>
                            <Select.Option value="ONE_BY_ONE_TILL_FIRST_INCORRECT">
                                One by one till first incorrect{" "}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    {/*Select field for if the feedback is along the row or column, only show it for cell type feedback and both one by one sub cases*/}
                    {block.feedback.type === "CELL" &&
                    (block.feedback?.subType === "ONE_BY_ONE" ||
                        block.feedback?.subType ===
                            "ONE_BY_ONE_TILL_FIRST_INCORRECT") ? (
                        <Form.Item label="Feedback Direction">
                            <Select
                                disabled={disableWrite}
                                value={block.feedback?.direction}
                                onChange={(val) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock.feedback.direction = val;
                                    setBlock(tmpBlock);
                                }}
                                style={{
                                    width: "200px",
                                }}
                            >
                                <Select.Option value="ROW">Row</Select.Option>
                                <Select.Option value="COLUMN">
                                    Column
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    ) : null}
                </>
            )}
            {/*Input text area for feedback function*/}
            <Form.Item label="Feedback Function">
                <Input.TextArea
                    rows={6}
                    disabled={disableWrite}
                    value={block.feedback?.function}
                    onChange={(e) => {
                        let tmpBlock = _.cloneDeep(block);
                        if (tmpBlock.feedback === undefined)
                            tmpBlock.feedback = {};
                        tmpBlock.feedback.function = e.target.value;
                        setBlock(tmpBlock);
                    }}
                />
            </Form.Item>

            {/*Path Form for feedback paths*/}
            {block?.feedback?.type === "PATH" && (
                <PathForm
                    block={block}
                    setBlock={setBlock}
                    disableWrite={disableWrite}
                />
            )}
        </>
    );
};

export default FeedbackAndEvaluation;
