import React from "react";
import _ from "lodash";
import { Row, Col, Form, Input, Select, Switch, Card } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { BlockPicker } from "react-color";

const BorderCustomizationComponent = ({
    blockData,
    setBlock,
    isChildBorder,
    disableWrite,
}: any) => {
    return (
        <Row gutter={[5, 5]}>
            <Col span={12}>
                <Form.Item label="Stroke Color">
                    {/*Color picker field from antd */}
                    <BlockPicker
                        colors={[]}
                        color={blockData?.color ?? "gray"}
                        onChangeComplete={(color: any) => {
                            if (disableWrite) return;
                            let tmpBlock = _.cloneDeep(blockData);
                            if (tmpBlock === undefined)
                                tmpBlock = {
                                    color: "#000000",
                                };
                            tmpBlock.color = color.hex;
                            setBlock(tmpBlock);
                        }}
                        triangle="hide"
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Stroke Opacity">
                    {/*Number input */}
                    <Input
                        disabled={disableWrite}
                        type="number"
                        value={blockData?.opacity}
                        onChange={(e) => {
                            let tmpBlock = _.cloneDeep(blockData);
                            if (tmpBlock === undefined)
                                tmpBlock = { opacity: 1 };
                            tmpBlock.opacity = Number(e.target.value);
                            setBlock(tmpBlock);
                        }}
                        width={200}
                        max={1}
                        min={0}
                        step={0.01}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Stroke Width">
                    {/*Number field */}
                    <Input
                        disabled={disableWrite}
                        type="number"
                        value={blockData?.width}
                        onChange={(e) => {
                            let tmpBlock = _.cloneDeep(blockData);
                            if (tmpBlock === undefined) tmpBlock = { width: 1 };
                            tmpBlock.width = Number(e.target.value);
                            setBlock(tmpBlock);
                        }}
                        width={200}
                    />
                </Form.Item>
            </Col>
            {!isChildBorder && (
                <Col span={12}>
                    <Form.Item label="Border radius">
                        {/*Number field */}
                        <Input
                            disabled={disableWrite}
                            type="number"
                            value={blockData?.radius}
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(blockData);
                                if (tmpBlock === undefined)
                                    tmpBlock = { radius: 0 };
                                tmpBlock.radius = Number(e.target.value);
                                setBlock(tmpBlock);
                            }}
                            width={200}
                            min={0}
                            max={100}
                        />
                    </Form.Item>
                </Col>
            )}
            <Col span={12}>
                {/*Select field for stroke style */}
                <Form.Item label="Stroke Style">
                    <Select
                        disabled={disableWrite}
                        value={blockData?.style}
                        onChange={(val) => {
                            let tmpBlock = _.cloneDeep(blockData);
                            if (tmpBlock === undefined)
                                tmpBlock = {
                                    style: "SOLID",
                                };
                            tmpBlock.style = val;
                            setBlock(tmpBlock);
                        }}
                        style={{
                            width: "100px",
                        }}
                    >
                        <Select.Option value="SOLID">Solid</Select.Option>
                        <Select.Option value="DASHED">Dashed</Select.Option>
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    );
};

const TableBorderEditor = (props: any) => {
    const { blockData, setBlock, disableWrite, puzzleEditor = false } = props;
    return (
        <Form layout="vertical">
            <Card
                title={<h3 style={{ marginBottom: 0 }}>Border Settings:</h3>}
                extra={
                    // Switch Component for enabling disabling visibility
                    <Switch
                        disabled={disableWrite}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={blockData?.border?.enabled}
                        onChange={(value) => {
                            let tmpCell = _.cloneDeep(blockData);
                            if (!blockData.border) {
                                tmpCell.border = {
                                    enabled: false,
                                };
                            }
                            tmpCell = _.set(
                                tmpCell,
                                ["border", "enabled"],
                                value,
                            );
                            setBlock(tmpCell);
                        }}
                    />
                }
            >
                {blockData.border?.enabled && (
                    <>
                        <BorderCustomizationComponent
                            disableWrite={disableWrite}
                            blockData={blockData.border ?? {}}
                            setBlock={(val: any) => {
                                let tmpBlock = _.cloneDeep(blockData);
                                tmpBlock.border = val;
                                setBlock(tmpBlock);
                            }}
                            isChildBorder={false}
                        />
                        {puzzleEditor && (
                            <Form.Item label="Boundary Border">
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={blockData?.border?.boundary}
                                    onChange={(value) => {
                                        let tmpBlock = _.cloneDeep(blockData);
                                        if (!tmpBlock.border) {
                                            tmpBlock.border = {
                                                boundary: false,
                                            };
                                        }
                                        tmpBlock.border.boundary = value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        )}
                        {!puzzleEditor && (
                            <>
                                <Form.Item label="Selective Borders">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={blockData?.border?.selective}
                                        onChange={(value) => {
                                            let tmpBlock =
                                                _.cloneDeep(blockData);
                                            if (
                                                !tmpBlock.border ||
                                                !tmpBlock?.border?.top ||
                                                !tmpBlock?.border?.bottom ||
                                                !tmpBlock?.border?.left ||
                                                !tmpBlock?.border?.right
                                            ) {
                                                tmpBlock.border = {
                                                    selective: false,
                                                    top: {
                                                        enabled: false,
                                                        color: "#000000",
                                                        opacity: 1,
                                                        width: 1,
                                                    },
                                                    bottom: {
                                                        enabled: false,
                                                        color: "#000000",
                                                        opacity: 1,
                                                        width: 1,
                                                    },
                                                    left: {
                                                        enabled: false,
                                                        color: "#000000",
                                                        opacity: 1,
                                                        width: 1,
                                                    },
                                                    right: {
                                                        enabled: false,
                                                        color: "#000000",
                                                        opacity: 1,
                                                        width: 1,
                                                    },
                                                };
                                            }
                                            tmpBlock.border.selective = value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                                {blockData.border?.selective && (
                                    <>
                                        <Card
                                            type="inner"
                                            title="Top Border"
                                            bodyStyle={{ padding: 5 }}
                                            extra={
                                                <Switch
                                                    disabled={disableWrite}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={
                                                        blockData?.border?.top
                                                            ?.enabled
                                                    }
                                                    onChange={(value) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                blockData,
                                                            );
                                                        if (
                                                            tmpBlock.border
                                                                ?.top ===
                                                            undefined
                                                        ) {
                                                            tmpBlock.border.top =
                                                                {
                                                                    enabled:
                                                                        false,
                                                                };
                                                        }
                                                        tmpBlock.border.top.enabled =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            }
                                        >
                                            {blockData?.border?.top
                                                ?.enabled && (
                                                <BorderCustomizationComponent
                                                    blockData={
                                                        blockData.border?.top ??
                                                        {}
                                                    }
                                                    disableWrite={disableWrite}
                                                    setBlock={(val: any) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                blockData,
                                                            );
                                                        tmpBlock.border.top =
                                                            val;
                                                        setBlock(tmpBlock);
                                                    }}
                                                    isChildBorder={true}
                                                />
                                            )}
                                        </Card>
                                        <Card
                                            type="inner"
                                            title="Bottom Border"
                                            bodyStyle={{ padding: 5 }}
                                            extra={
                                                <Switch
                                                    disabled={disableWrite}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={
                                                        blockData?.border
                                                            ?.bottom?.enabled
                                                    }
                                                    onChange={(value) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                blockData,
                                                            );
                                                        if (
                                                            tmpBlock.border
                                                                ?.bottom ===
                                                            undefined
                                                        ) {
                                                            tmpBlock.border.bottom =
                                                                {
                                                                    enabled:
                                                                        false,
                                                                };
                                                        }
                                                        tmpBlock.border.bottom.enabled =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            }
                                        >
                                            {blockData?.border?.bottom
                                                ?.enabled && (
                                                <BorderCustomizationComponent
                                                    blockData={
                                                        blockData.border
                                                            ?.bottom ?? {}
                                                    }
                                                    disableWrite={disableWrite}
                                                    setBlock={(val: any) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                blockData,
                                                            );
                                                        tmpBlock.border.bottom =
                                                            val;
                                                        setBlock(tmpBlock);
                                                    }}
                                                    isChildBorder={true}
                                                />
                                            )}
                                        </Card>
                                        <Card
                                            type="inner"
                                            title="Left Border"
                                            bodyStyle={{ padding: 5 }}
                                            extra={
                                                <Switch
                                                    disabled={disableWrite}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={
                                                        blockData?.border?.left
                                                            ?.enabled
                                                    }
                                                    onChange={(value) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                blockData,
                                                            );
                                                        if (
                                                            tmpBlock.border
                                                                ?.left ===
                                                            undefined
                                                        ) {
                                                            tmpBlock.border.left =
                                                                {
                                                                    enabled:
                                                                        false,
                                                                };
                                                        }
                                                        tmpBlock.border.left.enabled =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            }
                                        >
                                            {blockData?.border?.left
                                                ?.enabled && (
                                                <BorderCustomizationComponent
                                                    blockData={
                                                        blockData.border
                                                            ?.left ?? {}
                                                    }
                                                    disableWrite={disableWrite}
                                                    setBlock={(val: any) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                blockData,
                                                            );
                                                        tmpBlock.border.left =
                                                            val;
                                                        setBlock(tmpBlock);
                                                    }}
                                                    isChildBorder={true}
                                                />
                                            )}
                                        </Card>
                                        <Card
                                            type="inner"
                                            title="Right Border"
                                            bodyStyle={{ padding: 5 }}
                                            extra={
                                                <Switch
                                                    disabled={disableWrite}
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={
                                                        blockData?.border?.right
                                                            ?.enabled
                                                    }
                                                    onChange={(value) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                blockData,
                                                            );
                                                        if (
                                                            tmpBlock.border
                                                                ?.right ===
                                                            undefined
                                                        ) {
                                                            tmpBlock.border.right =
                                                                {
                                                                    enabled:
                                                                        false,
                                                                };
                                                        }
                                                        tmpBlock.border.right.enabled =
                                                            value;
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                            }
                                        >
                                            {blockData?.border?.right
                                                ?.enabled && (
                                                <BorderCustomizationComponent
                                                    blockData={
                                                        blockData.border
                                                            ?.right ?? {}
                                                    }
                                                    disableWrite={disableWrite}
                                                    setBlock={(val: any) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(
                                                                blockData,
                                                            );
                                                        tmpBlock.border.right =
                                                            val;
                                                        setBlock(tmpBlock);
                                                    }}
                                                    isChildBorder={true}
                                                />
                                            )}
                                        </Card>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Card>
        </Form>
    );
};

export default TableBorderEditor;
