import { useAuth0 } from "@auth0/auth0-react";

import {
    CopyIcon,
    Field,
    RenderCheckBox,
    RenderDynamicField,
    RenderField,
    RenderSelect,
    RenderUpload,
} from "src/components";
import FormikPersist from "src/components/FormikPersist";
import { TagsAssignComponent } from "src/modules/book/components";
import { Can } from "src/services/casl";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    message,
    Row,
    Select,
    Space,
    TimePicker,
} from "antd";
import { FieldArray, withFormik } from "formik";
import { useRouter } from "src/helpers";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import FigmaStoryEditor from "src/modules/worksheet/components/blocks/renderers/Figma/renderEditor/FigmaStoryEditor";
import moment from "moment";
import { DEFAULT_DEADLINE_STORY } from "./defaults";

const Option = Select.Option;

const subjects = [
    "Maths",
    "Science",
    "Physics",
    "Biology",
    "Chemistry",
    "Environmental Studies",
    "English",
    "Social Studies",
    "Social Science",
    "Hindi",
];

const classNames = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
];

const buttonsList = [
    {
        type: "next_question",
        text: "Next question",
        label: "Next question",
    },
    {
        type: "show_story",
        text: "Show Hint",
        label: "Show Story",
    },
    {
        type: "show_submit",
        text: "show_submit",
        label: "Show Submit",
    },
    {
        type: "show_try_again",
        text: "Show Try Again",
        label: "Show Try Again",
    },
];

const textType = [
    {
        type: "normal",
        label: "Normal",
    },
    {
        type: "function",
        label: "Function",
    },
];

export const creditTags = [
    {
        id: "CHALLENGE",
        value: "CHALLENGE",
        label: "Challenge (imc)",
    },
    {
        id: "PAID_PLAN_1",
        value: "PAID_PLAN_1",
        label: "Paid Plan 1(Require credits)",
    },
    {
        id: "TEST_PAID_PLAN_1",
        value: "TEST_PAID_PLAN_1",
        label: "Test Paid Plan 1(Only for testing)",
    },
];

const WorksheetForm = (props: any) => {
    const {
        onFinishFailed,
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetValues,
        toggleResetValues,
        resetForm,
        disableStatus = false,
        worksheet,
        editorProps,
    } = props;
    // console.log("🚀 ~ file: index.tsx ~ line 8 ~ values", values);
    const [loading, setLoading] = useState(false);
    const [isNewBlock, setIsNewBlock] = useState(false);

    const setStories = (stories: any[]) => {
        setFieldValue(["other", "stories"], stories);
    };

    const allProps = {
        ...props,
        ...editorProps.blocksColumnProps,
        ...editorProps.dataColumnProps,
        stories: values.other.stories || [],
        setStories,
    };

    if (
        !allProps.stories.find(
            (s) => s.backend?.slug === "default_deadline_story",
        )
    ) {
        allProps.stories.push(DEFAULT_DEADLINE_STORY);
    }

    const figmaStoriesProps = {
        setStories: allProps.setStories,
        stories: allProps.stories,
        isEditor: allProps.isEditor,
        setIsEditor: allProps.setIsEditor,
        isNewBlock,
        setIsNewBlock,
        worksheet: allProps.worksheet,
        disableWrite: allProps.disableWrite,
        isReadOnlyMode: allProps.isReadOnlyMode,
        mentionsList: [],
        hasMentions: false,
        storiesPath: ["other", "stories"],
        setNewJob: allProps.setNewJob,
        updateStoriesJob: allProps.updateStoriesJob,
        previewData: {},
    };

    useEffect(() => {
        if (resetValues) {
            resetForm();
            toggleResetValues(false);
        }
    }, [resetValues]);

    return (
        <Form onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
            {typeof window !== "undefined" && !isSubmitting && (
                <FormikPersist name={window?.location?.pathname} />
            )}

            <Row gutter={24}>
                {[
                    "personalized_learning_v3",
                    "personalized_learning_v4",
                ].includes(values.type) ? (
                    <>
                        <Col lg={12} md={24}>
                            <Field
                                label="Worksheet Title"
                                placeholder="Worksheet Title"
                                name={"title"}
                                icon="FontSizeOutlined"
                                component={RenderField}
                                type="text"
                                value={values.title}
                                rules={[{ required: true }]}
                                path="worksheet/"
                            />
                        </Col>
                        <Col lg={12} md={24}>
                            <Form.Item label="Activity type">
                                <Select
                                    value={
                                        values.other?.activity_type ||
                                        "CHALLENGE"
                                    }
                                    onChange={(value) => {
                                        setFieldValue(
                                            ["other", "activity_type"],
                                            value,
                                        );
                                    }}
                                >
                                    <Select.Option value="CHALLENGE">
                                        Challenge (Yellow star)
                                    </Select.Option>
                                    <Select.Option value="LEARNING">
                                        Learning (Blue star)
                                    </Select.Option>
                                    <Select.Option value="EXAM">
                                        Exam
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                            <Field
                                label="Slug"
                                placeholder="Add a slug"
                                name={"slug"}
                                icon="FontSizeOutlined"
                                component={RenderField}
                                type="text"
                                value={values.slug}
                                path="worksheet/"
                            />
                        </Col>
                    </>
                ) : (
                    <Col lg={24} md={24}>
                        <Field
                            label="Worksheet Title"
                            placeholder="Worksheet Title"
                            name={"title"}
                            icon="FontSizeOutlined"
                            component={RenderField}
                            type="text"
                            value={values.title}
                            rules={[{ required: true }]}
                            path="worksheet/"
                        />
                    </Col>
                )}

                <Col lg={24} md={24}>
                    <Field
                        label="Worksheet Description"
                        placeholder="Worksheet Description"
                        name={"description"}
                        icon="FontSizeOutlined"
                        component={RenderField}
                        type="textarea"
                        value={values.description || ""}
                        rules={[{ required: true }]}
                        path="worksheet/"
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Worksheet Banner"
                        placeholder="Worksheet Banner"
                        name={"banner"}
                        icon="FontSizeOutlined"
                        component={RenderUpload}
                        type="textarea"
                        value={values.banner}
                        rules={[{ required: true }]}
                        path="worksheet/"
                        setLoad={setLoading}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Unlock all Levels"
                        placeholder="Unlock all Levels"
                        name={"other.unlock_levels"}
                        icon="FontSizeOutlined"
                        component={RenderCheckBox}
                        checked={values.other.unlock_levels}
                        rules={[{ required: true }]}
                    />
                    <Field
                        label="Worksheet is locked?"
                        placeholder="Worksheet is locked?"
                        name={"other.is_locked"}
                        icon="FontSizeOutlined"
                        component={RenderCheckBox}
                        checked={values.other.is_locked}
                        rules={[{ required: true }]}
                    />
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Book Subject"
                        placeholder="Book Subject"
                        name={"subject"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.subject}
                        rules={[{ required: true }]}
                        disabled={true}
                    >
                        {subjects.map((subject) => (
                            <Option value={subject}>{subject}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={12} md={24}>
                    <Field
                        label="Book Class"
                        placeholder="Book Class"
                        name={"class"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.class}
                        rules={[{ required: true }]}
                        disabled={true}
                    >
                        {classNames.map((className) => (
                            <Option value={className}>{className}</Option>
                        ))}
                    </Field>
                </Col>

                <Col lg={24} md={24}>
                    <TagsAssignComponent
                        name="tags"
                        formik={props}
                        isSet={true}
                        handleChange={(data) => {
                            setFieldValue(
                                ["tags"],
                                data.map((d) => ({
                                    tag_id: d.value,
                                    label: d.label,
                                })),
                            );
                        }}
                        selected={
                            values?.tags?.map((tag, index) => ({
                                value: tag.tag_id,
                                key: index,
                                label: tag.label,
                            })) || []
                        }
                        tagGroup={"worksheet"}
                        otherTags={[]}
                    />
                    <br />
                </Col>

                <Col lg={24} md={24}>
                    <FieldArray
                        name={"other.ratings"}
                        render={(arrayHelpers) => (
                            <RenderDynamicField
                                buttonText="Add Ratings"
                                keys={[
                                    {
                                        key: "user_name",
                                        type: "text",
                                        label: "User Name",
                                    },
                                    {
                                        key: "user_description",
                                        type: "text",
                                        label: "User Description",
                                    },
                                    {
                                        key: "rating",
                                        type: "number",
                                        label: "Rating",
                                    },
                                    {
                                        key: "comment",
                                        type: "textarea",
                                        label: "Comment",
                                    },
                                    {
                                        key: "avatar",
                                        type: "text",
                                        label: "Avatar",
                                    },
                                ]}
                                arrayHelpers={arrayHelpers}
                                values={values.other.ratings}
                                name={"other.ratings"}
                            />
                        )}
                    />
                </Col>

                <Col lg={24} md={24}>
                    <Field
                        label="Worksheet Type"
                        placeholder="Worksheet Type"
                        name={"type"}
                        icon="FontSizeOutlined"
                        component={RenderSelect}
                        type="text"
                        value={values.type}
                        rules={[{ required: true }]}
                        disabled={!!worksheet?.id}
                    >
                        <Option value={"normal"}>{"Normal Worksheet"}</Option>
                        <Option value={"timed"}>{"Timed Worksheet"}</Option>
                        <Option value={"personalized_learning"}>
                            {"Personalized Learning Worksheet"}
                        </Option>
                        <Option value={"personalized_learning_v2"}>
                            {"Personalized Learning Worksheet V2"}
                        </Option>
                        <Option value={"personalized_learning_v3"}>
                            {"Personalized Learning Worksheet V3"}
                        </Option>
                        <Option value={"personalized_learning_v4"}>
                            {"Personalized Learning Worksheet V4"}
                        </Option>
                        <Option value={"quiz_form"}>{"Quiz Form"}</Option>
                    </Field>
                </Col>

                {[
                    "personalized_learning_v2",
                    "personalized_learning_v3",
                    "personalized_learning_v4",
                ].includes(values.type) && (
                    <>
                        <Col lg={8} md={24}>
                            <Field
                                label="Homepage Banner"
                                placeholder="Homepage Banner"
                                name={"other.homepage_banner"}
                                icon="FontSizeOutlined"
                                component={RenderUpload}
                                value={values.other.homepage_banner}
                                rules={[{ required: true }]}
                                path="worksheet/"
                                setLoad={setLoading}
                            />
                        </Col>

                        <Col lg={8} md={24}>
                            <Field
                                label="Worksheet Duration Text"
                                placeholder="Worksheet Duration Text"
                                name={"other.duration_text"}
                                icon="FontSizeOutlined"
                                component={RenderField}
                                type="text"
                                value={values.other?.duration_text || ""}
                                rules={[{ required: true }]}
                                path="worksheet/"
                            />
                        </Col>

                        <Col lg={8} md={24}>
                            <Field
                                label="Pass Threshold"
                                placeholder="Pass Threshold"
                                name={"other.pass_threshold"}
                                icon="FontSizeOutlined"
                                component={RenderField}
                                type="number"
                                max={100}
                                min={0}
                                value={values.other?.pass_threshold || "0"}
                                rules={[{ required: true }]}
                                path="worksheet/"
                            />
                        </Col>

                        {[
                            "personalized_learning_v3",
                            "personalized_learning_v4",
                        ].includes(values.type) && (
                            <>
                                <Col
                                    lg={24}
                                    md={24}
                                    style={{
                                        border: "1px solid black",
                                    }}
                                >
                                    <Space>
                                        <h3>Configure Question Buttons</h3>
                                        <Button
                                            shape="circle"
                                            type={"primary"}
                                            size="small"
                                            icon={<CopyIcon />}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                message.info(
                                                    `Copied Config to clipboard!`,
                                                );
                                                navigator.clipboard.writeText(
                                                    JSON.stringify(
                                                        values.other
                                                            .buttons_list,
                                                    ),
                                                );
                                            }}
                                        />
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={async () => {
                                                try {
                                                    const clipboardData =
                                                        await navigator.clipboard.readText();
                                                    if (!clipboardData) return;
                                                    let data =
                                                        JSON.parse(
                                                            clipboardData,
                                                        );
                                                    if (!Array.isArray(data))
                                                        return;
                                                    setFieldValue(
                                                        [
                                                            "other",
                                                            "buttons_list",
                                                        ],
                                                        data,
                                                    );
                                                } catch (e) {}
                                            }}
                                        >
                                            Click to paste config
                                        </Button>
                                    </Space>
                                    <FieldArray
                                        name={"other.buttons_list"}
                                        render={(arrayHelpers) => (
                                            <RenderDynamicField
                                                buttonText="Add Button"
                                                keys={[
                                                    {
                                                        key: "type",
                                                        type: "select",
                                                        label: "Type",
                                                        options:
                                                            buttonsList?.map(
                                                                (v) => ({
                                                                    value: v.type,
                                                                    label: v.label,
                                                                }),
                                                            ),
                                                    },
                                                    {
                                                        key: "textType",
                                                        type: "select",
                                                        label: "Text Type",
                                                        options: textType?.map(
                                                            (v) => ({
                                                                value: v.type,
                                                                label: v.label,
                                                            }),
                                                        ),
                                                    },
                                                    {
                                                        key: "text",
                                                        type: "text",
                                                        label: "Text",
                                                    },
                                                    {
                                                        key: "function",
                                                        type: "textarea",
                                                        label: "Function",
                                                    },
                                                ]}
                                                arrayHelpers={arrayHelpers}
                                                values={
                                                    values.other.buttons_list
                                                }
                                                name={"other.buttons_list"}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col
                                    lg={24}
                                    md={24}
                                    style={{ marginTop: "20px" }}
                                >
                                    <Field
                                        label="Next Worksheet Function"
                                        placeholder="Next Worksheet Function"
                                        name={"other.next_worksheet"}
                                        icon="FontSizeOutlined"
                                        component={RenderField}
                                        type="textarea"
                                        value={
                                            values.other?.next_worksheet || ""
                                        }
                                        rules={[{ required: true }]}
                                        path="worksheet/"
                                    />
                                </Col>
                            </>
                        )}
                    </>
                )}

                <Col lg={24} md={24}>
                    <Can I="publish" a={"worksheet"} passThrough>
                        {(allowed: boolean) => (
                            <Field
                                label="Worksheet Status"
                                placeholder="Worksheet Status"
                                name={"status"}
                                icon="FontSizeOutlined"
                                component={RenderSelect}
                                type="text"
                                value={values.status}
                                rules={[{ required: true }]}
                                disabled={
                                    !allowed ||
                                    disableStatus ||
                                    values.status !== "active"
                                }
                            >
                                <Option value="inactive">Inactive</Option>
                                <Option value="active">Active</Option>
                            </Field>
                        )}
                    </Can>
                </Col>

                <Col style={{ width: "100%", marginBottom: "10px" }}>
                    <Card title="Stories" style={{ border: "1px solid black" }}>
                        <FigmaStoryEditor {...figmaStoriesProps} />
                    </Card>
                </Col>

                <Col lg={24} md={24}>
                    <Card
                        title="Deadline"
                        style={{
                            border: "1px solid black",
                            marginBottom: "10px",
                        }}
                    >
                        <Field
                            label="Has Deadline?"
                            placeholder="Has Deadline?"
                            name={"other.deadline.has_deadline"}
                            icon="FontSizeOutlined"
                            component={RenderCheckBox}
                            checked={values.other.deadline?.has_deadline}
                        />
                        {values.other.deadline?.has_deadline && (
                            <>
                                <DatePicker
                                    value={
                                        values.other.deadline?.date_value
                                            ? moment(
                                                  values.other.deadline
                                                      ?.date_value,
                                              )
                                            : undefined
                                    }
                                    onChange={(value, dateString) => {
                                        setFieldValue(
                                            ["other", "deadline", "date_value"],
                                            dateString,
                                        );
                                    }}
                                    allowClear={false}
                                />
                                <TimePicker
                                    value={
                                        values.other?.deadline?.time_value
                                            ? moment(
                                                  values.other?.deadline
                                                      ?.time_value,
                                                  "HH:mm:ss",
                                              )
                                            : undefined
                                    }
                                    onChange={(value, timeString) => {
                                        setFieldValue(
                                            ["other", "deadline", "time_value"],
                                            timeString,
                                        );
                                    }}
                                    allowClear={false}
                                />
                                <Field
                                    label="Deadline story"
                                    placeholder="Deadline story"
                                    name={"other.deadline.story_name"}
                                    icon="FontSizeOutlined"
                                    component={RenderField}
                                    type="text"
                                    value={
                                        values.other?.deadline?.story_name ||
                                        "default_deadline_story"
                                    }
                                    path="worksheet/"
                                />
                            </>
                        )}
                    </Card>
                </Col>

                <Col lg={24} md={24}>
                    <Card
                        title="Can be played multiple time?"
                        style={{
                            border: "1px solid black",
                            marginBottom: "10px",
                        }}
                    >
                        <Field
                            label="Can be played multiple time?"
                            placeholder="Can be played multiple time?"
                            name={"other.max_limit.has_maxlimit"}
                            icon="FontSizeOutlined"
                            component={RenderCheckBox}
                            checked={values.other.max_limit?.has_maxlimit}
                        />
                        {values.other.max_limit?.has_maxlimit && (
                            <Field
                                label="Max limit story"
                                placeholder="Max limit story"
                                name={"other.max_limit.story_name"}
                                icon="FontSizeOutlined"
                                component={RenderField}
                                type="text"
                                value={
                                    values.other?.max_limit?.story_name || ""
                                }
                                path="worksheet/"
                            />
                        )}
                    </Card>
                </Col>

                <Col lg={12} md={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isSubmitting || loading}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export const FormSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    banner: yup.string().required("This field is required"),
    class: yup.string().required("This field is required"),
    subject: yup.string().required("This field is required"),

    status: yup.string().required("This field is required"),
});

const WorksheetFormWithFormik = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props: any) => {
        const { worksheet, user_id, subject, className } = props;
        return {
            ...(worksheet?.id ? { id: worksheet.id } : {}),
            title: worksheet?.title || "",
            description: worksheet?.description || "",
            banner: worksheet?.banner || "",
            class: worksheet?.class || className || "",
            subject: worksheet?.subject || subject || "",

            status: worksheet?.status || "inactive",
            user_id,
            tags: worksheet?.other?.tags || [],
            other: {
                ratings: worksheet?.other?.ratings || [],
                sub_title: worksheet?.other?.sub_title || "",
                pass_threshold: worksheet?.other?.pass_threshold || "0",
                homepage_banner: worksheet?.other?.homepage_banner || "",
                duration_text: worksheet?.other?.duration_text || "",
                is_locked: worksheet?.other
                    ? worksheet?.other?.is_locked
                    : true,
                unlock_levels: worksheet?.other
                    ? worksheet?.other?.unlock_levels
                    : false,
                buttons_list: worksheet?.other?.buttons_list || [],
                next_worksheet: worksheet?.other?.next_worksheet || "",
                stories: worksheet?.other?.stories || [],
                deadline: worksheet?.other?.deadline || {
                    has_deadline: false,
                    date: undefined,
                    time: undefined,
                    story_name: "",
                },
                max_limit: worksheet?.other?.max_limit || {
                    has_maxlimit: false,
                    story_name: "",
                },
                activity_type: worksheet?.other?.activity_type || "CHALLENGE",
            },
            ...([
                "personalized_learning_v3",
                "personalized_learning_v4",
            ].includes(worksheet?.type)
                ? {
                      slug:
                          worksheet?.slug ||
                          (worksheet?.id ? `${worksheet?.id}` : null),
                  }
                : {}),
            type: worksheet?.type || "personalized_learning_v4",
        };
    },
    validationSchema: FormSchema,

    async handleSubmit(values, { props: { onFinish } }: any) {
        console.log("vaues", values);
        await onFinish({
            ...values,
            other: {
                ...values.other,
                activity_type: values.other.activity_type || "CHALLENGE",
                tags: values.tags,
            },
        });
    },
    ...(typeof window !== "undefined" && {
        displayName: window?.location?.pathname, // helps with React DevTools
    }),
});

const FormikForm = WorksheetFormWithFormik(WorksheetForm);

const FormikFormWithUser = (props) => {
    const { user } = useAuth0();

    const {
        query: { subject, class: className },
    } = useRouter();

    const namespace = "https://hasura.io/jwt/claims/";
    const user_id = user && user[namespace + "user_id"];
    return (
        <FormikForm
            {...props}
            user_id={user_id}
            className={className}
            subject={subject}
        />
    );
};

export default FormikFormWithUser;
