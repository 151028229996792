import _, { cloneDeep, isString, set } from "lodash";
import { useState } from "react";
import {
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Switch,
    Typography,
    message,
} from "antd";
import { LogicModal } from "../../AllInOne/renderEditor/ComponentsEditor/common/LogicModal";
import { CheckOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { SlateEditor } from "src/components";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { BLOCK_TYPES } from "../../../common";
import KeyboardModal from "./KeyboardModal";
import { COMPONENT_TYPES } from "../../AllInOne/defaultValue";
import { v4 as uuid } from "uuid";
import {
    convertAllInOneBlockToV3,
    convertAllInOneBlockToV4,
    convertChunkToV3,
    convertChunkToV4,
    convertStoriesToV3,
    convertStoriesToV4,
} from "./helper";
import { useWorksheetState } from "src/modules/worksheet/components/contexts/WorksheetContext";
import { captureException } from "@sentry/react";

const { ALL_IN_ONE, V2_STORIES_BLOCK } = BLOCK_TYPES;

const { Title } = Typography;

const ChunkEditor = ({
    setBlock,
    disableWrite,
    // isReadOnlyMode,
    currentBlock,
    block,
    renderContextVariables,
    currentEditor,
    setCurrentEditor,
    mentionsList = [],
    hasMentions,
    worksheet,
    levelId,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            slug: dataSlug,
            chunk: {
                name,
                is_variant: isVar = false,
                compute_functions: computeFuncs = [],
                blocks: chunkBlocks,
                dev_remarks = [],
            },
        },
        children = [],
        backend,
    } = block;

    let blocks =
        worksheet?.type === "personalized_learning_v4" ? children : chunkBlocks;
    let slug =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.slug
            : dataSlug;
    let compute_functions =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.compute_functions || []
            : computeFuncs;
    let is_variant =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.is_variant
            : isVar;

    const {
        getBlockfromDb,
        chunkIndex,
        publishedBlocksMap = {},
    }: any = useWorksheetState();

    const [tempChildren, setTempChildren] = useState("");
    const [tempBlock, setTempBLock] = useState("");

    const [dataLoading, setDataLoad] = useState(false);
    const [tempBlockId, setTempBlockId] = useState("");
    const [tempChunkId, setTempChunkId] = useState("");
    const [showQuestionList, setShowQuestionList] = useState(false);

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value }) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                }}
            >
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            borderBottom: "3px solid indigo",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            // minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                        }}
                    >
                        {`B${value + 1}`}
                        <div
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "2px",
                            }}
                        >
                            <DragHandle />
                        </div>
                    </Card>
                </Badge.Ribbon>
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            blocks,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(
            tmpBlock,
            worksheet?.type === "personalized_learning_v4"
                ? ["children"]
                : ["data", "chunk", "blocks"],
            tmpChildren,
        );
        setBlock(tmpBlock);
    };

    return (
        <Card
            style={{
                maxHeight: "85vh",
                overflow: "auto",
                scrollbarWidth: "thin",
            }}
        >
            <Row gutter={[20, 20]}>
                <Col span={9}>
                    <Title
                        level={4}
                        editable={
                            disableWrite
                                ? false
                                : {
                                      text: name,
                                      onChange: (val) => {
                                          let tmpBlock = cloneDeep(block);
                                          tmpBlock = set(
                                              tmpBlock,
                                              ["data", "chunk", "name"],
                                              val,
                                          );
                                          setBlock(tmpBlock);
                                      },
                                      triggerType: ["icon", "text"],
                                      enterIcon: null,
                                  }
                        }
                    >
                        {is_variant ? "(V) " : ""}C{currentBlock} {name}
                    </Title>
                </Col>
                <Col span={4}>
                    <div style={{ display: "flex", gap: "20px" }}>
                        {block?.id ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Form.Item label="Show only audio list?">
                                    <Checkbox
                                        value={showQuestionList}
                                        onChange={(e) => {
                                            setShowQuestionList(
                                                e.target.checked,
                                            );
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={"Open Preview"}
                                    style={{
                                        border: "1px solid black",
                                        padding: "0 5px",
                                        display: "inline",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            const queryParams =
                                                new URLSearchParams();
                                            const blockId =
                                                block?.children[0]?.id;
                                            const jsonData = {
                                                context: "preview",
                                                chunkPreview: true,
                                                progression_id: levelId,
                                                progression_activity_id: uuid(),
                                                path_id: uuid(),
                                                variant_id: blockId,
                                                block_id: blockId,
                                            };

                                            // Add parameters from JSON object to the query string
                                            for (const key in jsonData) {
                                                if (
                                                    jsonData.hasOwnProperty(key)
                                                ) {
                                                    queryParams.append(
                                                        key,
                                                        jsonData[key],
                                                    );
                                                }
                                            }

                                            // Construct the URL with the parameters
                                            const url = `${
                                                process.env
                                                    .REACT_APP_CLIENT_PREVIEW
                                            }/home/worksheet/${
                                                worksheet?.id
                                            }/${levelId}?${queryParams.toString()}&logs=true&showQuestion=true&noCache=true${
                                                showQuestionList
                                                    ? "&questionText=true"
                                                    : ""
                                            }`;

                                            console.log({ url });
                                            // Open the URL in a new tab
                                            window.open(url, "_blank");
                                        }}
                                        disabled={!levelId}
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        QA
                                    </Button>
                                    {/* <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    const queryParams = new URLSearchParams();
                                    const jsonData = {
                                        context: "preview",
                                        ...previewData,
                                        progression_activity_id: uuid(),
                                        path_id: uuid(),
                                        variant_id: block?.id,
                                        block_id: block?.id,
                                    };

                                    // Add parameters from JSON object to the query string
                                    for (const key in jsonData) {
                                        if (jsonData.hasOwnProperty(key)) {
                                            queryParams.append(
                                                key,
                                                jsonData[key],
                                            );
                                        }
                                    }

                                    // Construct the URL with the parameters
                                    const url = `https://mathai.ai/home/worksheet/${worksheet?.id
                                        }/${previewData["progression_id"]
                                        }?${queryParams.toString()}&logs=true&showQuestion=true`;

                                    // Open the URL in a new tab
                                    window.open(url, "_blank");
                                }}
                                disabled={!previewData["progression_id"]}
                            >
                                MathAi
                            </Button> */}
                                </Form.Item>
                            </div>
                        ) : (
                            <Button
                                size="small"
                                style={{
                                    marginLeft: 16,
                                    display: "inline",
                                }}
                                danger
                                type="primary"
                                onClick={(val) => {
                                    if (
                                        worksheet?.type ===
                                        "personalized_learning_v4"
                                    )
                                        return;
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock.id = uuid();
                                    tmpBlock.tmpId = uuid();
                                    setBlock(tmpBlock);
                                }}
                            >
                                {worksheet?.type === "personalized_learning_v4"
                                    ? "Save Worksheet to preview"
                                    : "Set Block ID"}
                            </Button>
                        )}
                    </div>
                </Col>
                <Col span={9}>
                    <Form.Item label="Slug">
                        <Input
                            value={slug}
                            disabled={disableWrite}
                            onChange={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    worksheet?.type ===
                                        "personalized_learning_v4"
                                        ? ["backend", "slug"]
                                        : ["data", "slug"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Update Logic">
                        <LogicModal
                            isAllGlobal={true}
                            disableWrite={disableWrite}
                            chunkIndex={chunkIndex}
                            chunkSlug={slug}
                            computeFUnctions={compute_functions || []}
                            onSave={(val) => {
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    worksheet?.type ===
                                        "personalized_learning_v4"
                                        ? ["backend", "compute_functions"]
                                        : [
                                              "data",
                                              "chunk",
                                              "compute_functions",
                                          ],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <h3
                        style={{
                            fontWeight: "bold",
                            marginBottom: "10px",
                        }}
                    >
                        Dev Remarks
                    </h3>
                    <SlateEditor
                        disabled={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        id={`${id || tmpId}_remarks`}
                        onChange={(value: any) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["data", "chunk", "dev_remarks"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        isFocused={currentEditor === `${id || tmpId}_remarks`}
                        setEditor={(id: string) => setCurrentEditor(id)}
                        value={dev_remarks}
                        placeholder={"Type here..."}
                        required={false}
                    />
                </Col>
                {/* <Col span={8}>
                    <Form.Item label="Chunk Icon">
                        <RenderUpload
                            singleUpload={true}
                            disabled={disableWrite}
                            path="home-explore/document/"
                            onChangeCustom={(e) => {
                                const value = e.target.value;
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "chunk", "icon"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            label="upload an image"
                            value={icon}
                        />
                    </Form.Item>
                </Col> */}
                <Col span={8}>
                    <Form.Item label={"Is variant?"}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={is_variant}
                            onChange={(value) => {
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    worksheet?.type ===
                                        "personalized_learning_v4"
                                        ? ["backend", "is_variant"]
                                        : ["data", "chunk", "is_variant"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>

                    {renderContextVariables && renderContextVariables()}
                </Col>
                {!disableWrite && (
                    <>
                        <Col span={12}>
                            <Form.Item label={"Add Block/Stories"}>
                                <Input.TextArea
                                    disabled={disableWrite}
                                    value={tempBlock}
                                    placeholder="Paste Block/Stories Config here"
                                    onChange={(e) =>
                                        setTempBLock(e.target.value)
                                    }
                                    rows={2}
                                    style={{
                                        width: "50%",
                                    }}
                                />
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempBlock);
                                            if (
                                                ![
                                                    V2_STORIES_BLOCK,
                                                    ALL_IN_ONE,
                                                ].includes(parsedJson?.type)
                                            ) {
                                                message.warn("Invalid JSON");
                                                return;
                                            }
                                            let tmpBlock = _.cloneDeep(block);

                                            let newBlock = updateKeysAndCopy(
                                                _.omit(parsedJson, [
                                                    "id",
                                                    "worksheet_block_map_id",
                                                    "tmpId",
                                                ]),
                                                worksheet?.type ===
                                                    "personalized_learning_v4",
                                            );

                                            if (
                                                parsedJson?.type ===
                                                V2_STORIES_BLOCK
                                            ) {
                                                if (
                                                    worksheet?.type ===
                                                    "personalized_learning_v4"
                                                ) {
                                                    newBlock =
                                                        convertStoriesToV4(
                                                            newBlock,
                                                        );
                                                } else if (
                                                    worksheet?.type ===
                                                    "personalized_learning_v3"
                                                ) {
                                                    newBlock =
                                                        convertStoriesToV3(
                                                            newBlock,
                                                        );
                                                }
                                            } else if (
                                                parsedJson?.type === ALL_IN_ONE
                                            ) {
                                                if (
                                                    worksheet?.type ===
                                                    "personalized_learning_v4"
                                                ) {
                                                    newBlock =
                                                        convertAllInOneBlockToV4(
                                                            newBlock,
                                                        );
                                                } else if (
                                                    worksheet?.type ===
                                                    "personalized_learning_v3"
                                                ) {
                                                    newBlock =
                                                        convertAllInOneBlockToV3(
                                                            newBlock,
                                                        );
                                                }
                                            }

                                            tmpBlock = _.set(
                                                tmpBlock,
                                                worksheet?.type ===
                                                    "personalized_learning_v4"
                                                    ? [
                                                          "children",
                                                          blocks?.length,
                                                      ]
                                                    : [
                                                          "data",
                                                          "chunk",
                                                          "blocks",
                                                          blocks?.length,
                                                      ],
                                                newBlock,
                                            );
                                            setBlock(tmpBlock);
                                            setTempBLock("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e);
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Add Block/Stories
                                </Button>
                            </Form.Item>
                            {worksheet?.type === "personalized_learning_v4" && (
                                <Form.Item label={"Paste Block Id"}>
                                    <Input
                                        disabled={disableWrite}
                                        value={tempBlockId}
                                        placeholder="Paste Block Id"
                                        onChange={(e) =>
                                            setTempBlockId(e.target.value)
                                        }
                                        style={{
                                            width: "50%",
                                        }}
                                    />
                                    <Button
                                        disabled={disableWrite}
                                        style={{ marginTop: "10px" }}
                                        type="primary"
                                        size="small"
                                        loading={dataLoading}
                                        onClick={async () => {
                                            try {
                                                if (Number(tempBlockId) < 1) {
                                                    message.warn(
                                                        "Invalid Block id",
                                                    );
                                                    return;
                                                }
                                                setDataLoad(true);
                                                let parsedJson =
                                                    await getBlockfromDb(
                                                        Number(tempBlockId),
                                                    );
                                                if (
                                                    ![
                                                        V2_STORIES_BLOCK,
                                                        ALL_IN_ONE,
                                                    ].includes(parsedJson?.type)
                                                ) {
                                                    message.warn(
                                                        "Invalid Block",
                                                    );
                                                    setDataLoad(false);
                                                    return;
                                                }
                                                let tmpBlock =
                                                    _.cloneDeep(block);

                                                let newBlock =
                                                    updateKeysAndCopy(
                                                        _.omit(parsedJson, [
                                                            "id",
                                                            "worksheet_block_map_id",
                                                            "tmpId",
                                                        ]),
                                                        true,
                                                    );

                                                if (
                                                    parsedJson?.type ===
                                                    V2_STORIES_BLOCK
                                                ) {
                                                    newBlock =
                                                        convertStoriesToV4(
                                                            newBlock,
                                                        );
                                                } else if (
                                                    parsedJson?.type ===
                                                    ALL_IN_ONE
                                                ) {
                                                    newBlock =
                                                        convertAllInOneBlockToV4(
                                                            newBlock,
                                                        );
                                                }

                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "children",
                                                        blocks?.length,
                                                    ],
                                                    newBlock,
                                                );
                                                setBlock(tmpBlock);
                                                setTempBlockId("");
                                                message.info("Updated!");
                                            } catch (e) {
                                                captureException(e);
                                                console.log(e);
                                                message.error(
                                                    "Something went wrong!",
                                                );
                                            }
                                            setDataLoad(false);
                                        }}
                                    >
                                        Update Chunk
                                    </Button>
                                </Form.Item>
                            )}
                            <SortableList
                                items={blocks}
                                onSortEnd={onSortEnd}
                                axis="xy"
                                useDragHandle
                            />
                        </Col>
                        <Col span={12}>
                            {block?.id && (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "15px",
                                    }}
                                >
                                    <Form.Item label="Chunk Id">
                                        <Input.Search
                                            value={block.id}
                                            onChange={() => {}}
                                            enterButton="Copy"
                                            size="small"
                                            onSearch={() => {
                                                message.info(
                                                    `Copied Id to clipboard!`,
                                                );
                                                navigator.clipboard.writeText(
                                                    block.id,
                                                );
                                            }}
                                        />
                                    </Form.Item>
                                    {worksheet?.type ===
                                        "personalized_learning_v4" && (
                                        <Form.Item label="Published Chunk Id">
                                            {publishedBlocksMap[block.id] ? (
                                                <Input.Search
                                                    value={
                                                        publishedBlocksMap[
                                                            block.id
                                                        ]
                                                    }
                                                    onChange={() => {}}
                                                    enterButton="Copy"
                                                    size="small"
                                                    onSearch={() => {
                                                        message.info(
                                                            `Copied Id to clipboard!`,
                                                        );
                                                        navigator.clipboard.writeText(
                                                            block.id,
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <span>Not published Yet</span>
                                            )}
                                        </Form.Item>
                                    )}
                                </div>
                            )}

                            <Form.Item label={"Add Keyboard config"}>
                                <KeyboardModal
                                    blocks={blocks}
                                    setBlock={({
                                        blocksList = [],
                                        keyboard = [],
                                        isLatex = false,
                                    }) => {
                                        setBlock(
                                            updateBlocksKeyboard({
                                                blocksList,
                                                keyboard,
                                                isLatex,
                                                block,
                                                blocks,
                                                worksheet,
                                            }),
                                        );
                                    }}
                                    disableWrite={disableWrite}
                                />
                            </Form.Item>

                            <Form.Item label="Chunk type">
                                <Select
                                    value={
                                        (worksheet?.type ===
                                        "personalized_learning_v4"
                                            ? block?.backend?.activity_type
                                            : block?.data?.chunk
                                                  ?.activity_type) || "INHERIT"
                                    }
                                    onChange={(value) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            worksheet?.type ===
                                                "personalized_learning_v4"
                                                ? ["backend", "activity_type"]
                                                : [
                                                      "data",
                                                      "chunk",
                                                      "activity_type",
                                                  ],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                >
                                    <Select.Option value="INHERIT">
                                        Inherit
                                    </Select.Option>
                                    <Select.Option value="CHALLENGE">
                                        Challenge (Yellow star)
                                    </Select.Option>
                                    <Select.Option value="LEARNING">
                                        Learning (Blue star)
                                    </Select.Option>
                                </Select>
                            </Form.Item>

                            <Divider />

                            <Button
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(`Copied Chunk to clipboard!`);

                                    navigator.clipboard.writeText(
                                        JSON.stringify(block),
                                    );
                                }}
                                size="small"
                                style={{
                                    marginBottom: "10px",
                                }}
                            >
                                Copy this Chunk to Clipboard
                            </Button>
                            <Form.Item label={"Paste Chunk Config here"}>
                                <Input.TextArea
                                    disabled={disableWrite}
                                    value={tempChildren}
                                    placeholder="Paste Chunk Config here"
                                    onChange={(e) =>
                                        setTempChildren(e.target.value)
                                    }
                                    rows={2}
                                    style={{
                                        width: "50%",
                                    }}
                                />
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        try {
                                            let parsedJson =
                                                JSON.parse(tempChildren);
                                            if (parsedJson?.type !== "chunk") {
                                                message.warn("Invalid JSON");
                                                return;
                                            }
                                            if (
                                                worksheet?.type ===
                                                "personalized_learning_v4"
                                            ) {
                                                parsedJson =
                                                    convertChunkToV4(
                                                        parsedJson,
                                                    );
                                            } else if (
                                                worksheet?.type ===
                                                "personalized_learning_v3"
                                            ) {
                                                parsedJson =
                                                    convertChunkToV3(
                                                        parsedJson,
                                                    );
                                            }

                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["data"],
                                                updateKeysAndCopy(
                                                    parsedJson.data,
                                                    true,
                                                ),
                                            );

                                            if (
                                                worksheet?.type ===
                                                "personalized_learning_v4"
                                            ) {
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["backend"],
                                                    parsedJson.backend,
                                                );
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["children"],
                                                    parsedJson?.children?.map(
                                                        (gChild: any) =>
                                                            _.omit(
                                                                {
                                                                    ...gChild,
                                                                    tmpId: uuid(),
                                                                },
                                                                ["id"],
                                                            ),
                                                    ),
                                                );
                                            }
                                            setBlock(tmpBlock);
                                            setTempChildren("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e);
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Update Chunk config
                                </Button>
                            </Form.Item>

                            <Form.Item label={"Paste Chunk Id"}>
                                <Input
                                    disabled={disableWrite}
                                    value={tempChunkId}
                                    placeholder="Paste Chunk Id"
                                    onChange={(e) =>
                                        setTempChunkId(e.target.value)
                                    }
                                    style={{
                                        width: "50%",
                                    }}
                                />
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    size="small"
                                    loading={dataLoading}
                                    onClick={async () => {
                                        try {
                                            if (Number(tempChunkId) < 1) {
                                                message.warn(
                                                    "Invalid Chunk id",
                                                );
                                                return;
                                            }
                                            setDataLoad(true);
                                            let parsedJson =
                                                await getBlockfromDb(
                                                    Number(tempChunkId),
                                                );
                                            if (parsedJson?.type !== "chunk") {
                                                message.warn("Invalid Chunk");
                                                setDataLoad(false);
                                                return;
                                            }
                                            if (
                                                worksheet?.type ===
                                                "personalized_learning_v4"
                                            ) {
                                                parsedJson =
                                                    convertChunkToV4(
                                                        parsedJson,
                                                    );
                                            } else if (
                                                worksheet?.type ===
                                                "personalized_learning_v3"
                                            ) {
                                                parsedJson =
                                                    convertChunkToV3(
                                                        parsedJson,
                                                    );
                                            }
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["data"],
                                                updateKeysAndCopy(
                                                    parsedJson.data,
                                                    true,
                                                ),
                                            );

                                            if (
                                                worksheet?.type ===
                                                "personalized_learning_v4"
                                            ) {
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["backend"],
                                                    parsedJson.backend,
                                                );
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["children"],
                                                    parsedJson?.children?.map(
                                                        (gChild: any) =>
                                                            _.omit(
                                                                {
                                                                    ...gChild,
                                                                    tmpId: uuid(),
                                                                },
                                                                ["id"],
                                                            ),
                                                    ),
                                                );
                                            }
                                            setBlock(tmpBlock);
                                            setTempChunkId("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            captureException(e);
                                            console.log(e);
                                            message.error(
                                                "Something went wrong!",
                                            );
                                        }
                                        setDataLoad(false);
                                    }}
                                >
                                    Update Chunk
                                </Button>
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>
        </Card>
    );
};

const updateBlocksKeyboard = ({
    blocksList = [],
    keyboard = [],
    isLatex = false,
    block = {},
    blocks = [],
    worksheet,
}: any) => {
    let tmpBlock = cloneDeep(block);
    for (let i = 0; i < blocksList.length; i++) {
        const index = blocksList[i];
        const element = cloneDeep(blocks[index]);
        if (element?.type === V2_STORIES_BLOCK) {
            element.data.children = element.data.children?.map((story) => {
                if (story?.data?.children) {
                    story.data.children = story.data.children?.map((item) => {
                        if (item?.data?.input) {
                            if (isLatex) {
                                if (
                                    item?.type === "rich_text_input" ||
                                    item?.type ===
                                        "rich_text_mathquill_input_html"
                                ) {
                                    item.data.input.custom_keyboard = keyboard;
                                }
                            } else {
                                if (item?.type === "input") {
                                    item.data.input.custom_keyboard = keyboard;
                                }
                            }
                        }

                        return item;
                    });
                }
                return story;
            });
        } else if (element?.type === ALL_IN_ONE) {
            // For Stories
            if (element?.data?.other?.stories?.length) {
                element.data.other.stories = element.data.other.stories.map(
                    (stories: {
                        data: {
                            children: any[];
                        };
                    }) => {
                        stories.data.children = stories.data.children?.map(
                            (story) => {
                                if (story?.data?.children) {
                                    story.data.children =
                                        story.data.children?.map((item) => {
                                            if (item?.data?.input) {
                                                if (isLatex) {
                                                    if (
                                                        item?.type ===
                                                            "rich_text_input" ||
                                                        item?.type ===
                                                            "rich_text_mathquill_input_html"
                                                    ) {
                                                        item.data.input.custom_keyboard =
                                                            keyboard;
                                                    }
                                                } else {
                                                    if (
                                                        item?.type === "input"
                                                    ) {
                                                        item.data.input.custom_keyboard =
                                                            keyboard;
                                                    }
                                                }
                                            }

                                            return item;
                                        });
                                }
                                return story;
                            },
                        );
                        return stories;
                    },
                );
            }
            // For Components
            if (element?.data[ALL_IN_ONE]?.components?.length) {
                element.data[ALL_IN_ONE].components = element.data[
                    ALL_IN_ONE
                ]?.components.map(
                    (component: { type: any; figma: { variables: any[] } }) => {
                        switch (component?.type) {
                            case COMPONENT_TYPES.GENERIC_TABLE:
                                if (component?.figma?.variables) {
                                    component.figma.variables =
                                        component.figma.variables?.map((v) => {
                                            if (v?.name === "table_and_tags") {
                                                v.value = v.value?.map(
                                                    (item: {
                                                        type: string;
                                                        props: {
                                                            table: {
                                                                value: {
                                                                    variables: {
                                                                        value: {
                                                                            variables: {
                                                                                name: string;
                                                                                value: any;
                                                                            }[];
                                                                        };
                                                                    };
                                                                };
                                                            };
                                                        };
                                                    }) => {
                                                        if (
                                                            item?.type ===
                                                            "table_collection"
                                                        ) {
                                                            item.props.table.value.variables.value.variables =
                                                                item.props?.table?.value?.variables?.value?.variables?.map(
                                                                    (tableVar: {
                                                                        name: string;
                                                                        value: any;
                                                                    }) => {
                                                                        if (
                                                                            tableVar.name ===
                                                                            "tableData"
                                                                        ) {
                                                                            if (
                                                                                tableVar.value &&
                                                                                !isString(
                                                                                    tableVar.value,
                                                                                )
                                                                            ) {
                                                                                tableVar.value.cells =
                                                                                    tableVar.value?.cells?.map(
                                                                                        (
                                                                                            row: {
                                                                                                input: {
                                                                                                    enabled: any;
                                                                                                    type: string;
                                                                                                    keyboard: any;
                                                                                                };
                                                                                            }[],
                                                                                        ) =>
                                                                                            row.map(
                                                                                                (
                                                                                                    cell,
                                                                                                ) => {
                                                                                                    if (
                                                                                                        cell
                                                                                                            ?.input
                                                                                                            ?.enabled
                                                                                                    ) {
                                                                                                        if (
                                                                                                            !cell
                                                                                                                ?.input
                                                                                                                ?.type ||
                                                                                                            cell
                                                                                                                ?.input
                                                                                                                ?.type ===
                                                                                                                "text"
                                                                                                        ) {
                                                                                                            if (
                                                                                                                !isLatex
                                                                                                            )
                                                                                                                cell.input.keyboard =
                                                                                                                    keyboard;
                                                                                                        } else if (
                                                                                                            isLatex
                                                                                                        )
                                                                                                            cell.input.keyboard =
                                                                                                                keyboard;
                                                                                                    }
                                                                                                    return cell;
                                                                                                },
                                                                                            ),
                                                                                    );
                                                                            }
                                                                        }
                                                                        return tableVar;
                                                                    },
                                                                );
                                                        }

                                                        return item;
                                                    },
                                                );
                                            }
                                            return v;
                                        });
                                }
                                break;
                            case COMPONENT_TYPES.MULTI_INPUT:
                                if (component?.figma?.variables) {
                                    component.figma.variables =
                                        component.figma.variables?.map((v) => {
                                            if (v?.name === "inputs_and_tags") {
                                                v.value = v.value?.map(
                                                    (item: {
                                                        type: string;
                                                        props: {
                                                            inputs_list: {
                                                                value: {
                                                                    variables: {
                                                                        value: {
                                                                            variables: {
                                                                                name: string;
                                                                                value: any;
                                                                            }[];
                                                                        };
                                                                    };
                                                                };
                                                            };
                                                        };
                                                    }) => {
                                                        if (
                                                            item?.type ===
                                                            "generic_collection"
                                                        ) {
                                                            item.props.inputs_list.value.variables.value.variables =
                                                                item.props?.inputs_list?.value?.variables?.value?.variables?.map(
                                                                    (genVar: {
                                                                        name: string;
                                                                        value: any;
                                                                    }) => {
                                                                        if (
                                                                            genVar.name ===
                                                                            "Components"
                                                                        ) {
                                                                            if (
                                                                                genVar.value &&
                                                                                !isString(
                                                                                    genVar.value,
                                                                                )
                                                                            ) {
                                                                                genVar.value =
                                                                                    genVar.value?.map(
                                                                                        (item: {
                                                                                            type: string;
                                                                                            props: {
                                                                                                keyboard: {
                                                                                                    value: any;
                                                                                                };
                                                                                            };
                                                                                        }) => {
                                                                                            if (
                                                                                                item?.type ===
                                                                                                    "input" &&
                                                                                                !isLatex &&
                                                                                                item
                                                                                                    ?.props
                                                                                                    ?.keyboard
                                                                                            ) {
                                                                                                item.props.keyboard.value =
                                                                                                    keyboard;
                                                                                            }

                                                                                            if (
                                                                                                [
                                                                                                    "rich_text_input",
                                                                                                    "rich_text_mathquill_input_html",
                                                                                                ].includes(
                                                                                                    item?.type,
                                                                                                ) &&
                                                                                                isLatex &&
                                                                                                item
                                                                                                    ?.props
                                                                                                    ?.keyboard
                                                                                            ) {
                                                                                                item.props.keyboard.value =
                                                                                                    keyboard;
                                                                                            }

                                                                                            return item;
                                                                                        },
                                                                                    );
                                                                            }
                                                                        }
                                                                        return genVar;
                                                                    },
                                                                );
                                                        }

                                                        return item;
                                                    },
                                                );
                                            }
                                            return v;
                                        });
                                }
                                break;
                            case COMPONENT_TYPES.MULTI_SELECT_INPUT:
                                if (!isLatex && component?.figma?.variables) {
                                    component.figma.variables =
                                        component.figma.variables?.map((v) => {
                                            if (
                                                v?.name === "schema" &&
                                                v?.value?.props?.keyboard
                                            ) {
                                                v.value.props.keyboard.value =
                                                    keyboard;
                                            }
                                            return v;
                                        });
                                }
                                break;
                            default:
                                break;
                        }
                        return component;
                    },
                );
            }
        }
        tmpBlock = _.set(
            tmpBlock,
            worksheet?.type === "personalized_learning_v4"
                ? ["children", index]
                : ["data", "chunk", "blocks", index],
            element,
        );
    }

    return tmpBlock;
};

export default ChunkEditor;
