import _ from "lodash";
import { useEffect, useState } from "react";
// import ProgressionEditor from "./ProgressionEditor";
import {
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Switch,
    TimePicker,
    message,
} from "antd";
import { CustomEditor } from "../../..";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import { v4 as uuid } from "uuid";
import ChunkEditor from "./ChunkEditor";
import { BLOCK_TYPES } from "../../../common";
import { RenderUpload, SlateEditor } from "src/components";
import GlobalContextVariablesModal from "./GlobalContextVariablesModal";
import CustomSelectField from "../../../common/CustomSelectField";
import { arrayMoveImmutable } from "array-move";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { CheckOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import {
    convertChunkToV3,
    convertChunkToV4,
    convertLevelToV3,
    convertLevelToV4,
} from "./helper";
import { useWorksheetState } from "src/modules/worksheet/components/contexts/WorksheetContext";
import { captureException } from "@sentry/react";
import moment from "moment";
import { creditTags } from "src/modules/worksheet/components/WorksheetEditor/components/Modals/WorksheetForm/Form";

const { PROGRESS_LEVEL_BLOCK } = BLOCK_TYPES;

const DefaultEditor = ({
    block,
    setBlock,
    parentBlock,
    currentSubBlock,
    currentChunkBlock,
    isEditor,
    setIsEditor,
    isNewBlock,
    setIsNewBlock,
    worksheet,
    toggleShouldBlockUI,
    onInputVariableUpdate,
    mentionsList = [],
    hasMentions,
    // blocks,
    disableWrite,
    isReadOnlyMode,
    currentBlock,
    setNewJob,
    updateStoriesJob,
    storiesPath,
}: any) => {
    const {
        id,
        tmpId,
        is_hidden,
        data: {
            slug: dataSlug,
            [PROGRESS_LEVEL_BLOCK]: {
                name,
                description,
                passing_badge_count: dataBadgeCount,
                banner,
                card_banner,
                challenge_banner,
                challenge_description,
                global_context_variables: dataVars = {
                    base_variables: [],
                    derived_variables: [],
                },
                dev_remarks = [],
            },
        },
        children,
        backend,
    } = block;

    const DATA_PATH =
        worksheet?.type === "personalized_learning_v4" ? "backend" : "data";

    const { getBlockfromDb, publishedBlocksMap }: any = useWorksheetState();

    let slug =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.slug
            : dataSlug;
    let global_context_variables =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.global_context_variables || {
                  base_variables: [],
                  derived_variables: [],
              }
            : dataVars;
    let passing_badge_count =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.passing_badge_count
            : dataBadgeCount;

    const [loading, setLoad] = useState(false);
    const [tempChildren, setTempChildren] = useState("");
    const [tempChunk, setTempChunk] = useState("");

    const [dataLoading, setDataLoad] = useState(false);
    const [tempLevelId, setTempLevelId] = useState("");
    const [tempChunkId, setTempChunkId] = useState("");

    const [currentEditor, setCurrentEditor] = useState(
        `${id || tmpId}_remarks`,
    );
    const [showQuestionList, setShowQuestionList] = useState(false);

    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_remarks`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    useEffect(() => {
        setLoad(true);
        setTempChildren("");
        let v = setTimeout(() => setLoad(false), 50);
        return () => {
            clearTimeout(v);
        };
    }, [currentSubBlock, parentBlock, currentBlock, currentChunkBlock]);

    useEffect(() => {
        if (!block[DATA_PATH]?.deadline?.type) {
            let tmpBlock = _.cloneDeep(block);
            _.set(tmpBlock, [DATA_PATH, "deadline", "type"], "INHERIT");
            setBlock(tmpBlock);
        }

        if (!block[DATA_PATH]?.max_limit?.type) {
            let tmpBlock = _.cloneDeep(block);
            _.set(tmpBlock, [DATA_PATH, "max_limit", "type"], "INHERIT");
            setBlock(tmpBlock);
        }

        if (!block[DATA_PATH]?.activity_type) {
            let tmpBlock = _.cloneDeep(block);
            _.set(tmpBlock, [DATA_PATH, "activity_type"], "CHALLENGE");
            setBlock(tmpBlock);
        }
    }, [block, DATA_PATH]);

    const renderContextVariables = () => {
        return (
            <Form.Item label={"Global Context Variables"}>
                <GlobalContextVariablesModal
                    global_context_variables={global_context_variables}
                    isReadOnlyMode={isReadOnlyMode}
                    disableWrite={disableWrite}
                    setBlock={(value: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            worksheet?.type === "personalized_learning_v4"
                                ? ["backend", "global_context_variables"]
                                : [
                                      "data",
                                      PROGRESS_LEVEL_BLOCK,
                                      "global_context_variables",
                                  ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            </Form.Item>
        );
    };

    const renderLinkSelect = ({
        value,
        onChange,
        options,
        style,
        next_line,
        question_variables = [],
    }) => {
        return (
            <Form layout={next_line ? "vertical" : "horizontal"}>
                <Form.Item label={"Link Variable"}>
                    <CustomSelectField
                        disabled={disableWrite}
                        value={value}
                        btnText={"Global Var"}
                        setOption={(value: any) => {
                            let tmpBlock = _.cloneDeep(block);
                            if (
                                global_context_variables?.base_variables?.length
                            ) {
                                tmpBlock = _.set(
                                    tmpBlock,
                                    worksheet?.type ===
                                        "personalized_learning_v4"
                                        ? [
                                              "backend",
                                              "global_context_variables",
                                              "base_variables",
                                              global_context_variables
                                                  ?.base_variables?.length,
                                          ]
                                        : [
                                              "data",
                                              PROGRESS_LEVEL_BLOCK,
                                              "global_context_variables",
                                              "base_variables",
                                              global_context_variables
                                                  ?.base_variables?.length,
                                          ],
                                    { name: value, value: "" },
                                );
                            } else {
                                tmpBlock = _.set(
                                    tmpBlock,
                                    worksheet?.type ===
                                        "personalized_learning_v4"
                                        ? [
                                              "backend",
                                              "global_context_variables",
                                              "base_variables",
                                          ]
                                        : [
                                              "data",
                                              PROGRESS_LEVEL_BLOCK,
                                              "global_context_variables",
                                              "base_variables",
                                          ],
                                    [{ name: value, value: "" }],
                                );
                            }
                            setBlock(tmpBlock);
                        }}
                        onChange={onChange}
                        options={[
                            {
                                label: "none",
                                value: null,
                            },
                            ...(options || [
                                {
                                    label: "Global Variables",
                                    options:
                                        global_context_variables?.base_variables?.map(
                                            ({ name }: any) => ({
                                                value: name,
                                                label: name,
                                            }),
                                        ),
                                },
                                {
                                    label: "Qyestion Variables",
                                    options: question_variables?.map(
                                        ({ name }: any) => ({
                                            value: name,
                                            label: name,
                                        }),
                                    ),
                                },
                            ]),
                        ]}
                        placeholder={"Select variable"}
                        style={style}
                    />
                </Form.Item>
            </Form>
        );
    };

    const mentionProps = {
        setNewJob,
        updateStoriesJob,
        storiesPath,
        mentionsList:
            [
                ...(global_context_variables?.base_variables || []),
                ...(global_context_variables?.derived_variables || []),
            ].map((item: { name: any }, i) => ({
                key: i,
                text: item.name,
                data: item.name,
            })) || [],
        hasMentions: true,
        renderContextVariables,
        globalContextVariables: global_context_variables,
        renderLinkSelect,
        // ["quiz_form", "personalized_learning_v2", "personalized_learning_v3"].includes(
        //     worksheet?.type,
        // ),
    };

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value, child }) => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                }}
            >
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            borderBottom: "3px solid indigo",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            // minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                        }}
                    >
                        {`C${value + 1}`}
                        <div
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "2px",
                            }}
                        >
                            <DragHandle />
                        </div>
                    </Card>
                </Badge.Ribbon>
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["children"], tmpChildren);
        setBlock(tmpBlock);
    };

    const colSpan = worksheet?.type === "personalized_learning_v4" ? 6 : 8;
    if (!loading && currentSubBlock == null) {
        return (
            <Card
                style={{
                    maxHeight: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "thin",
                }}
            >
                <Row gutter={[20, 20]}>
                    <Col span={colSpan}>
                        <h2
                            style={{
                                fontWeight: "bold",
                                marginBottom: "20px",
                            }}
                        >
                            Card Details
                        </h2>
                    </Col>
                    {block?.id && (
                        <Col span={colSpan}>
                            <span
                                style={{
                                    border: "1px solid black",
                                    padding: "5px",
                                    marginRight: "5px",
                                    display: "flex",
                                    gap: "10px",
                                    // flexWrap: "wrap",
                                    flexDirection: "column",
                                }}
                            >
                                <span>Open Preview</span>
                                <Form.Item label="Show only audio list?">
                                    <Checkbox
                                        value={showQuestionList}
                                        onChange={(e) => {
                                            setShowQuestionList(
                                                e.target.checked,
                                            );
                                        }}
                                    />
                                </Form.Item>
                                <div style={{ display: "flex", gap: "8px" }}>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            const url = `${
                                                process.env
                                                    .REACT_APP_CLIENT_PREVIEW
                                            }/home/worksheet/${worksheet?.id}/${
                                                block?.id
                                            }?isLanding=true&logs=true&noCache=true&showQuestion=true${
                                                showQuestionList
                                                    ? "&questionText=true"
                                                    : ""
                                            }`;
                                            // Open the URL in a new tab
                                            window.open(url, "_blank");
                                        }}
                                    >
                                        QA
                                    </Button>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            const url = `${process.env.REACT_APP_CLIENT_PROD}/home/worksheet/${worksheet?.id}/${block?.id}`;
                                            // Open the URL in a new tab
                                            window.open(url, "_blank");
                                        }}
                                    >
                                        MathAi
                                    </Button>
                                </div>
                            </span>
                        </Col>
                    )}
                    {worksheet?.type === "personalized_learning_v4" && (
                        <Col span={colSpan}>
                            <Form.Item label={"Is hidden?"}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={is_hidden}
                                    onChange={(value) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock.is_hidden = value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={colSpan}>
                        <Form.Item label="Slug">
                            <Input
                                value={slug}
                                disabled={disableWrite}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        worksheet?.type ===
                                            "personalized_learning_v4"
                                            ? ["backend", "slug"]
                                            : ["data", "slug"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            validateStatus={!name ? "error" : ""}
                            help={!name ? "Field cannot be empty" : ""}
                        >
                            <Input
                                value={name}
                                disabled={disableWrite}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["data", PROGRESS_LEVEL_BLOCK, "name"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="Description"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea
                                value={description}
                                disabled={disableWrite}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            PROGRESS_LEVEL_BLOCK,
                                            "description",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Level type">
                            <Select
                                value={
                                    block[DATA_PATH]?.activity_type || "INHERIT"
                                }
                                onChange={(value) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    _.set(
                                        tmpBlock,
                                        [DATA_PATH, "activity_type"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            >
                                <Select.Option value="INHERIT">
                                    Inherit (worksheet)
                                </Select.Option>
                                <Select.Option value="CHALLENGE">
                                    Challenge (Yellow star)
                                </Select.Option>
                                <Select.Option value="LEARNING">
                                    Learning (Blue star)
                                </Select.Option>
                                <Select.Option value="EXAM">Exam</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    {block[DATA_PATH]?.activity_type === "EXAM" && (
                        <Col span={8}>
                            <Form.Item
                                label="Number of Sets"
                                rules={[{ required: true }]}
                                validateStatus={
                                    !block[DATA_PATH]?.number_of_sets ||
                                    block[DATA_PATH]?.number_of_sets <= 0
                                        ? "error"
                                        : ""
                                }
                                help={
                                    !block[DATA_PATH]?.number_of_sets ||
                                    block[DATA_PATH]?.number_of_sets <= 0
                                        ? "Field cannot be empty"
                                        : ""
                                }
                            >
                                <Input
                                    disabled={disableWrite}
                                    value={block[DATA_PATH]?.number_of_sets}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        let tmpBlock = _.cloneDeep(block);
                                        _.set(
                                            tmpBlock,
                                            [DATA_PATH, "number_of_sets"],
                                            value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    required
                                    type="number"
                                    placeholder={`Number of Sets`}
                                />
                            </Form.Item>
                        </Col>
                    )}

                    <Col span={colSpan}>
                        <Form.Item label={"Disable resume popup?"}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={block[DATA_PATH]?.disable_resume_popup}
                                onChange={(value) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    _.set(
                                        tmpBlock,
                                        [DATA_PATH, "disable_resume_popup"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Exit Popup">
                            <Select
                                value={
                                    block[DATA_PATH]?.exit_popup || "DEFAULT"
                                }
                                onChange={(value) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    _.set(
                                        tmpBlock,
                                        [DATA_PATH, "exit_popup"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            >
                                <Select.Option value="DEFAULT">
                                    Default
                                </Select.Option>
                                <Select.Option value="EXAM">Exam</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                        <Form.Item label={"Submit if student leave?"}>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={
                                    block[DATA_PATH]?.submit_if_student_leave
                                }
                                onChange={(value) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    _.set(
                                        tmpBlock,
                                        [DATA_PATH, "submit_if_student_leave"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="Passing Badge Count"
                            rules={[{ required: true }]}
                            validateStatus={
                                !passing_badge_count || passing_badge_count <= 0
                                    ? "error"
                                    : ""
                            }
                            help={
                                !passing_badge_count || passing_badge_count <= 0
                                    ? "Field cannot be empty"
                                    : ""
                            }
                        >
                            <Input
                                disabled={disableWrite}
                                value={passing_badge_count}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        worksheet?.type ===
                                            "personalized_learning_v4"
                                            ? ["backend", "passing_badge_count"]
                                            : [
                                                  "data",
                                                  PROGRESS_LEVEL_BLOCK,
                                                  "passing_badge_count",
                                              ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                required
                                type="number"
                                placeholder={`Passing Badge Count`}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="Home Banner"
                            rules={[{ required: true }]}
                            validateStatus={!banner ? "error" : ""}
                            help={!banner ? "Field cannot be empty" : ""}
                        >
                            <RenderUpload
                                singleUpload={true}
                                disabled={disableWrite}
                                path="home-explore/document/"
                                onChangeCustom={(e) => {
                                    const value = e.target.value;
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            PROGRESS_LEVEL_BLOCK,
                                            "banner",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                label="upload an image"
                                value={banner}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="Card Banner"
                            rules={[{ required: true }]}
                            validateStatus={!card_banner ? "error" : ""}
                            help={!card_banner ? "Field cannot be empty" : ""}
                        >
                            <RenderUpload
                                singleUpload={true}
                                disabled={disableWrite}
                                path="home-explore/document/"
                                onChangeCustom={(e) => {
                                    const value = e.target.value;
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            PROGRESS_LEVEL_BLOCK,
                                            "card_banner",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                label="upload an image"
                                value={card_banner}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Challenge Banner"
                            // rules={[{ required: true }]}
                            // validateStatus={!challenge_banner ? "error" : ""}
                            // help={!challenge_banner ? "Field cannot be empty" : ""}
                        >
                            <RenderUpload
                                singleUpload={true}
                                disabled={disableWrite}
                                path="home-explore/document/"
                                onChangeCustom={(e) => {
                                    const value = e.target.value;
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            PROGRESS_LEVEL_BLOCK,
                                            "challenge_banner",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                label="upload an image"
                                value={challenge_banner}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Challenge description">
                            <Input
                                value={challenge_description}
                                disabled={disableWrite}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "data",
                                            PROGRESS_LEVEL_BLOCK,
                                            "challenge_description",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Level tags">
                            <Select
                                mode="multiple"
                                allowClear
                                value={block[DATA_PATH]?.tags || []}
                                onChange={(value) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    _.set(tmpBlock, [DATA_PATH, "tags"], value);
                                    setBlock(tmpBlock);
                                }}
                                options={creditTags}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Card
                            title="Deadline"
                            style={{
                                border: "1px solid black",
                                marginBottom: "10px",
                            }}
                        >
                            <div style={{ display: "flex", gap: "8px" }}>
                                <Form.Item
                                    label="Deadline"
                                    style={{ width: 200 }}
                                >
                                    <Select
                                        value={
                                            block[DATA_PATH]?.deadline?.type ||
                                            "INHERIT"
                                        }
                                        onChange={(value) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            _.set(
                                                tmpBlock,
                                                [DATA_PATH, "deadline", "type"],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    >
                                        <Select.Option value="INHERIT">
                                            Inherit
                                        </Select.Option>
                                        <Select.Option value="NONE">
                                            None
                                        </Select.Option>
                                        <Select.Option value="CUSTOM">
                                            Custom
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                {block[DATA_PATH]?.deadline?.type ===
                                    "CUSTOM" && (
                                    <>
                                        <DatePicker
                                            defaultValue={
                                                block[DATA_PATH]?.deadline
                                                    ?.date_value
                                                    ? moment(
                                                          block[DATA_PATH]
                                                              ?.deadline
                                                              ?.date_value,
                                                      )
                                                    : undefined
                                            }
                                            style={{ flex: 1, height: "40px" }}
                                            onChange={(value, dateString) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                _.set(
                                                    tmpBlock,
                                                    [
                                                        DATA_PATH,
                                                        "deadline",
                                                        "date_value",
                                                    ],
                                                    dateString,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            allowClear={false}
                                        />
                                        <TimePicker
                                            defaultValue={
                                                block[DATA_PATH]?.deadline
                                                    ?.time_value
                                                    ? moment(
                                                          block[DATA_PATH]
                                                              ?.deadline
                                                              ?.time_value,
                                                          "HH:mm:ss",
                                                      )
                                                    : undefined
                                            }
                                            style={{ flex: 1, height: "40px" }}
                                            onChange={(value, timeString) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                _.set(
                                                    tmpBlock,
                                                    [
                                                        DATA_PATH,
                                                        "deadline",
                                                        "time_value",
                                                    ],
                                                    timeString,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            allowClear={false}
                                        />
                                        <Form.Item
                                            label="Deadline story"
                                            style={{ flex: 1 }}
                                        >
                                            <Input
                                                placeholder="Story name"
                                                value={
                                                    block[DATA_PATH]?.deadline
                                                        ?.story_name
                                                }
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    _.set(
                                                        tmpBlock,
                                                        [
                                                            DATA_PATH,
                                                            "deadline",
                                                            "story_name",
                                                        ],
                                                        e.target.value,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </div>
                        </Card>
                    </Col>

                    <Col span={24}>
                        <Card
                            title="Can be played multiple time?"
                            style={{
                                border: "1px solid black",
                                marginBottom: "10px",
                            }}
                        >
                            <div style={{ display: "flex", gap: "8px" }}>
                                <Form.Item
                                    label="Can be played multiple time?"
                                    style={{ width: 240, flex: 1 }}
                                >
                                    <Select
                                        value={
                                            block[DATA_PATH]?.max_limit?.type ||
                                            "INHERIT"
                                        }
                                        onChange={(value) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            _.set(
                                                tmpBlock,
                                                [
                                                    DATA_PATH,
                                                    "max_limit",
                                                    "type",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    >
                                        <Select.Option value="INHERIT">
                                            Inherit
                                        </Select.Option>
                                        <Select.Option value="NONE">
                                            None
                                        </Select.Option>
                                        <Select.Option value="CUSTOM">
                                            Custom
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                {block[DATA_PATH]?.max_limit?.type ===
                                    "CUSTOM" && (
                                    <>
                                        <Form.Item
                                            label="Max limit story"
                                            style={{ flex: 1 }}
                                        >
                                            <Input
                                                placeholder="Story name"
                                                value={
                                                    block[DATA_PATH]?.max_limit
                                                        ?.story_name
                                                }
                                                onChange={(e) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    _.set(
                                                        tmpBlock,
                                                        [
                                                            DATA_PATH,
                                                            "max_limit",
                                                            "story_name",
                                                        ],
                                                        e.target.value,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </div>
                        </Card>
                    </Col>

                    <Col span={8}>
                        <h3
                            style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                            }}
                        >
                            Dev Remarks
                        </h3>
                        <SlateEditor
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`${id || tmpId}_remarks`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "data",
                                        PROGRESS_LEVEL_BLOCK,
                                        "dev_remarks",
                                    ],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            isFocused={
                                currentEditor === `${id || tmpId}_remarks`
                            }
                            setEditor={(id: string) => setCurrentEditor(id)}
                            value={dev_remarks}
                            placeholder={"Type here..."}
                            required={false}
                        />
                    </Col>
                    {block?.id && (
                        <Col
                            span={16}
                            style={{
                                width: "100%",
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <Form.Item label="Level Id">
                                <Input.Search
                                    value={block.id}
                                    onChange={() => {}}
                                    enterButton="Copy"
                                    size="small"
                                    onSearch={() => {
                                        message.info(`Copied Id to clipboard!`);
                                        navigator.clipboard.writeText(block.id);
                                    }}
                                />
                            </Form.Item>
                            {worksheet?.type === "personalized_learning_v4" && (
                                <Form.Item label="Published Level Id">
                                    {publishedBlocksMap[block.id] ? (
                                        <Input.Search
                                            value={publishedBlocksMap[block.id]}
                                            onChange={() => {}}
                                            enterButton="Copy"
                                            size="small"
                                            onSearch={() => {
                                                message.info(
                                                    `Copied Id to clipboard!`,
                                                );
                                                navigator.clipboard.writeText(
                                                    block.id,
                                                );
                                            }}
                                        />
                                    ) : (
                                        <span>Not published Yet</span>
                                    )}
                                </Form.Item>
                            )}
                        </Col>
                    )}
                    <Col span={12}>
                        {/* <Divider /> */}
                        {renderContextVariables()}
                        {!disableWrite && (
                            <>
                                <Form.Item label={"Add Chunk"}>
                                    <Input.TextArea
                                        disabled={disableWrite}
                                        value={tempChunk}
                                        placeholder="Paste Chunk Config here"
                                        onChange={(e) =>
                                            setTempChunk(e.target.value)
                                        }
                                        rows={2}
                                        style={{
                                            width: "50%",
                                        }}
                                    />
                                    <Button
                                        disabled={disableWrite}
                                        style={{ marginTop: "10px" }}
                                        type="primary"
                                        size="small"
                                        onClick={() => {
                                            try {
                                                let parsedJson =
                                                    JSON.parse(tempChunk);
                                                if (
                                                    parsedJson?.type !== "chunk"
                                                ) {
                                                    message.warn(
                                                        "Invalid JSON",
                                                    );
                                                    return;
                                                }
                                                if (
                                                    worksheet?.type ===
                                                    "personalized_learning_v4"
                                                ) {
                                                    parsedJson =
                                                        convertChunkToV4(
                                                            parsedJson,
                                                        );
                                                    parsedJson = _.set(
                                                        parsedJson,
                                                        ["children"],
                                                        parsedJson?.children?.map(
                                                            (gChild: any) =>
                                                                _.omit(
                                                                    {
                                                                        ...gChild,
                                                                        tmpId: uuid(),
                                                                    },
                                                                    ["id"],
                                                                ),
                                                        ),
                                                    );
                                                } else if (
                                                    worksheet?.type ===
                                                    "personalized_learning_v3"
                                                ) {
                                                    parsedJson =
                                                        convertChunkToV3(
                                                            parsedJson,
                                                        );
                                                }
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "children",
                                                        children?.length,
                                                    ],
                                                    updateKeysAndCopy(
                                                        _.omit(
                                                            {
                                                                ...parsedJson,
                                                                tmpId: uuid(),
                                                            },
                                                            ["id"],
                                                        ),
                                                        true,
                                                    ),
                                                );
                                                setBlock(tmpBlock);
                                                setTempChunk("");
                                                message.info("Updated!");
                                            } catch (e) {
                                                // captureException(e);
                                                message.error("Error in JSON!");
                                            }
                                        }}
                                    >
                                        Add Chunk
                                    </Button>
                                </Form.Item>
                                <Form.Item label={"Paste Chunk Id"}>
                                    <Input
                                        disabled={disableWrite}
                                        value={tempChunkId}
                                        placeholder="Paste Chunk Id"
                                        onChange={(e) =>
                                            setTempChunkId(e.target.value)
                                        }
                                        style={{
                                            width: "50%",
                                        }}
                                    />
                                    <Button
                                        disabled={disableWrite}
                                        style={{ marginTop: "10px" }}
                                        type="primary"
                                        size="small"
                                        loading={dataLoading}
                                        onClick={async () => {
                                            try {
                                                if (Number(tempChunkId) < 1) {
                                                    message.warn(
                                                        "Invalid Block id",
                                                    );
                                                    return;
                                                }
                                                setDataLoad(true);
                                                let parsedJson =
                                                    await getBlockfromDb(
                                                        Number(tempChunkId),
                                                    );
                                                if (
                                                    parsedJson?.type !== "chunk"
                                                ) {
                                                    message.warn(
                                                        "Invalid Chunk",
                                                    );
                                                    setDataLoad(false);
                                                    return;
                                                }
                                                if (
                                                    worksheet?.type ===
                                                    "personalized_learning_v4"
                                                ) {
                                                    parsedJson =
                                                        convertChunkToV4(
                                                            parsedJson,
                                                        );
                                                    parsedJson = _.set(
                                                        parsedJson,
                                                        ["children"],
                                                        parsedJson?.children?.map(
                                                            (gChild: any) =>
                                                                _.omit(
                                                                    {
                                                                        ...gChild,
                                                                        tmpId: uuid(),
                                                                    },
                                                                    ["id"],
                                                                ),
                                                        ),
                                                    );
                                                } else if (
                                                    worksheet?.type ===
                                                    "personalized_learning_v3"
                                                ) {
                                                    parsedJson =
                                                        convertChunkToV3(
                                                            parsedJson,
                                                        );
                                                }
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "children",
                                                        children?.length,
                                                    ],
                                                    updateKeysAndCopy(
                                                        _.omit(
                                                            {
                                                                ...parsedJson,
                                                                tmpId: uuid(),
                                                            },
                                                            ["id"],
                                                        ),
                                                        true,
                                                    ),
                                                );
                                                setBlock(tmpBlock);
                                                setTempChunkId("");
                                                message.info("Updated!");
                                            } catch (e) {
                                                captureException(e);
                                                message.error(
                                                    "Something went wrong!",
                                                );
                                            }
                                            setDataLoad(false);
                                        }}
                                    >
                                        Update Chunk
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Col>
                    {!disableWrite && (
                        <Col span={12}>
                            <Button
                                style={{
                                    marginBottom: "10px",
                                }}
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(`Copied Level to clipboard!`);

                                    navigator.clipboard.writeText(
                                        JSON.stringify(block),
                                    );
                                }}
                                size="small"
                            >
                                Copy this level to Clipboard
                            </Button>
                            <Form.Item label={"Paste Level Config here"}>
                                <Input.TextArea
                                    disabled={disableWrite}
                                    value={tempChildren}
                                    placeholder="Paste Level Config here"
                                    onChange={(e) =>
                                        setTempChildren(e.target.value)
                                    }
                                    rows={2}
                                    style={{
                                        width: "50%",
                                    }}
                                />
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        try {
                                            let parsedJson =
                                                JSON.parse(tempChildren);
                                            if (
                                                parsedJson?.type !==
                                                PROGRESS_LEVEL_BLOCK
                                            ) {
                                                message.warn("Invalid JSON");
                                                return;
                                            }
                                            if (
                                                worksheet?.type ===
                                                "personalized_learning_v4"
                                            ) {
                                                parsedJson =
                                                    convertLevelToV4(
                                                        parsedJson,
                                                    );
                                            } else if (
                                                worksheet?.type ===
                                                "personalized_learning_v3"
                                            ) {
                                                parsedJson =
                                                    convertLevelToV3(
                                                        parsedJson,
                                                    );
                                            }
                                            let tmpBlock = _.cloneDeep(block);
                                            const tmpChildren = [
                                                ...parsedJson.children,
                                            ];
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["children"],
                                                tmpChildren?.map((child: any) =>
                                                    updateKeysAndCopy(
                                                        _.omit(
                                                            {
                                                                ...child,
                                                                children:
                                                                    child?.children?.map(
                                                                        (
                                                                            gChild: any,
                                                                        ) =>
                                                                            _.omit(
                                                                                gChild,
                                                                                [
                                                                                    "id",
                                                                                    "tmpId",
                                                                                ],
                                                                            ),
                                                                    ),
                                                            },
                                                            [
                                                                "id",
                                                                "worksheet_block_map_id",
                                                                "tmpId",
                                                            ],
                                                        ),
                                                        true,
                                                    ),
                                                ),
                                            );
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["data"],
                                                parsedJson.data,
                                            );
                                            if (
                                                worksheet?.type ===
                                                "personalized_learning_v4"
                                            ) {
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["backend"],
                                                    parsedJson.backend,
                                                );
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["is_hidden"],
                                                    parsedJson.is_hidden,
                                                );
                                            }
                                            setBlock(tmpBlock);
                                            setTempChildren("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e);
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Update Level config
                                </Button>
                            </Form.Item>
                            <Form.Item label={"Paste Level Id here"}>
                                <Input
                                    disabled={disableWrite}
                                    value={tempLevelId}
                                    placeholder="Paste Level Id here"
                                    onChange={(e) =>
                                        setTempLevelId(e.target.value)
                                    }
                                    style={{
                                        width: "50%",
                                    }}
                                />
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    size="small"
                                    loading={dataLoading}
                                    onClick={async () => {
                                        try {
                                            if (Number(tempLevelId) < 1) {
                                                message.warn(
                                                    "Invalid Block id",
                                                );
                                                return;
                                            }
                                            setDataLoad(true);
                                            let parsedJson =
                                                await getBlockfromDb(
                                                    Number(tempLevelId),
                                                );
                                            if (
                                                parsedJson?.type !==
                                                PROGRESS_LEVEL_BLOCK
                                            ) {
                                                setDataLoad(false);
                                                message.warn("Invalid Block");
                                                return;
                                            }
                                            if (
                                                worksheet?.type ===
                                                "personalized_learning_v4"
                                            ) {
                                                parsedJson =
                                                    convertLevelToV4(
                                                        parsedJson,
                                                    );
                                            } else if (
                                                worksheet?.type ===
                                                "personalized_learning_v3"
                                            ) {
                                                parsedJson =
                                                    convertLevelToV3(
                                                        parsedJson,
                                                    );
                                            }
                                            let tmpBlock = _.cloneDeep(block);
                                            const tmpChildren = [
                                                ...parsedJson.children,
                                            ];
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["children"],
                                                tmpChildren?.map((child: any) =>
                                                    updateKeysAndCopy(
                                                        _.omit(
                                                            {
                                                                ...child,
                                                                children:
                                                                    child?.children?.map(
                                                                        (
                                                                            gChild: any,
                                                                        ) =>
                                                                            _.omit(
                                                                                gChild,
                                                                                [
                                                                                    "id",
                                                                                    "tmpId",
                                                                                ],
                                                                            ),
                                                                    ),
                                                            },
                                                            [
                                                                "id",
                                                                "worksheet_block_map_id",
                                                                "tmpId",
                                                            ],
                                                        ),
                                                        true,
                                                    ),
                                                ),
                                            );
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                ["data"],
                                                parsedJson.data,
                                            );
                                            if (
                                                worksheet?.type ===
                                                "personalized_learning_v4"
                                            ) {
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["backend"],
                                                    parsedJson.backend,
                                                );
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    ["is_hidden"],
                                                    parsedJson.is_hidden,
                                                );
                                            }
                                            setBlock(tmpBlock);
                                            setTempLevelId("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            captureException(e);
                                            message.error(
                                                "Something went wrong!",
                                            );
                                        }
                                        setDataLoad(false);
                                    }}
                                >
                                    Update Level
                                </Button>
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                {!disableWrite && (
                    <SortableList
                        items={children}
                        onSortEnd={onSortEnd}
                        axis="xy"
                        useDragHandle
                    />
                )}
                {/* <Divider /> */}
            </Card>
        );
    } else if (!loading && children[currentSubBlock]) {
        if (currentChunkBlock === null) {
            const editorProps = {
                block: children[currentSubBlock],
                setBlock: (subBlock: any) => {
                    let tmpBlock = _.cloneDeep(block);
                    tmpBlock = _.set(
                        tmpBlock,
                        ["children", currentSubBlock],
                        subBlock,
                    );
                    setBlock(tmpBlock);
                },
                currentBlock: currentSubBlock + 1,
                disableWrite,
                isReadOnlyMode,
                ...mentionProps,
                setCurrentEditor,
                currentEditor,
                worksheet,
                levelId: block?.id,
            };
            return <ChunkEditor {...editorProps} />;
        } else {
            let chunkChildren =
                worksheet?.type === "personalized_learning_v4"
                    ? children[currentSubBlock]?.children
                    : children[currentSubBlock]?.data?.chunk?.blocks;
            const chunkSlug =
                worksheet?.type === "personalized_learning_v4"
                    ? children[currentSubBlock]?.backend?.slug
                    : children[currentSubBlock]?.data?.slug;

            if (!loading && chunkChildren?.[currentChunkBlock]) {
                const editorProps = {
                    chunkSlug,
                    blocks: chunkChildren,
                    block: chunkChildren?.[currentChunkBlock],
                    type: chunkChildren?.[currentChunkBlock]?.type,
                    setBlock: (subBlock: any) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock = _.set(
                            tmpBlock,
                            worksheet?.type === "personalized_learning_v4"
                                ? [
                                      "children",
                                      currentSubBlock,
                                      "children",
                                      currentChunkBlock,
                                  ]
                                : [
                                      "children",
                                      currentSubBlock,
                                      "data",
                                      "chunk",
                                      "blocks",
                                      currentChunkBlock,
                                  ],
                            subBlock,
                        );
                        setBlock(tmpBlock);
                    },
                    isEditor,
                    setIsEditor,
                    currentBlock: currentChunkBlock + 1,
                    isNewBlock,
                    setIsNewBlock,
                    worksheet,
                    toggleShouldBlockUI,
                    onInputVariableUpdate,
                    disableWrite,
                    previewData: {
                        progression_id: block?.id,
                    },
                    isReadOnlyMode,
                    ...mentionProps,
                    mentionsList: [
                        ...((worksheet?.type === "personalized_learning_v4"
                            ? chunkChildren?.[currentChunkBlock]?.backend
                            : chunkChildren?.[currentChunkBlock]?.data
                                  ?.all_in_one
                        )?.question_variables?.map(
                            (item: { name: any }, i) => ({
                                key: i,
                                text: item.name,
                                data: item.name,
                            }),
                        ) || []),
                        ...((worksheet?.type === "personalized_learning_v4"
                            ? chunkChildren?.[currentChunkBlock]?.backend
                            : chunkChildren?.[currentChunkBlock]?.data
                                  ?.all_in_one
                        )?.question_derived_variables?.map(
                            (item: { name: any }, i) => ({
                                key: i,
                                text: item.name,
                                data: item.name,
                            }),
                        ) || []),
                        ...mentionProps?.mentionsList,
                    ],
                };

                return <CustomEditor {...editorProps} />;
            }
        }
    }
    return <></>;
};

export default DefaultEditor;
