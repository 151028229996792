import React, { Component, useState } from "react";
import {
    Col,
    Form,
    Row,
    Switch,
    InputNumber,
    Card,
    Button,
    Input,
    Select,
    Space,
    Tag,
} from "antd";
import _, { cloneDeep, set } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { LogicModal } from "../../../AllInOne/renderEditor/ComponentsEditor/common/LogicModal";
import { FeedbackPool } from "../../../AllInOne/renderEditor/ComponentsEditor/common/FeedbackPool";

const Gamify = ({
    block,
    setBlock,
    disableWrite,
    hasMentions,
    mentionsList = [],
    currentEditor,
    setCurrentEditor,
}: any) => {
    console.log("block", block);

    const [openFeedbackModal, setOpenFeedbackModal] = useState("");
    const [GamifyElementsInput, setGamifyElementsInput] = useState("");
    const [GamifyTargetElementsInput, setGamifyTargetElementsInput] =
        useState("");

    if (!block?.gamifyElements || !block?.gamifyTargetElements) {
        const tmpBlock = _.cloneDeep(block);
        !block?.gamifyElements ? (tmpBlock.gamifyElements = []) : null;
        !block?.gamifyTargetElements
            ? (tmpBlock.gamifyTargetElements = [])
            : null;
        setBlock(tmpBlock);
    }

    if (
        !block?.gamifyFeedbackFunctions ||
        block?.gamifyFeedbackFunctions?.length === 0
    ) {
        let tmpBlock = _.cloneDeep(block);

        tmpBlock.gamifyFeedbackFunctions = [
            {
                name: "End Audio",
                output: "",
                params: ["tableData"],
                isGlobal: true,
                label: "End Audio",
            },
            {
                name: "End Feedback",
                output: "",
                params: ["tableData"],
                isGlobal: true,
                label: "End Feedback",
            },
            {
                name: "Story",
                output: "",
                params: ["tableData"],
                isGlobal: true,
                label: "Story",
            },
            {
                name: "EndEffects",
                output: "",
                params: ["tableData"],
                isGlobal: true,
                label: "End Effects",
            },
        ];
        setBlock(tmpBlock);
    }

    if (!block?.gamifyFeedbackPool) {
        const tmpBlock = _.cloneDeep(block);
        tmpBlock.gamifyFeedbackPool = {
            name: "feedback",
            type: "GLOBAL",
            label: "Feedback",
            value: "[]",
            schema: {
                name: "",
                text: [],
                duration: 5,
            },
            objectType: "FEEDBACK_POOL",
        };
        setBlock(tmpBlock);
    }
    return (
        <Card title={<h3>Gamify</h3>}>
            <Row>
                <Form.Item label={"Enable"}>
                    <Switch
                        disabled={disableWrite}
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={block?.table_color_settings?.enabled}
                        onChange={(value) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(tmpBlock, ["isGame"], value);
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
                <Col span={24}>
                    <LogicModal
                        disableWrite={disableWrite}
                        computeFUnctions={block?.gamifyFeedbackFunctions}
                        onSave={(val: any) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["gamifyFeedbackFunctions"],
                                val,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Col>

                {openFeedbackModal && openFeedbackModal !== "" && (
                    <FeedbackPool
                        isModalOpen={true}
                        setIsModalOpen={(value: boolean) => {
                            setOpenFeedbackModal(
                                value === true ? openFeedbackModal : "",
                            );
                        }}
                        name={openFeedbackModal}
                        feedbackPool={block?.gamifyFeedbackPool}
                        disableWrite={disableWrite}
                        onSave={(value: any) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["gamifyFeedbackPool"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        currentEditor={currentEditor}
                        setCurrentEditor={setCurrentEditor}
                    />
                )}

                <Form.Item label="Feedback Pool">
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpenFeedbackModal("feedback");
                            }}
                            size="small"
                        >
                            Feedback Pool
                        </Button>
                    </Space>
                </Form.Item>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Form.Item
                    label="Gamify Elements"
                    style={{
                        marginTop: "20px",
                    }}
                >
                    {block?.gamifyElements?.map((tag: any, i: number) => {
                        return (
                            <Tag
                                closable
                                onClose={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const tmpBlock = _.cloneDeep(block);
                                    tmpBlock.gamifyElements =
                                        tmpBlock.gamifyElements.filter(
                                            (t: any, ti: number) => ti !== i,
                                        );
                                    setBlock(tmpBlock);
                                }}
                            >
                                {tag}
                            </Tag>
                        );
                    })}
                    <Input
                        disabled={disableWrite}
                        type="text"
                        style={{ width: 78 }}
                        value={GamifyElementsInput}
                        onChange={(e) =>
                            setGamifyElementsInput(e?.target?.value)
                        }
                        onPressEnter={() => {
                            const tmpBlock = _.cloneDeep(block);
                            tmpBlock.gamifyElements = [
                                ...tmpBlock?.gamifyElements,
                                GamifyElementsInput,
                            ];
                            setBlock(tmpBlock);
                            setGamifyElementsInput("");
                        }}
                    />
                </Form.Item>
            </Row>
            <Row style={{ marginTop: "8px" }}>
                <Form.Item
                    label="Target Elements"
                    style={{
                        marginTop: "20px",
                    }}
                >
                    {block?.gamifyTargetElements?.map((tag: any, i: number) => {
                        return (
                            <Tag
                                closable
                                onClose={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    const tmpBlock = _.cloneDeep(block);
                                    tmpBlock.gamifyTargetElements =
                                        tmpBlock.gamifyTargetElements.filter(
                                            (t: any, ti: number) => ti !== i,
                                        );
                                    setBlock(tmpBlock);
                                }}
                            >
                                {tag}
                            </Tag>
                        );
                    })}
                    <Input
                        disabled={disableWrite}
                        type="text"
                        style={{ width: 78 }}
                        value={GamifyTargetElementsInput}
                        onChange={(e) =>
                            setGamifyTargetElementsInput(e?.target?.value)
                        }
                        onPressEnter={() => {
                            const tmpBlock = _.cloneDeep(block);
                            tmpBlock.gamifyTargetElements = [
                                ...tmpBlock?.gamifyTargetElements,
                                GamifyTargetElementsInput,
                            ];
                            setBlock(tmpBlock);
                            setGamifyTargetElementsInput("");
                        }}
                    />
                </Form.Item>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label={"Effects"}>
                        <Button
                            ghost
                            type="primary"
                            size="small"
                            style={{
                                marginBottom: "10px",
                            }}
                            disabled={disableWrite}
                            onClick={() => {
                                let tmpBlock = _.cloneDeep(block);
                                if (tmpBlock.gamifyFunctions === undefined) {
                                    tmpBlock.gamifyFunctions = [
                                        {
                                            type: "",
                                            computeFunction: { output: "" },
                                        },
                                    ];
                                } else {
                                    tmpBlock.gamifyFunctions.push({
                                        type: "",
                                        computeFunction: { output: "" },
                                    });
                                }
                                setBlock(tmpBlock);
                            }}
                        >
                            Add new Gamify Function
                        </Button>
                        {block?.gamifyFunctions?.map((v: any, key: number) => (
                            <div
                                style={{
                                    display: "flex",
                                    // flexDirection: "column",
                                    gap: "1rem",
                                }}
                            >
                                <Form.Item
                                    label={"Select execution type"}
                                    style={{
                                        padding: "0rem 1rem",
                                        width: "100%",
                                    }}
                                >
                                    <Select
                                        value={v?.type}
                                        placeholder="execution type"
                                        options={[
                                            {
                                                label: "SetTimeOut",
                                                value: "SET_TIME_OUT",
                                            },
                                            {
                                                label: "SetInterval",
                                                value: "SET_INTERVAL",
                                            },
                                        ]}
                                        style={{
                                            flexGrow: 0,
                                        }}
                                        onChange={(val) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock.gamifyFunctions[key].type =
                                                val;
                                            setBlock(tmpBlock);
                                        }}
                                        disabled={disableWrite}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={"Duration (ms)"}
                                    style={{
                                        padding: "0rem 1rem",
                                        width: "100%",
                                    }}
                                >
                                    <Input
                                        key={key}
                                        disabled={disableWrite}
                                        value={v.duration}
                                        placeholder="1000"
                                        onChange={(e) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock.gamifyFunctions[
                                                key
                                            ].duration = e.target.value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={"Function"}
                                    style={{
                                        padding: "0rem 1rem",
                                        width: "100%",
                                    }}
                                >
                                    <Input.TextArea
                                        key={key}
                                        disabled={disableWrite}
                                        value={v.computeFunction.output}
                                        placeholder="computeFunction"
                                        onChange={(e) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock.gamifyFunctions[
                                                key
                                            ].computeFunction.output =
                                                e.target.value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </Form.Item>

                                <Button
                                    disabled={disableWrite}
                                    icon={<DeleteOutlined />}
                                    type="primary"
                                    shape="circle"
                                    danger
                                    size="small"
                                    style={{ marginTop: "50px" }}
                                    onClick={() => {
                                        let tmpBlock = _.cloneDeep(block);
                                        tmpBlock.gamifyFunctions =
                                            tmpBlock.gamifyFunctions.filter(
                                                (v: any, index: number) =>
                                                    index !== key,
                                            );
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </div>
                        ))}
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};

export default Gamify;
