export function generatePuzzleCellsNonDrawing(pieces, tableData) {
    // Initialize a 2D array of nulls based on tableData dimensions
    let puzzleGrid = Array.from({ length: tableData.cells.length }, () =>
        Array(tableData.cells[0].length).fill(null),
    );

    // Mark coordinates in the puzzleGrid based on the pieces array
    pieces.forEach(({ rowIndex, colIndex }) => {
        puzzleGrid[rowIndex][colIndex] = true;
    });

    const firstCellBorder = tableData.cells[0][0].border;
    const tableBorder = tableData.border;

    // Determine border settings
    const borderSettings = firstCellBorder?.enabled
        ? firstCellBorder
        : tableBorder?.enabled
        ? tableBorder
        : {
              enabled: true,
              opacity: 1,
              width: 1,
              radius: 0,
              style: "SOLID",
          };

    // Populate cells based on schema and pieces array
    puzzleGrid = puzzleGrid.map((row, rowIndex) =>
        row.map((isPiece, colIndex) =>
            isPiece
                ? {
                      content: {
                          ...tableData.cells[0][0], // Use the schema from tableData
                          hidden: false,
                          id: `${rowIndex}-${colIndex}-${Math.random()
                              .toString(36)
                              .substring(2)}`,
                          text: {
                              ...tableData.cells[0][0].text,
                              value: { default: `${rowIndex}-${colIndex}` },
                          },
                          minHeight: tableData.minHeight[0], // Set minHeight directly
                          minMaxWidth: tableData.minMaxWidth[0], // Set minMaxWidth directly
                          dragDrop: {
                              enabled: false,
                          },
                          border: {
                              ...borderSettings,
                          },
                      },
                  }
                : null,
        ),
    );

    // Remove rows that are entirely null
    puzzleGrid = puzzleGrid.filter((row) => row.some((cell) => cell !== null));

    // Remove columns that are entirely null by checking column-by-column
    let numCols = puzzleGrid[0]?.length || 0;
    for (let col = numCols - 1; col >= 0; col--) {
        // Traverse columns from the end to avoid index shift
        if (puzzleGrid.every((row) => row[col] === null)) {
            puzzleGrid.forEach((row) => row.splice(col, 1));
        }
    }

    return puzzleGrid;
}

export function generatePuzzlePiecesDrawingData(pieces: any[], tableData: any) {
    const indices = pieces.map(
        ({ rowIndex, colIndex }: { rowIndex: number; colIndex: number }) =>
            colIndex,
    );
    const cells = tableData.cells[0];
    // Select cells based on the provided indices and create deep copies
    let selectedCells = indices.map((index) => {
        // Deep clone the cell to avoid mutating the original
        return JSON.parse(JSON.stringify(cells[index]));
    });
    // Initialize minX and minY with Infinity to find the smallest values
    let minX = Infinity;
    let minY = Infinity;

    // Initialize maxX and maxY to compute overall dimensions
    let maxX = -Infinity;
    let maxY = -Infinity;

    // Find the minimum and maximum x and y values among the selected cells
    selectedCells.forEach((cell) => {
        let x = cell.drawing.position.x;
        let y = cell.drawing.position.y;
        let width = cell.drawing.dimension.width;
        let height = cell.drawing.dimension.height;

        // Update minX and minY
        if (x < minX) minX = x;
        if (y < minY) minY = y;

        // Update maxX and maxY
        if (x + width > maxX) maxX = x + width;
        if (y + height > maxY) maxY = y + height;
    });

    // Adjust the positions so that the smallest x and y start from 0, 0
    selectedCells.forEach((cell) => {
        cell.drawing.position.x -= minX;
        cell.drawing.position.y -= minY;
    });

    // Adjust neighbors according to the sequence in indices
    for (let k = 0; k < selectedCells.length; k++) {
        let cell = selectedCells[k];
        // If not the last cell, set neighbor to next cell in sequence
        if (k < selectedCells.length - 1) {
            let nextCell = selectedCells[k + 1];

            // Calculate dx and dy between current cell and next cell
            let dx = nextCell.drawing.position.x - cell.drawing.position.x;
            let dy = nextCell.drawing.position.y - cell.drawing.position.y;

            // Set neighbors to include only the next cell
            cell.drawing.neighbors = [{ dx: dx, dy: dy }];
        } else {
            // Last cell has no neighbors
            cell.drawing.neighbors = [];
        }
    }

    // Now, for each cell, create an object matching the default schema provided
    let output = selectedCells.map((cell) => {
        // Create a copy of the default schema
        let obj = {
            angle: 0,
            content: {
                id: "",
                text: {
                    color: {
                        default: "#000000",
                    },
                    value: {
                        default: "4",
                    },
                    enabled: true,
                    fontSize: {
                        default: 16,
                    },
                    alignment: {
                        default: "CENTER",
                    },
                },
                align: "CENTER",
                image: {
                    src: {
                        default: "",
                    },
                    enabled: false,
                },
                input: {
                    max: "",
                    min: "0",
                    fill: "#ffffff",
                    default: "",
                    enabled: false,
                    max_feedback_text: "",
                    min_feedback_text: "",
                    max_feedback_audio: "",
                    min_feedback_audio: "",
                },
                border: {
                    enabled: false,
                },
                dropdown: {
                    default: "",
                    enabled: false,
                    optionVariable: "",
                },
                tappable: {
                    default: {
                        selected: "0",
                    },
                    enabled: false,
                },
                clickable: {
                    enabled: false,
                },
                fillColor: {
                    default: "#CDC1FF",
                    enabled: true,
                },
            },
        };

        // Insert the adjusted drawing data into "content"
        obj.content.id = cell.id;
        obj.content.drawing = cell.drawing;

        return obj;
    });

    // Compute the overall width and height required to render the cells
    let totalWidth = maxX - minX;
    let totalHeight = maxY - minY;

    console.log("Total width required:", totalWidth);
    console.log("Total height required:", totalHeight);

    return { cells: output, width: totalWidth, height: totalHeight };
}
