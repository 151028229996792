// IrregularHexagon.jsx
import React from "react";

const IrregularHexagon = ({
    points,
    stroke = "gray",
    strokeWidth = 0,
    strokeType = "dashed", // New prop for stroke type
    strokeOpacity = 1,
    fill = "none",
    sizeStyles,
    type = "polygon",
    zIndex = 5,
    drawing,
}) => {
    // Calculate the additional space needed to prevent stroke clipping
    const halfStroke = strokeWidth / 2;
    const adjustedViewBoxSize = 100 + halfStroke / 2; // As per the note

    // Determine the stroke-dasharray based on strokeType
    let strokeDasharray;
    if (strokeType === "dashed") {
        strokeDasharray = `${strokeWidth * 2}, ${strokeWidth}`;
    } else {
        strokeDasharray = "none"; // Solid stroke
    }

    // take difference of height width

    const shape = (function getShape() {
        if (type === "Circle") {
            return (
                <circle
                    cx={adjustedViewBoxSize / 2}
                    cy={adjustedViewBoxSize / 2}
                    r={adjustedViewBoxSize / 2}
                    fill={fill}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeOpacity={strokeOpacity}
                />
            );
        }
        if (
            type === "Polygon" ||
            type === "Triangle" ||
            type === "Hexagon" ||
            type === "Pentagon"
        ) {
            return (
                <polygon
                    points={points?.replaceAll("%", "")} // Remove % from points
                    fill={fill}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeOpacity={strokeOpacity}
                />
            );
        }
        if (type === "Ellipse") {
            return (
                <ellipse
                    cx={adjustedViewBoxSize / 2}
                    cy={adjustedViewBoxSize / 2}
                    rx={adjustedViewBoxSize / 2}
                    ry={adjustedViewBoxSize / 2}
                    fill={fill}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeOpacity={strokeOpacity}
                />
            );
        }
        if (type === "Rectangle") {
            return (
                <rect
                    width={adjustedViewBoxSize}
                    height={adjustedViewBoxSize}
                    fill={fill}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeOpacity={strokeOpacity}
                />
            );
        }
    })();

    return (
        <svg
            className="background-svg"
            viewBox={`0 0 ${adjustedViewBoxSize} ${adjustedViewBoxSize}`}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            style={{
                overflow: "visible",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: zIndex,
                pointerEvents: "none",
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
            }}
        >
            {shape}
        </svg>
    );
};

export default IrregularHexagon;
