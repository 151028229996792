import React, { useEffect, useState } from "react";
import CollectorCustomizer from "./Collector";
import GameConfigs from "./GameConfigs";
import Items from "./Items";

function SurferGameEditor(props: any) {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
        renderLinkSelect,
    } = props;

    useEffect(() => {
        if (!block.config || !block.functions) {
            setBlock({
                ...block,
                config: block.config || block.data.config,
                functions: block.data.functions,
            });
        }
    }, [block]);

    if (!block.config || !block.functions) {
        return null;
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px",
            }}
        >
            <GameConfigs
                config={{
                    max_items: block.config?.max_items || 0,
                    item_speed: block.config?.item_speed || 0,
                    lives: block.config?.lives || 0,
                    feedback_pool: block.config?.feedback_pool || [],
                    linked_global_context_variable:
                        block?.linked_global_context_variable?.name || null,
                    value: block?.value || null,
                    backpress_action: block.config?.backpress_action || "PAUSE",
                    start_button_name:
                        block.config?.start_button_name || "Start now!",
                    on_start_audio: block.config?.on_start_audio || "",
                    on_start_feedback: block.config?.on_start_feedback || "",
                }}
                setConfig={(v: any) => {
                    setBlock({
                        ...block,
                        config: {
                            ...(block.config || {}),
                            max_items: v.max_items,
                            item_speed: v.item_speed,
                            lives: v.lives,
                            feedback_pool: v.feedback_pool,
                            backpress_action: v.backpress_action,
                            start_button_name: v.start_button_name,
                            on_start_audio: v.on_start_audio,
                            on_start_feedback: v.on_start_feedback,
                        },
                        linked_global_context_variable: {
                            name: v.linked_global_context_variable,
                        },
                        value: v.value,
                    });
                }}
                functions={block?.functions}
                setFunctions={(v: any) => {
                    setBlock({
                        ...block,
                        functions: v,
                    });
                }}
                renderLinkSelect={renderLinkSelect}
                mentionsList={mentionsList}
            />
            <CollectorCustomizer
                collector={block.config?.collector || {}}
                onCollectorChange={(v: any) => {
                    setBlock({
                        ...block,
                        config: {
                            ...(block.config || {}),
                            collector: v,
                        },
                    });
                }}
            />
            <Items
                items={block.config?.item_types}
                setItems={(v: any) => {
                    const updatedV = v.map((item: any, id) => ({
                        ...item,
                        id,
                        position: {
                            x: 0,
                            y: 0,
                        },
                    }));
                    setBlock({
                        ...block,
                        config: {
                            ...(block.config || {}),
                            item_types: updatedV,
                        },
                    });
                }}
            />
        </div>
    );
}

export default SurferGameEditor;
