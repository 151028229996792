import React, { useEffect, useMemo, useState } from "react";
import DroppedItem from "./DroppedItem";
import { getBackgroundColor } from "./helpers";

const getDropAreaUpdatedStyles = (dragDropObj) => {
    const dimensionControl = {
        enabled: dragDropObj?.dimensionControl?.enabled,
        minWidth: Number(dragDropObj?.dimensionControl?.minWidth),
        minHeight: Number(dragDropObj?.dimensionControl?.minHeight),
        maxHeight: Number(dragDropObj?.dimensionControl?.maxHeight),
        maxWidth: Number(dragDropObj?.dimensionControl?.maxWidth),
    };
    if (dimensionControl?.enabled) {
        return {
            parentStyles: {
                minWidth: dimensionControl.minWidth,
                minHeight: dimensionControl.minHeight,
                maxWidth: dimensionControl.maxWidth,
                maxHeight: dimensionControl.maxHeight,
            },
            childStyles: {
                minWidth: dimensionControl.minWidth - 8,
                minHeight: dimensionControl.minHeight - 16,
            },
        };
    } else {
        return {
            parentStyles: {},
            childStyles: {},
        };
    }
};

const DropAreaComponent = ({
    dragDropObj,
    rowIndex,
    cellIndex,
    cellFillColorStyle,
    shapeStyles,
    isDrawing,
}) => {
    const [droppedItem, setDroppedItem] = useState(dragDropObj?.droppedSource);
    useEffect(() => {
        if (dragDropObj?.droppedSource) {
            setDroppedItem(dragDropObj?.droppedSource);
        } else {
            setDroppedItem(null);
        }
    }, [dragDropObj?.droppedSource?.genericId]);

    const backgroundColor = dragDropObj?.fillIndex
        ? getBackgroundColor(dragDropObj.fillIndex)
        : dragDropObj?.takeCellBackground
        ? cellFillColorStyle?.backgroundColor
        : getBackgroundColor(0);

    const { parentStyles, childStyles } = useMemo(() => {
        return getDropAreaUpdatedStyles(dragDropObj);
    }, [dragDropObj]);

    const drawingSpecificStyles = isDrawing
        ? {
              padding: "0px",
              boxShadow: "none",
              background: "transparent",
              border: "none",
              minWidth: "10px",
          }
        : {};
    return (
        <div
            className="renderer-table-drag-drop-area-wrapper"
            style={{
                backgroundColor,
                ...parentStyles,
                borderOpacity: dragDropObj?.takeCellBackground ? 1 : 0.5,
                ...drawingSpecificStyles,
            }}
        >
            {droppedItem && (
                <DroppedItem
                    droppedSource={droppedItem}
                    index1={rowIndex}
                    index2={cellIndex}
                    setDroppedItem={setDroppedItem}
                    customStyles={childStyles}
                    shapeStyles={shapeStyles}
                    isDrawing={isDrawing}
                />
            )}
        </div>
    );
};

export default DropAreaComponent;
