import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import ComponentDetails from "../common/ComponentDetails";
import { LogicModal } from "../common/LogicModal";
import { DeleteOutlined } from "@ant-design/icons";
import _ from "lodash";

const GenericRendererEditor = (props: any) => {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
        renderLinkSelect,
    } = props;

    return (
        <div
            style={{
                paddingBottom: "10px",
                display: "flex",
            }}
        >
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
                title={<h3>Configurations</h3>}
            >
                <ComponentDetails
                    block={block}
                    setBlock={setBlock}
                    disableWrite={disableWrite}
                />

                <Form.Item
                    label="Default Value"
                    style={{
                        marginTop: "20px",
                    }}
                >
                    <Input.TextArea
                        disabled={disableWrite}
                        // style={{ width: 78 }}
                        value={block?.data?.default_value}
                        onChange={(e) => {
                            const tmpBlock = _.cloneDeep(block);
                            tmpBlock.data.default_value = e.target.value;
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={"Type"}
                    style={{
                        padding: "0rem 1rem",
                    }}
                >
                    <Select
                        value={block?.data?.type}
                        placeholder="Select type"
                        options={[
                            {
                                label: "string",
                                value: "STRING",
                            },
                            {
                                label: "html",
                                value: "HTML",
                            },
                            {
                                label: "score board",
                                value: "SCORE_BOARD",
                            },
                        ]}
                        style={{
                            flexGrow: 0,
                        }}
                        onChange={(val: any) => {
                            const tmpBlock = _.cloneDeep(block);
                            tmpBlock.data.type = val;
                            setBlock(tmpBlock);
                        }}
                        disabled={disableWrite}
                    />
                </Form.Item>
                <Row>
                    <Col span={24}>
                        <Form.Item label={"Effects"}>
                            <Button
                                ghost
                                type="primary"
                                size="small"
                                style={{
                                    marginBottom: "10px",
                                }}
                                disabled={disableWrite}
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(block);
                                    if (tmpBlock.data.effects === undefined) {
                                        tmpBlock.data.effects = [
                                            {
                                                event: "",
                                                computeFunction: { output: "" },
                                            },
                                        ];
                                    } else {
                                        tmpBlock.data.effects.push({
                                            event: "",
                                            computeFunction: { output: "" },
                                        });
                                    }
                                    setBlock(tmpBlock);
                                }}
                            >
                                Add New Table Effects
                            </Button>
                            {block?.data?.effects?.map(
                                (v: any, key: number) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "1rem",
                                        }}
                                    >
                                        <Input
                                            key={key}
                                            disabled={disableWrite}
                                            value={v.event}
                                            placeholder="event"
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.effects[
                                                    key
                                                ].event = e.target.value;
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                        <Input.TextArea
                                            key={key}
                                            disabled={disableWrite}
                                            value={v.computeFunction.output}
                                            placeholder="computeFunction"
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.effects[
                                                    key
                                                ].computeFunction.output =
                                                    e.target.value;
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                        <Button
                                            disabled={disableWrite}
                                            icon={<DeleteOutlined />}
                                            type="primary"
                                            shape="circle"
                                            danger
                                            size="small"
                                            onClick={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.effects =
                                                    tmpBlock.data.effects.filter(
                                                        (
                                                            v: any,
                                                            index: number,
                                                        ) => index !== key,
                                                    );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </div>
                                ),
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={"Mount functions"}>
                            <Button
                                ghost
                                type="primary"
                                size="small"
                                style={{
                                    marginBottom: "10px",
                                }}
                                disabled={disableWrite}
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(block);
                                    if (
                                        tmpBlock.data.mountFunctions ===
                                        undefined
                                    ) {
                                        tmpBlock.data.mountFunctions = [
                                            {
                                                event: "",
                                                computeFunction: { output: "" },
                                            },
                                        ];
                                    } else {
                                        tmpBlock.data.mountFunctions.push({
                                            event: "",
                                            computeFunction: { output: "" },
                                        });
                                    }
                                    setBlock(tmpBlock);
                                }}
                            >
                                Add New Table Effects
                            </Button>
                            {block?.data?.mountFunctions?.map(
                                (v: any, key: number) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "1rem",
                                        }}
                                    >
                                        <Input.TextArea
                                            key={key}
                                            disabled={disableWrite}
                                            value={v.computeFunction.output}
                                            placeholder="computeFunction"
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.mountFunctions[
                                                    key
                                                ].computeFunction.output =
                                                    e.target.value;
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                        <Button
                                            disabled={disableWrite}
                                            icon={<DeleteOutlined />}
                                            type="primary"
                                            shape="circle"
                                            danger
                                            size="small"
                                            onClick={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock.data.mountFunctions =
                                                    tmpBlock.data.mountFunctions.filter(
                                                        (
                                                            v: any,
                                                            index: number,
                                                        ) => index !== key,
                                                    );
                                                setBlock(tmpBlock);
                                            }}
                                        />
                                    </div>
                                ),
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default GenericRendererEditor;
