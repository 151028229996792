import React, { useEffect, useState } from "react";
import {
    Select,
    InputNumber,
    Input,
    Form,
    Card,
    Row,
    Col,
    Divider,
    message,
    Button,
} from "antd";
import { RenderUpload } from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import { cloneDeep } from "lodash";
import { BlockPicker } from "react-color";

function drawRoundedRect(ctx, x, y, width, height, radii, color) {
    // Ensure all radii are defined, or fallback to 0
    const topLeft = radii.topLeft || 0;
    const topRight = radii.topRight || 0;
    const bottomRight = radii.bottomRight || 0;
    const bottomLeft = radii.bottomLeft || 0;

    ctx.fillStyle = color;
    ctx.beginPath();

    // Start at the top-left corner
    ctx.moveTo(x + topLeft, y);

    // Top edge and top-right corner
    ctx.lineTo(x + width - topRight, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + topRight);

    // Right edge and bottom-right corner
    ctx.lineTo(x + width, y + height - bottomRight);
    ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - bottomRight,
        y + height,
    );

    // Bottom edge and bottom-left corner
    ctx.lineTo(x + bottomLeft, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - bottomLeft);

    // Left edge and top-left corner
    ctx.lineTo(x, y + topLeft);
    ctx.quadraticCurveTo(x, y, x + topLeft, y);

    ctx.closePath();
    ctx.fill();
}

const { Option } = Select;

const CollectorCustomizer = ({ collector, onCollectorChange }) => {
    const [collectorType, setCollectorType] = useState(
        collector.type || "circle",
    );
    const [shape, setShape] = useState(
        collector.shape || { width: 50, height: 50 },
    );
    const [radius, setRadius] = useState(collector.radius || 25);
    const [color, setColor] = useState(collector.color || "cyan");
    const [src, setSrc] = useState(collector.src || "");
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const [tempConfig, setTempConfig] = useState("");
    const [borderRadius, setBorderRadius] = useState({
        topLeft: 0,
        topRight: 0,
        bottomRight: 0,
        bottomLeft: 0,
    });
    const [text, setText] = useState("");
    const [font, setFont] = useState({
        size: 16,
        weight: 500,
        color: "white",
    });

    useEffect(() => {
        onCollectorChange({
            type: collectorType,
            shape,
            color: collectorType !== "image" ? color : undefined,
            ...(collectorType === "circle" ? { radius } : {}),
            position: { x: 0, y: 0 },
            src: collectorType === "image" ? src : undefined,
            ...(collectorType === "rectangle" ? { borderRadius } : {}),
            font,
            text,
        }); // Initial call to set default values
    }, [collectorType, shape, radius, color, src, borderRadius, text, font]);

    const handleTypeChange = (value) => {
        setCollectorType(value);
    };

    const handleShapeChange = (key, value) => {
        const newShape = { ...shape, [key]: value };
        setShape(newShape);
    };

    const handleRadiusChange = (value) => {
        setRadius(value);
    };

    const handleSrcChange = (value) => {
        setSrc(value);
    };

    const handleBorderRadiusChange = (key, value) => {
        setBorderRadius({ ...borderRadius, [key]: value });
    };

    const handleFontChange = (key, value) => {
        setFont((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    // Draw preview of collector
    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        const renderImage = (img, src, width, height) => {
            ctx.drawImage(
                img,
                canvasRef.current.width / 2 - width / 2,
                canvasRef.current.height / 2 - height / 2,
                width,
                height,
            );
        };

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = color;

        const textPosition = {
            x: 0,
            y: 0,
        };

        if (collectorType === "circle") {
            ctx.beginPath();
            const x = canvas.width / 2;
            const y = canvas.height / 2;
            ctx.arc(x, y, radius, 0, Math.PI * 2);
            ctx.fill();
            textPosition.x = x;
            textPosition.y = y;
        } else if (collectorType === "rectangle") {
            // ctx.fillRect(
            //     canvas.width / 2 - shape.width / 2,
            //     canvas.height / 2 - shape.height / 2,
            //     shape.width,
            //     shape.height,
            // );
            const x = canvas.width / 2 - shape.width / 2;
            const y = canvas.height / 2 - shape.height / 2;
            drawRoundedRect(
                ctx,
                x,
                y,
                shape.width,
                shape.height,
                borderRadius,
                color,
            );
            textPosition.x = x + shape.width / 2;
            textPosition.y = y + shape.height / 2;
        } else if (collectorType === "triangle") {
            const { width, height } = shape;
            const x = canvas.width / 2;
            const y = canvas.height / 2 - height / 2;
            ctx.beginPath();
            ctx.moveTo(x, y); // Top
            ctx.lineTo(
                canvas.width / 2 - width / 2,
                canvas.height / 2 + height / 2,
            ); // Bottom left
            ctx.lineTo(
                canvas.width / 2 + width / 2,
                canvas.height / 2 + height / 2,
            ); // Bottom right
            ctx.closePath();
            ctx.fill();
            textPosition.x = x;
            textPosition.y = y + height / 2;
        } else if (collectorType === "image") {
            setText("");
            const img = new Image(shape.width, shape.height);
            img.src = src;
            img.onload = () => {
                ctx.drawImage(
                    img,
                    canvas.width / 2 - shape.width / 2,
                    canvas.height / 2 - shape.height / 2,
                    shape.width,
                    shape.height,
                );
                setTimeout(() => {
                    requestAnimationFrame(() => {
                        renderImage(img, src, shape.width, shape.height);
                    });
                }, 1000);
            };
        }
        if (text) {
            ctx.fillStyle = `${font?.color || "white"}`;
            ctx.font = `${font?.weight || 500} ${font?.size || 16}px Epilogue`;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(text, textPosition.x, textPosition.y);
        }
    }, [collectorType, shape, radius, color, src, borderRadius, font, text]);

    return (
        <Card
            title="Customize Collector"
            bordered={true}
            style={{
                // width: 400,
                width: "90%",
                margin: "0 auto",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                borderRadius: "10px",
                padding: "20px",
            }}
            headStyle={{ textAlign: "center", fontSize: "18px" }}
        >
            <Form
                layout="vertical"
                style={{
                    display: "flex",
                    gap: "10px",
                }}
            >
                {/* Collector Type Dropdown */}
                <Form.Item label="Collector Configs" style={{ flex: 1 }}>
                    <Form.Item
                        label="Collector Type"
                        style={{ textAlign: "center" }}
                    >
                        <Select
                            value={collectorType}
                            onChange={handleTypeChange}
                            style={{ width: "100%" }}
                        >
                            <Option value="circle">Circle</Option>
                            <Option value="rectangle">Rectangle</Option>
                            <Option value="triangle">Triangle</Option>
                            <Option value="image">Image</Option>
                        </Select>
                    </Form.Item>
                    {/* Dynamic Form Fields */}
                    {collectorType === "circle" ? (
                        <Form.Item label="Radius">
                            <InputNumber
                                min={10}
                                max={200}
                                value={radius}
                                onChange={handleRadiusChange}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    ) : (
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Width">
                                    <InputNumber
                                        min={10}
                                        max={200}
                                        value={shape.width}
                                        onChange={(value) =>
                                            handleShapeChange("width", value)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Height">
                                    <InputNumber
                                        min={10}
                                        max={200}
                                        value={shape.height}
                                        onChange={(value) =>
                                            handleShapeChange("height", value)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {collectorType === "rectangle" && (
                        <Card
                            title="Border Radius"
                            style={{ border: "1px solid black" }}
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Top Left">
                                        <InputNumber
                                            min={0}
                                            value={borderRadius.topLeft}
                                            onChange={(value) =>
                                                handleBorderRadiusChange(
                                                    "topLeft",
                                                    value,
                                                )
                                            }
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Top Right">
                                        <InputNumber
                                            min={0}
                                            value={borderRadius.topRight}
                                            onChange={(value) =>
                                                handleBorderRadiusChange(
                                                    "topRight",
                                                    value,
                                                )
                                            }
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Bottom Right">
                                        <InputNumber
                                            min={0}
                                            value={borderRadius.bottomRight}
                                            onChange={(value) =>
                                                handleBorderRadiusChange(
                                                    "bottomRight",
                                                    value,
                                                )
                                            }
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Bottom Left">
                                        <InputNumber
                                            min={0}
                                            value={borderRadius.bottomLeft}
                                            onChange={(value) =>
                                                handleBorderRadiusChange(
                                                    "bottomLeft",
                                                    value,
                                                )
                                            }
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    )}

                    {/* Color or Image Handling */}
                    {collectorType !== "image" ? (
                        <Form.Item
                            label="Collector color"
                            style={{ textAlign: "center", marginTop: "4px" }}
                        >
                            <BlockPicker
                                color={color}
                                onChange={(color) => {
                                    setColor(color.hex);
                                }}
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label="Image"
                            style={{ textAlign: "center" }}
                        >
                            <>
                                <RenderUpload
                                    singleUpload={true}
                                    // disabled={disableWrite}
                                    addExtension={true}
                                    path="home-explore/document/"
                                    onChangeCustom={(e) => {
                                        const value = e?.target?.value
                                            ? replaceCDNUrl(
                                                  e?.target?.value,
                                                  e?.target?.bucket,
                                              )
                                            : "";
                                        handleSrcChange(value);
                                        setSrc(value);
                                    }}
                                    label="upload the file"
                                    value={src}
                                />
                                <Input
                                    placeholder="Add value here"
                                    value={src}
                                    // disabled={disableWrite}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleSrcChange(value);
                                    }}
                                />
                            </>
                        </Form.Item>
                    )}
                </Form.Item>

                {/* Collector Preview */}
                <Form.Item
                    label="Preview"
                    style={{
                        textAlign: "center",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                    }}
                    labelAlign="right"
                >
                    <canvas
                        ref={canvasRef}
                        width="250"
                        height="250"
                        style={{
                            border: "1px solid #ddd",
                            display: "block",
                            margin: "0 auto",
                        }}
                    />
                    <Card>
                        <Form.Item label="Collector text">
                            <Input
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </Form.Item>
                        <Card
                            style={{
                                border: "1px solid black",
                            }}
                            title="Font customization"
                        >
                            <Form.Item label="Font size">
                                <InputNumber
                                    style={{ width: "100%" }}
                                    value={font.size}
                                    onChange={(e) =>
                                        handleFontChange("size", e)
                                    }
                                />
                            </Form.Item>

                            <Form.Item label="Font weight">
                                <InputNumber
                                    style={{ width: "100%" }}
                                    value={font.weight}
                                    minLength={3}
                                    maxLength={3}
                                    onChange={(e) =>
                                        handleFontChange("weight", e)
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Font color">
                                <Input
                                    value={font.color}
                                    onChange={(e) =>
                                        handleFontChange(
                                            "color",
                                            e.target.value,
                                        )
                                    }
                                />
                            </Form.Item>
                        </Card>
                    </Card>
                </Form.Item>
            </Form>
            <>
                <Divider />
                <>
                    <Button
                        type="primary"
                        onClick={() => {
                            message.info(`Copied collector to clipboard!`);

                            navigator.clipboard.writeText(
                                JSON.stringify(collector),
                            );
                        }}
                    >
                        Copy collector
                    </Button>
                    <Divider />
                </>
                <Form.Item label={"Paste collctor here"}>
                    <Input.TextArea
                        value={tempConfig}
                        placeholder="Paste Config here"
                        onChange={(e) => setTempConfig(e.target.value)}
                    />
                    <Button
                        style={{ marginTop: "10px" }}
                        type="primary"
                        onClick={() => {
                            try {
                                const parsedJson = JSON.parse(tempConfig);
                                setColor(parsedJson.color);
                                setCollectorType(parsedJson.type);
                                setRadius(parsedJson.radius);
                                setShape(parsedJson.shape);
                                setSrc(parsedJson.src);
                                setFont(parsedJson.font);
                                setText(parsedJson.text);
                                setBorderRadius(parsedJson.borderRadius);
                                message.info("Updated!");
                            } catch (e) {
                                // captureException(e)
                                message.error("Error in JSON!");
                            }
                        }}
                    >
                        Update config
                    </Button>
                </Form.Item>
            </>
        </Card>
    );
};

export default CollectorCustomizer;
