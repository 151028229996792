import {
    CheckOutlined,
    CloseOutlined,
    EllipsisOutlined,
    MenuOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Dropdown,
    Form,
    Menu,
    Select,
    Switch,
    message,
    Input,
    Row,
    Col,
    Collapse,
} from "antd";
import { cloneDeep, get, pullAt, set } from "lodash";
import { useEffect, useRef, useState } from "react";
import { KEYMAP, CLUSTER_KEYS } from "./mapping";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { getActionMenuItems } from "src/modules/worksheet/components/WorksheetEditor/helpers";
import { AudioPoolModal } from "../../../../Figma/renderEditor/AudioPoolModal";
import { LogicModal } from "../common/LogicModal";
import TableEditor from "../../../../Figma/renderEditor/TableEditor";
import { updateKeysAndCopy } from "src/modules/worksheet/components/WorksheetEditor/helpers/getActionMenuItems";
import ComponentDetails from "../common/ComponentDetails";
import { FeedbackPool } from "../common/FeedbackPool";
import CollapseBtn from "src/modules/worksheet/components/WorksheetEditor/helpers/CollapseBtn";
import TablePreviewComponent from "./preview";
import TagsTable from "../common/TagsTable";
import { checkGenericClusterValid } from "../../../isValid";
import ButtonsTable from "./ButtonsTable";
import TagsCollectionCard from "./TagsCollectionCard";

export const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            marginRight: "5px",
        }}
    />
));

const SortableItem = SortableElement((props: any) => {
    const {
        child,
        disableWrite,
        value,
        deleteBlock,
        addBlock,
        onClick,
        currentCluster,
        idx,
    } = props;
    const getBlockActionsMenu = () => {
        return (
            <Menu
                items={[
                    ...getActionMenuItems({
                        idx,
                        type: child.type,
                        disableDuplicateAction: disableWrite,
                        deleteBlock,
                        addBlockCustom: addBlock,
                        block: child,
                        // for progression stage
                        updateId: true,
                        ignoreId: false,
                        updateGenricId: true,
                    }),
                    {
                        key: 3,
                        label: <span>Copy JSON</span>,
                        onClick: async () => {
                            navigator.clipboard.writeText(
                                JSON.stringify(child),
                            );
                            message.info(`Copied Cluster to clipboard!`);
                        },
                    },
                ]}
            />
        );
    };

    const isValid = checkGenericClusterValid(child);

    return (
        <Card
            hoverable
            style={{
                marginBottom: "20px",
                boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                background: !isValid
                    ? "#FFD6D7"
                    : currentCluster === value
                    ? "#E6F7FF"
                    : "#ffffff",
                borderRight:
                    currentCluster === value
                        ? !isValid
                            ? "4px solid red"
                            : "4px solid #1890FF"
                        : 0,
            }}
            onClick={onClick}
            bodyStyle={{
                background: currentCluster === value ? "#E6F7FF" : "#ffffff",
                padding: 0,
            }}
            extra={
                <Dropdown
                    overlay={() => getBlockActionsMenu()}
                    trigger={["click"]}
                    overlayStyle={{ width: "100px" }}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Button
                            style={{
                                flexShrink: 0,
                            }}
                            type="default"
                            icon={
                                <EllipsisOutlined
                                    style={{ fontSize: "20px" }}
                                />
                            }
                            disabled={disableWrite}
                        />
                    </a>
                </Dropdown>
            }
            title={
                <>
                    <DragHandle /> {CLUSTER_KEYS[child.type]}
                </>
            }
        />
    );
});

const SortableList = SortableContainer(({ children }) => {
    return <div style={{ overflow: "auto", gap: "20px" }}>{children}</div>;
});

export const DEFAULT_TABLE = {
    cells: [],
    gap: {
        horizontal: 0,
        vertical: 0,
    },
};
const GenericTable = (props: any) => {
    const {
        block,
        disableWrite,
        setBlock,
        currentEditor,
        setCurrentEditor,
        hasMentions,
        mentionsList = [],
        renderLinkSelect,
    } = props;
    const {
        figma: { variables, compute_functions },
    } = block;
    const blockVarsMap: any = useRef({});

    const [currentCluster, setCurCluster] = useState(null);
    const [openAudioModal, setOpenAudioModal] = useState("");
    const [tempChildren, setTempChildren] = useState("");
    const [openFeedbackModal, setOpenFeedbackModal] = useState("");
    const [load, setLoad] = useState(false);
    const [loadingUpdateTagData, setLoadingUpdateTagData] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        setLoad(true);
        variables?.forEach((element: { name: string | number }, i: any) => {
            blockVarsMap.current[element.name] = i;
        });
        let v = setTimeout(() => {
            setLoad(false);
        }, 50);
        return () => {
            clearTimeout(v);
        };
    }, []);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const tmpChildren = arrayMoveImmutable(
            variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);

        let tmpBlock = cloneDeep(block);
        tmpBlock = set(
            tmpBlock,
            [
                "figma",
                "variables",
                blockVarsMap.current[KEYMAP.generic_cluster],
                "value",
            ],
            tmpChildren,
        );
        setBlock(tmpBlock);
        setCurCluster(newIndex);
    };

    const updateTagClusterDataInTable = (clusterIndex: number) => {
        setLoadingUpdateTagData(true);
        let tmpBlock = cloneDeep(block);
        const idx = variables[
            blockVarsMap.current[KEYMAP.generic_cluster]
        ]?.value[
            clusterIndex
        ]?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
            (v: { name: string }) => v.name === "options",
        );
        const isClusterVisibleVar = variables[
            blockVarsMap.current[KEYMAP.generic_cluster]
        ]?.value[
            clusterIndex
        ]?.props?.tags_list?.value?.variables?.value?.variables?.find(
            (v: { name: string }) => v.name === "is_cluster_visible",
        );
        const isClusterVisible =
            isClusterVisibleVar?.value === null ||
            isClusterVisibleVar?.value === undefined
                ? isClusterVisibleVar?.default
                : isClusterVisibleVar?.value;

        const tagsData =
            variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value[
                clusterIndex
            ]?.props?.tags_list?.value?.variables?.value?.variables[idx]?.value;

        variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value.forEach(
            (v: any, clusterIndex: number) => {
                if (v.type !== "table_collection") return;

                const tableDataIndex =
                    v?.props?.table?.value?.variables?.value?.variables?.findIndex(
                        (v: { name: string }) => v.name === "tableData",
                    );
                const tableData =
                    v?.props?.table?.value?.variables?.value?.variables[
                        tableDataIndex
                    ];
                let updatedTableData = cloneDeep(
                    tableData.value ?? tableData.default,
                );
                updatedTableData.cells?.map((row: any) => {
                    return row.map((cell: any) => {
                        if (cell.dragDrop?.enabled) {
                            if (cell.dragDrop.droppedSource) {
                                cell.dragDrop.droppedSource = tagsData?.find(
                                    (tag: any) =>
                                        tag.genericId ===
                                        cell.dragDrop.droppedSource?.genericId,
                                );
                                cell.dragDrop.droppedSource.is_cluster_visible =
                                    isClusterVisible;
                            }
                        }
                        return cell;
                    });
                });
                tmpBlock = set(
                    tmpBlock,
                    [
                        "figma",
                        "variables",
                        blockVarsMap.current[KEYMAP.generic_cluster],
                        "value",
                        clusterIndex,
                        "props",
                        "table",
                        "value",
                        "variables",
                        "value",
                        "variables",
                        tableDataIndex,
                        "value",
                    ],

                    updatedTableData,
                );
            },
        );
        setBlock(tmpBlock);
        setLoadingUpdateTagData(false);
    };

    let tableData =
        currentCluster !== null &&
        variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value[
            currentCluster
        ]?.props?.table?.value?.variables?.value?.variables?.find(
            (v: { name: string }) => v.name === "tableData",
        );

    return load ? (
        <></>
    ) : (
        <div style={{ paddingBottom: "10px", display: "flex" }}>
            {openAudioModal && openAudioModal !== "" && (
                <AudioPoolModal
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenAudioModal(value === true ? openAudioModal : "");
                    }}
                    name={openAudioModal}
                    audioPool={
                        blockVarsMap.current[openAudioModal] >= 0 &&
                        variables[blockVarsMap.current[openAudioModal]].value
                    }
                    disableWrite={disableWrite}
                    schema={{
                        name: "",
                        audio: "",
                    }}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[openAudioModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            )}
            {openFeedbackModal && openFeedbackModal !== "" && (
                <FeedbackPool
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenFeedbackModal(
                            value === true ? openFeedbackModal : "",
                        );
                    }}
                    name={openFeedbackModal}
                    feedbackPool={
                        blockVarsMap.current[openFeedbackModal] >= 0 &&
                        variables[blockVarsMap.current[openFeedbackModal]].value
                    }
                    disableWrite={disableWrite}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "figma",
                                "variables",
                                blockVarsMap.current[openFeedbackModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                />
            )}
            <Card
                style={{
                    minWidth: "50px",
                    maxWidth: "300px",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                }}
                bodyStyle={{
                    padding: "0px",
                    paddingTop: collapsed ? "10px" : "15px",
                }}
            >
                <div
                    style={{
                        height: "25px",
                    }}
                >
                    <CollapseBtn
                        collapsed={collapsed}
                        toggleCollapsed={toggleCollapsed}
                        style={{
                            marginBottom: 10,
                            marginLeft: collapsed ? 10 : 20,
                            marginRight: collapsed ? 10 : 0,
                            position: "fixed",
                        }}
                    />
                </div>
                <div
                    style={{
                        display: collapsed ? "none" : "block",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        height: "80vh",
                        padding: 20,
                        paddingTop: 30,
                    }}
                >
                    <SortableList onSortEnd={onSortEnd} useDragHandle>
                        {(
                            variables[
                                blockVarsMap.current[KEYMAP.generic_cluster]
                            ]?.value || []
                        ).map((child, idx) => (
                            <SortableItem
                                disabled={disableWrite}
                                key={`item-${idx}`}
                                index={idx}
                                idx={idx}
                                value={idx}
                                child={child}
                                disableWrite={disableWrite}
                                onClick={() => setCurCluster(idx)}
                                currentCluster={currentCluster}
                                addBlock={(
                                    type: string,
                                    index: number,
                                    newBlock: any,
                                ) => {
                                    let tmpBlock = cloneDeep(block);
                                    const tmpChildren = [
                                        ...variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value,
                                    ];
                                    tmpChildren.splice(index, 0, newBlock);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                        ],
                                        tmpChildren,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                deleteBlock={(index: number) => {
                                    let tmpBlock = cloneDeep(block);
                                    const tmpChildren = [
                                        ...variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value,
                                    ];
                                    pullAt(tmpChildren, idx);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "figma",
                                            "variables",
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ],
                                            "value",
                                        ],
                                        tmpChildren,
                                    );
                                    setBlock(tmpBlock);
                                    setCurCluster(idx - 1);
                                }}
                            />
                        ))}
                    </SortableList>
                    {!variables[blockVarsMap.current[KEYMAP.generic_cluster]]
                        ?.value?.length && (
                        <div
                            style={{
                                fontSize: "16px",
                                color: "red",
                            }}
                        >
                            No clusters added
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            marginTop: "20px",
                            flexWrap: "wrap",
                        }}
                    >
                        {!disableWrite &&
                            variables?.[
                                blockVarsMap.current?.[KEYMAP.generic_cluster]
                            ]?.schema?.map((item: any, key: number) => (
                                <Button
                                    key={key}
                                    ghost
                                    type="primary"
                                    onClick={() => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ],
                                                "value",
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ].value?.length,
                                            ],
                                            item,
                                        );
                                        setBlock(tmpBlock);
                                        setCurCluster(
                                            variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ].value?.length,
                                        );
                                    }}
                                >
                                    Add {CLUSTER_KEYS[item.type]}
                                </Button>
                            ))}
                        {!disableWrite && (
                            <Form.Item label={"Add Cluster"}>
                                <Input.TextArea
                                    disabled={disableWrite}
                                    value={tempChildren}
                                    placeholder="Paste Cluster here"
                                    onChange={(e) =>
                                        setTempChildren(e.target.value)
                                    }
                                    rows={2}
                                    style={{
                                        width: "100%",
                                    }}
                                />
                                <Button
                                    disabled={disableWrite || !tempChildren}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempChildren);
                                            if (
                                                !Object.keys(
                                                    CLUSTER_KEYS,
                                                ).includes(parsedJson?.type)
                                            ) {
                                                message.warn("Invalid JSON");
                                                return;
                                            }
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "figma",
                                                    "variables",
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ],
                                                    "value",
                                                    variables[
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ]
                                                    ].value?.length,
                                                ],
                                                updateKeysAndCopy(
                                                    parsedJson,
                                                    false,
                                                    true,
                                                ),
                                            );
                                            setBlock(tmpBlock);
                                            setCurCluster(
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ].value?.length,
                                            );
                                            setTempChildren("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e)
                                            console.log(e);
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Add New Cluster
                                </Button>
                            </Form.Item>
                        )}
                    </div>
                    <Collapse>
                        <Collapse.Panel header="Settings" key="1">
                            <ComponentDetails
                                block={block}
                                setBlock={setBlock}
                                disableWrite={disableWrite}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    gap: "4px",
                                    marginBottom: "20px",
                                }}
                            >
                                <LogicModal
                                    disableWrite={disableWrite}
                                    computeFUnctions={compute_functions}
                                    onSave={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["figma", "compute_functions"],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    filterList={[]}
                                />
                                <Select
                                    value={
                                        blockVarsMap.current[
                                            KEYMAP.check_type
                                        ] >= 0 &&
                                        variables[
                                            blockVarsMap.current[
                                                KEYMAP.check_type
                                            ]
                                        ].value
                                    }
                                    placeholder="Select type"
                                    options={[
                                        {
                                            label: "One by one",
                                            value: "ONE_BY_ONE",
                                        },
                                        {
                                            label: "One by one till first incorrect",
                                            value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                                        },
                                        {
                                            label: "All in one go",
                                            value: "ALL_IN_ONE_GO",
                                        },
                                    ]}
                                    style={{
                                        width: "150px",
                                        flexGrow: 0,
                                    }}
                                    onChange={(val) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            [
                                                "figma",
                                                "variables",
                                                blockVarsMap.current[
                                                    KEYMAP.check_type
                                                ],
                                                "value",
                                            ],
                                            val,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            </div>
                            <Form.Item label="Audio Pool">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setOpenAudioModal(KEYMAP.start_audio);
                                    }}
                                    size="small"
                                >
                                    Audio
                                </Button>
                            </Form.Item>
                            {blockVarsMap.current[KEYMAP.start_feedback] >=
                                0 && (
                                <Form.Item label="Feedback Pool">
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setOpenFeedbackModal(
                                                KEYMAP.start_feedback,
                                            );
                                        }}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Form.Item>
                            )}
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </Card>
            {currentCluster !== null &&
                variables[blockVarsMap.current[KEYMAP.generic_cluster]]?.value[
                    currentCluster
                ] && (
                    <div
                        style={{
                            minWidth: "320px",
                            flexShrink: 1,
                            marginLeft: "8px",
                            // maxWidth: "600px",
                            height: "85vh",
                            overflow: "auto",
                            scrollbarWidth: "thin",
                        }}
                        id="generic-table-container"
                    >
                        {variables[blockVarsMap.current[KEYMAP.generic_cluster]]
                            ?.value[currentCluster]?.type ===
                        "table_collection" ? (
                            <Card>
                                <h2>Table Configuration</h2>
                                <div
                                    style={{
                                        display: "flex",
                                        columnGap: "20px",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {renderLinkSelect &&
                                        renderLinkSelect({
                                            value: tableData
                                                ?.linked_global_context_variable
                                                ?.name,
                                            onChange: (value) => {
                                                let tmpBlock = cloneDeep(block);
                                                const idx = variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ]?.value[
                                                    currentCluster
                                                ]?.props?.table?.value?.variables?.value?.variables?.findIndex(
                                                    (v: { name: string }) =>
                                                        v.name === "tableData",
                                                );
                                                if (idx >= 0) {
                                                    tmpBlock = set(
                                                        tmpBlock,
                                                        [
                                                            "figma",
                                                            "variables",
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ],
                                                            "value",
                                                            currentCluster,
                                                            "props",
                                                            "table",
                                                            "value",
                                                            "variables",
                                                            "value",
                                                            "variables",
                                                            idx,
                                                            "linked_global_context_variable",
                                                            "name",
                                                        ],
                                                        value,
                                                    );

                                                    // if (
                                                    //     !tableData
                                                    //         ?.linked_global_context_variable
                                                    //         ?.name
                                                    // ) {
                                                    //     tmpBlock = set(
                                                    //         tmpBlock,
                                                    //         [
                                                    //             "figma",
                                                    //             "variables",
                                                    //             blockVarsMap
                                                    //                 .current[
                                                    //                 KEYMAP
                                                    //                     .generic_cluster
                                                    //             ],
                                                    //             "value",
                                                    //             currentCluster,
                                                    //             "props",
                                                    //             "table",
                                                    //             "value",
                                                    //             "variables",
                                                    //             "value",
                                                    //             "variables",
                                                    //             idx,
                                                    //             "last_value",
                                                    //         ],
                                                    //         tableData?.value ||
                                                    //             (typeof tableData?.default ===
                                                    //             "string"
                                                    //                 ? JSON.parse(
                                                    //                       tableData?.default,
                                                    //                   )
                                                    //                 : tableData?.default) ||
                                                    //             DEFAULT_TABLE,
                                                    //     );
                                                    // }

                                                    // tmpBlock = set(
                                                    //     tmpBlock,
                                                    //     [
                                                    //         "figma",
                                                    //         "variables",
                                                    //         blockVarsMap
                                                    //             .current[
                                                    //             KEYMAP
                                                    //                 .generic_cluster
                                                    //         ],
                                                    //         "value",
                                                    //         currentCluster,
                                                    //         "props",
                                                    //         "table",
                                                    //         "value",
                                                    //         "variables",
                                                    //         "value",
                                                    //         "variables",
                                                    //         idx,
                                                    //         "value",
                                                    //     ],
                                                    //     value !== null
                                                    //         ? `@@${value}@@`
                                                    //         : tableData.last_value ||
                                                    //               tableData?.value ||
                                                    //               (typeof tableData?.default ===
                                                    //               "string"
                                                    //                   ? JSON.parse(
                                                    //                         tableData?.default,
                                                    //                     )
                                                    //                   : tableData?.default) ||
                                                    //               DEFAULT_TABLE,
                                                    // );
                                                    setBlock(tmpBlock);
                                                }
                                            },
                                        })}
                                    <Form.Item label="Value from variable">
                                        <Select
                                            value={
                                                typeof tableData?.value ===
                                                "string"
                                                    ? tableData?.value
                                                    : null
                                            }
                                            placeholder="Select type"
                                            options={[
                                                {
                                                    label: "none",
                                                    value: null,
                                                },
                                                ...mentionsList?.map((v) => ({
                                                    label: v.text,
                                                    value: `@@${v.text}@@`,
                                                })),
                                            ]}
                                            style={{
                                                width: "150px",
                                                flexGrow: 0,
                                            }}
                                            onChange={(val) => {
                                                let tmpBlock = cloneDeep(block);
                                                const idx = variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ]?.value[
                                                    currentCluster
                                                ]?.props?.table?.value?.variables?.value?.variables?.findIndex(
                                                    (v: { name: string }) =>
                                                        v.name === "tableData",
                                                );
                                                if (idx >= 0) {
                                                    if (
                                                        !tableData.value ||
                                                        typeof tableData?.value !==
                                                            "string"
                                                    ) {
                                                        tmpBlock = set(
                                                            tmpBlock,
                                                            [
                                                                "figma",
                                                                "variables",
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ],
                                                                "value",
                                                                currentCluster,
                                                                "props",
                                                                "table",
                                                                "value",
                                                                "variables",
                                                                "value",
                                                                "variables",
                                                                idx,
                                                                "last_value",
                                                            ],
                                                            tableData?.value ||
                                                                (typeof tableData?.default ===
                                                                "string"
                                                                    ? JSON.parse(
                                                                          tableData?.default,
                                                                      )
                                                                    : tableData?.default) ||
                                                                DEFAULT_TABLE,
                                                        );
                                                    }

                                                    tmpBlock = set(
                                                        tmpBlock,
                                                        [
                                                            "figma",
                                                            "variables",
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ],
                                                            "value",
                                                            currentCluster,
                                                            "props",
                                                            "table",
                                                            "value",
                                                            "variables",
                                                            "value",
                                                            "variables",
                                                            idx,
                                                            "value",
                                                        ],
                                                        val !== null ||
                                                            typeof tableData.last_value ===
                                                                "string"
                                                            ? val
                                                            : tableData.last_value ||
                                                                  tableData?.value ||
                                                                  (typeof tableData?.default ===
                                                                  "string"
                                                                      ? JSON.parse(
                                                                            tableData?.default,
                                                                        )
                                                                      : tableData?.default) ||
                                                                  DEFAULT_TABLE,
                                                    );
                                                    setBlock(tmpBlock);
                                                }
                                            }}
                                            disabled={disableWrite}
                                        />
                                    </Form.Item>
                                </div>
                                {(!tableData.value ||
                                    typeof tableData?.value !== "string") && (
                                    <>
                                        <TableEditor
                                            table={
                                                tableData?.value ||
                                                (typeof tableData?.default ===
                                                "string"
                                                    ? JSON.parse(
                                                          tableData?.default,
                                                      )
                                                    : tableData?.default) ||
                                                DEFAULT_TABLE
                                            }
                                            tableVariableName={"tableData"}
                                            currentEditor={currentEditor}
                                            setCurrentEditor={setCurrentEditor}
                                            dropdownGlobalVariables={[]}
                                            droppableAreaIdsGlobalVariables={[]}
                                            disableWrite={disableWrite}
                                            tagsClusterList={variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value?.map((v, i) => ({
                                                value: i,
                                                label: `${i + 1}: ${
                                                    CLUSTER_KEYS[v.type]
                                                }`,
                                            }))}
                                            setBlock={(
                                                value: any,
                                                {
                                                    tag_id,
                                                    prev_tag_id,
                                                    tag_data,
                                                    tag_cluster_index = -1,
                                                    delete_prev_tag = false,
                                                    oldTags = [],
                                                    newTags = [],
                                                } = {},
                                            ) => {
                                                let tmpBlock = cloneDeep(block);
                                                const idx = variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ]?.value[
                                                    currentCluster
                                                ]?.props?.table?.value?.variables?.value?.variables?.findIndex(
                                                    (v: { name: string }) =>
                                                        v.name === "tableData",
                                                );
                                                if (idx >= 0) {
                                                    tmpBlock = set(
                                                        tmpBlock,
                                                        [
                                                            "figma",
                                                            "variables",
                                                            blockVarsMap
                                                                .current[
                                                                KEYMAP
                                                                    .generic_cluster
                                                            ],
                                                            "value",
                                                            currentCluster,
                                                            "props",
                                                            "table",
                                                            "value",
                                                            "variables",
                                                            "value",
                                                            "variables",
                                                            idx,
                                                            "value",
                                                        ],
                                                        value,
                                                    );
                                                    if (
                                                        tag_id ||
                                                        prev_tag_id ||
                                                        tag_cluster_index > -1
                                                    ) {
                                                        let arrData =
                                                            tmpBlock.figma
                                                                ?.variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value;

                                                        let tagIndex;
                                                        let prevTagIndex;
                                                        for (
                                                            let index = 0;
                                                            index <
                                                            arrData.length;
                                                            index++
                                                        ) {
                                                            const element =
                                                                arrData[index];

                                                            if (
                                                                element.type ===
                                                                "tags_collection"
                                                            ) {
                                                                let optionsIndex =
                                                                    element?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                                                                        (val) =>
                                                                            val.name ===
                                                                            "options",
                                                                    );
                                                                if (
                                                                    optionsIndex <
                                                                    0
                                                                )
                                                                    continue;

                                                                if (
                                                                    !prevTagIndex &&
                                                                    prev_tag_id
                                                                ) {
                                                                    prevTagIndex =
                                                                        element?.props?.tags_list?.value?.variables?.value?.variables[
                                                                            optionsIndex
                                                                        ]?.value?.findIndex(
                                                                            (
                                                                                v,
                                                                            ) =>
                                                                                v.genericId ===
                                                                                prev_tag_id,
                                                                        );

                                                                    if (
                                                                        prevTagIndex >=
                                                                        0
                                                                    ) {
                                                                        if (
                                                                            delete_prev_tag
                                                                        ) {
                                                                            tmpBlock =
                                                                                set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        "figma",
                                                                                        "variables",
                                                                                        blockVarsMap
                                                                                            .current[
                                                                                            KEYMAP
                                                                                                .generic_cluster
                                                                                        ],
                                                                                        "value",
                                                                                        index,
                                                                                        "props",
                                                                                        "tags_list",
                                                                                        "value",
                                                                                        "variables",
                                                                                        "value",
                                                                                        "variables",
                                                                                        optionsIndex,
                                                                                        "value",
                                                                                    ],
                                                                                    element?.props?.tags_list?.value?.variables?.value?.variables[
                                                                                        optionsIndex
                                                                                    ]?.value?.filter(
                                                                                        (
                                                                                            v,
                                                                                        ) =>
                                                                                            v.genericId !==
                                                                                            prev_tag_id,
                                                                                    ),
                                                                                );
                                                                        } else
                                                                            tmpBlock =
                                                                                set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        "figma",
                                                                                        "variables",
                                                                                        blockVarsMap
                                                                                            .current[
                                                                                            KEYMAP
                                                                                                .generic_cluster
                                                                                        ],
                                                                                        "value",
                                                                                        index,
                                                                                        "props",
                                                                                        "tags_list",
                                                                                        "value",
                                                                                        "variables",
                                                                                        "value",
                                                                                        "variables",
                                                                                        optionsIndex,
                                                                                        "value",
                                                                                        prevTagIndex,
                                                                                        "props",
                                                                                        "textVisible",
                                                                                        "value",
                                                                                    ],
                                                                                    1,
                                                                                );
                                                                    }
                                                                }

                                                                if (!tagIndex) {
                                                                    if (
                                                                        tag_id
                                                                    ) {
                                                                        tagIndex =
                                                                            element?.props?.tags_list?.value?.variables?.value?.variables[
                                                                                optionsIndex
                                                                            ]?.value?.findIndex(
                                                                                (
                                                                                    v,
                                                                                ) =>
                                                                                    v.genericId ===
                                                                                    tag_id,
                                                                            );

                                                                        if (
                                                                            tagIndex >=
                                                                            0
                                                                        ) {
                                                                            if (
                                                                                tag_data
                                                                            ) {
                                                                                tmpBlock =
                                                                                    set(
                                                                                        tmpBlock,
                                                                                        [
                                                                                            "figma",
                                                                                            "variables",
                                                                                            blockVarsMap
                                                                                                .current[
                                                                                                KEYMAP
                                                                                                    .generic_cluster
                                                                                            ],
                                                                                            "value",
                                                                                            index,
                                                                                            "props",
                                                                                            "tags_list",
                                                                                            "value",
                                                                                            "variables",
                                                                                            "value",
                                                                                            "variables",
                                                                                            optionsIndex,
                                                                                            "value",
                                                                                            tagIndex,
                                                                                        ],
                                                                                        tag_data,
                                                                                    );
                                                                            }
                                                                        }
                                                                    } else if (
                                                                        tag_cluster_index ===
                                                                            index &&
                                                                        tag_data
                                                                    ) {
                                                                        let tagArray: any =
                                                                            get(
                                                                                tmpBlock,
                                                                                [
                                                                                    "figma",
                                                                                    "variables",
                                                                                    blockVarsMap
                                                                                        .current[
                                                                                        KEYMAP
                                                                                            .generic_cluster
                                                                                    ],
                                                                                    "value",
                                                                                    index,
                                                                                    "props",
                                                                                    "tags_list",
                                                                                    "value",
                                                                                    "variables",
                                                                                    "value",
                                                                                    "variables",
                                                                                    optionsIndex,
                                                                                    "value",
                                                                                ],
                                                                            );

                                                                        if (
                                                                            tagArray?.length
                                                                        ) {
                                                                            tmpBlock =
                                                                                set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        "figma",
                                                                                        "variables",
                                                                                        blockVarsMap
                                                                                            .current[
                                                                                            KEYMAP
                                                                                                .generic_cluster
                                                                                        ],
                                                                                        "value",
                                                                                        index,
                                                                                        "props",
                                                                                        "tags_list",
                                                                                        "value",
                                                                                        "variables",
                                                                                        "value",
                                                                                        "variables",
                                                                                        optionsIndex,
                                                                                        "value",
                                                                                        tagArray?.length ||
                                                                                            0,
                                                                                    ],
                                                                                    tag_data,
                                                                                );
                                                                        } else {
                                                                            tmpBlock =
                                                                                set(
                                                                                    tmpBlock,
                                                                                    [
                                                                                        "figma",
                                                                                        "variables",
                                                                                        blockVarsMap
                                                                                            .current[
                                                                                            KEYMAP
                                                                                                .generic_cluster
                                                                                        ],
                                                                                        "value",
                                                                                        index,
                                                                                        "props",
                                                                                        "tags_list",
                                                                                        "value",
                                                                                        "variables",
                                                                                        "value",
                                                                                        "variables",
                                                                                        optionsIndex,
                                                                                        "value",
                                                                                    ],
                                                                                    [
                                                                                        tag_data,
                                                                                    ],
                                                                                );
                                                                            tagIndex =
                                                                                index;
                                                                        }
                                                                    }
                                                                }

                                                                if (
                                                                    ((!tag_id &&
                                                                        ((tag_cluster_index >
                                                                            0 &&
                                                                            index >=
                                                                                tag_cluster_index) ||
                                                                            tag_cluster_index <
                                                                                0)) ||
                                                                        (tag_id &&
                                                                            tagIndex >=
                                                                                0)) &&
                                                                    (!prev_tag_id ||
                                                                        (prev_tag_id &&
                                                                            prevTagIndex >=
                                                                                0))
                                                                ) {
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    if (newTags?.length) {
                                                        let arrData =
                                                            tmpBlock.figma
                                                                ?.variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value;
                                                        for (
                                                            let index = 0;
                                                            index <
                                                            arrData.length;
                                                            index++
                                                        ) {
                                                            const element =
                                                                arrData[index];

                                                            if (
                                                                element.type ===
                                                                "tags_collection"
                                                            ) {
                                                                let optionsIndex =
                                                                    element?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                                                                        (val) =>
                                                                            val.name ===
                                                                            "options",
                                                                    );
                                                                if (
                                                                    optionsIndex <
                                                                    0
                                                                )
                                                                    continue;
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        index,
                                                                        "props",
                                                                        "tags_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        optionsIndex,
                                                                        "value",
                                                                    ],
                                                                    [
                                                                        ...(element
                                                                            ?.props
                                                                            ?.tags_list
                                                                            ?.value
                                                                            ?.variables
                                                                            ?.value
                                                                            ?.variables[
                                                                            optionsIndex
                                                                        ]
                                                                            ?.value ||
                                                                            []),
                                                                        ...newTags,
                                                                    ],
                                                                );

                                                                break;
                                                            }
                                                        }
                                                    }

                                                    if (oldTags?.length) {
                                                        let oldTagsMap: any =
                                                            {};

                                                        oldTags?.forEach(
                                                            (v: any) => {
                                                                oldTagsMap[
                                                                    v.genericId
                                                                ] = v;
                                                            },
                                                        );
                                                        let arrData =
                                                            tmpBlock.figma
                                                                ?.variables[
                                                                blockVarsMap
                                                                    .current[
                                                                    KEYMAP
                                                                        .generic_cluster
                                                                ]
                                                            ]?.value;

                                                        for (
                                                            let index = 0;
                                                            index <
                                                            arrData.length;
                                                            index++
                                                        ) {
                                                            const element =
                                                                arrData[index];

                                                            if (
                                                                element.type ===
                                                                "tags_collection"
                                                            ) {
                                                                let optionsIndex =
                                                                    element?.props?.tags_list?.value?.variables?.value?.variables?.findIndex(
                                                                        (val) =>
                                                                            val.name ===
                                                                            "options",
                                                                    );
                                                                if (
                                                                    optionsIndex <
                                                                    0
                                                                )
                                                                    continue;
                                                                tmpBlock = set(
                                                                    tmpBlock,
                                                                    [
                                                                        "figma",
                                                                        "variables",
                                                                        blockVarsMap
                                                                            .current[
                                                                            KEYMAP
                                                                                .generic_cluster
                                                                        ],
                                                                        "value",
                                                                        index,
                                                                        "props",
                                                                        "tags_list",
                                                                        "value",
                                                                        "variables",
                                                                        "value",
                                                                        "variables",
                                                                        optionsIndex,
                                                                        "value",
                                                                    ],
                                                                    element?.props?.tags_list?.value?.variables?.value?.variables[
                                                                        optionsIndex
                                                                    ]?.value?.map(
                                                                        (v) => {
                                                                            if (
                                                                                oldTagsMap[
                                                                                    v
                                                                                        .genericId
                                                                                ]
                                                                            ) {
                                                                                let ot =
                                                                                    oldTagsMap[
                                                                                        v
                                                                                            .genericId
                                                                                    ];
                                                                                delete oldTagsMap[
                                                                                    v
                                                                                        .genericId
                                                                                ];
                                                                                return ot;
                                                                            } else
                                                                                return v;
                                                                        },
                                                                    ),
                                                                );

                                                                if (
                                                                    Object.keys(
                                                                        oldTagsMap,
                                                                    )
                                                                        ?.length ===
                                                                    0
                                                                )
                                                                    break;
                                                            }
                                                        }
                                                    }
                                                    setBlock(tmpBlock);
                                                }
                                            }}
                                            hasMentions={hasMentions}
                                            mentionsList={mentionsList}
                                            renderLinkSelect={renderLinkSelect}
                                            tagsList={
                                                variables[
                                                    blockVarsMap.current[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ]?.value
                                                    ?.filter(
                                                        (v) =>
                                                            v.type ===
                                                            "tags_collection",
                                                    )
                                                    .flatMap(
                                                        (v) =>
                                                            v?.props?.tags_list?.value?.variables?.value?.variables
                                                                ?.find(
                                                                    (val) =>
                                                                        val.name ===
                                                                        "options",
                                                                )
                                                                ?.value?.map(
                                                                    (valu) => {
                                                                        const currentIsClusterVariable =
                                                                            v?.props?.tags_list?.value?.variables?.value?.variables?.find(
                                                                                (
                                                                                    val,
                                                                                ) =>
                                                                                    val.name ===
                                                                                    "is_cluster_visible",
                                                                            );
                                                                        let isClusterVisibleValue;
                                                                        if (
                                                                            currentIsClusterVariable.value ===
                                                                                null ||
                                                                            currentIsClusterVariable.value ===
                                                                                undefined
                                                                        ) {
                                                                            isClusterVisibleValue =
                                                                                currentIsClusterVariable.default;
                                                                        } else {
                                                                            isClusterVisibleValue =
                                                                                currentIsClusterVariable.value;
                                                                        }
                                                                        return {
                                                                            ...valu,
                                                                            is_cluster_visible:
                                                                                isClusterVisibleValue,
                                                                        };
                                                                    },
                                                                ) || [],
                                                    ) || []
                                            }
                                            optionsSchema={
                                                variables?.[
                                                    blockVarsMap.current?.[
                                                        KEYMAP.generic_cluster
                                                    ]
                                                ]?.schema
                                                    ?.find(
                                                        (
                                                            item: any,
                                                            key: number,
                                                        ) =>
                                                            item.type ===
                                                            "tags_collection",
                                                    )
                                                    ?.props?.tags_list?.value?.variables?.value?.variables?.find(
                                                        (v: { name: string }) =>
                                                            v.name ===
                                                            "options",
                                                    )?.schema
                                            }
                                        />
                                    </>
                                )}
                            </Card>
                        ) : variables[
                              blockVarsMap.current[KEYMAP.generic_cluster]
                          ]?.value[currentCluster]?.type ===
                          "tags_collection" ? (
                            <TagsCollectionCard
                                variables={variables}
                                blockVarsMap={blockVarsMap}
                                KEYMAP={KEYMAP}
                                currentCluster={currentCluster}
                                disableWrite={disableWrite}
                                loadingUpdateTagData={loadingUpdateTagData}
                                updateTagClusterDataInTable={
                                    updateTagClusterDataInTable
                                }
                                renderLinkSelect={renderLinkSelect}
                                mentionsList={mentionsList}
                                block={block}
                                setBlock={setBlock}
                                currentEditor={currentEditor}
                                setCurrentEditor={setCurrentEditor}
                                hasMentions={hasMentions}
                            />
                        ) : (
                            <Card>
                                <ButtonsTable
                                    {...{
                                        disableWrite,
                                        currentEditor,
                                        setCurrentEditor,
                                        hasMentions,
                                        mentionsList,
                                        setBlock: (v) => {
                                            let tmpBlock = cloneDeep(block);
                                            const idx = variables[
                                                blockVarsMap.current[
                                                    KEYMAP.generic_cluster
                                                ]
                                            ]?.value[
                                                currentCluster
                                            ]?.props?.buttons_list?.value?.variables?.value?.variables?.findIndex(
                                                (v: { name: string }) =>
                                                    v.name === "options",
                                            );
                                            if (idx >= 0)
                                                tmpBlock = set(
                                                    tmpBlock,
                                                    [
                                                        "figma",
                                                        "variables",
                                                        blockVarsMap.current[
                                                            KEYMAP
                                                                .generic_cluster
                                                        ],
                                                        "value",
                                                        currentCluster,
                                                        "props",
                                                        "buttons_list",
                                                        "value",
                                                        "variables",
                                                        "value",
                                                        "variables",
                                                        idx,
                                                        "value",
                                                    ],
                                                    v,
                                                );
                                            setBlock(tmpBlock);
                                        },
                                        ...variables[
                                            blockVarsMap.current[
                                                KEYMAP.generic_cluster
                                            ]
                                        ]?.value[
                                            currentCluster
                                        ]?.props?.buttons_list?.value?.variables?.value?.variables?.find(
                                            (v: { name: string }) =>
                                                v.name === "options",
                                        ),
                                    }}
                                />
                            </Card>
                        )}
                    </div>
                )}
        </div>
    );
};

export default GenericTable;
