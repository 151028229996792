import React from "react";
import { Form, Input, Select, Divider } from "antd";
import _ from "lodash";

const AlignmentComponent = ({ block, setBlock, disableWrite }: any) => {
    return (
        <>
            <h3>Alignment Handling</h3>
            <Form.Item label="Alignment">
                <Select
                    disabled={disableWrite}
                    value={block.align}
                    onChange={(val) => {
                        let tmpBlock = _.cloneDeep(block);
                        tmpBlock.align = val;
                        setBlock(tmpBlock);
                    }}
                    style={{
                        width: "200px",
                    }}
                >
                    <Select.Option value="LEFT">Left</Select.Option>
                    <Select.Option value="RIGHT">Right</Select.Option>
                    <Select.Option value="CENTER">Center</Select.Option>
                </Select>
            </Form.Item>
        </>
    );
};

export default AlignmentComponent;
